import React from 'react';
import { Form } from '../../../toolympus/components/primitives';
import { TaskListBound } from '../../../toolympus/components/Tasks';

interface Props {
  
}

export const TaskListPage = (props: Props) => {
  return (
    <Form>
      <TaskListBound apiPath="/api/task" />
    </Form>
  );
}
