import React from 'react';
import { TableForFields } from '../../../toolympus/components/schemed';
import { DescriptionViewer } from '../CaseRecordsList';
import { ActionRow, Dialog, LoadingIndicator, OccupyFreeSpace, SearchField } from '../../../toolympus/components/primitives';
import { Button, IconButton } from '@mui/material';
import { Add, DeleteOutlined } from '@mui/icons-material';
import { useCaseRecordLinkedComments } from './useCaseRecordLinkedComments';
import { useExpertCommentSchema } from '../../ExpertComments/useExpertCommentsList';

interface Props {
  caseRecordId: number;
}

export const CaseRecordLinkedComments = (props: Props) => {
  const data = useCaseRecordLinkedComments(props.caseRecordId);
  const schema = useExpertCommentSchema();

  return (<>
    <ActionRow firstItemNoMargin>
      {data.isLoading && <LoadingIndicator size="small" />}
      <OccupyFreeSpace />
      <IconButton
        size="small"
        onClick={() => data.newItem.open()}
        >
          <Add />
      </IconButton>
    </ActionRow>
    <TableForFields
      data={data.data}
      schema={schema}
      fields={[
        ["title"],
        ["comment_date"],
        ["created_datetime"],
        ["description", { tableControl: DescriptionViewer }],
      ]}
      fieldLink={f => {
        if(f === "title") {
          return r => `/expert-comment/${r._id}`
        }
      }}
      rowButtons={r => <IconButton size="small" onClick={() => data.removeComment.run(r)}><DeleteOutlined /></IconButton>}
      />

    <Dialog
      isOpen={data.newItem.isOpen}
      close={() => data.newItem.close()}
      dialogTitle={<>Добавить мнение {data.allComments.isLoading && <LoadingIndicator size="small" />}</>}
      maxWidth="md"
      noFullscreen>

        <SearchField
          filter={data.search}
          setFilter={data.setSearch}
          noButton
          autoFocus
          small
          fullWidth
          />

        <TableForFields
          data={data.allComments.data}
          schema={schema}
          fields={[
            ["title"],
            ["comment_date"],
            ["created_datetime"],
          ]}
          fieldLink={f => {
            if(f === "title") {
              return r => `/expert-comment/${r._id}`
            }
          }}
          rowButtons={row => <Button size="small" color="primary" onClick={() => data.newItem.save(row._id)}>добавить</Button>}
          autoexpander={{ initialRows: 10, increment: 10 }}
          />
    </Dialog>
  </>);
}
