import { FieldType } from "../../../hooks/useSchema";

export type FilterOp = 'equals' | 'not_equals' | 'oneof' | 'empty' | 'notempty' | '<' | '>' | '<=' | '>=';

export interface Filter {
    id: number;
    field?: string;
    op: FilterOp;
    value?: any;
}

export const AvailableOps: { key: FilterOp, label: string }[] = [
    { key: "equals", label: "=" },
    { key: "not_equals", label: "не=" },
    { key: "oneof", label: "одно из" },
    { key: "<", label: "<" },
    { key: "<=", label: "<=" },
    { key: ">", label: ">" },
    { key: ">=", label: ">=" },
    { key: "empty", label: "пусто" },
    { key: "notempty", label: "не пусто" },
];

export const opNeedsValue = (op: FilterOp) => op !== "empty" && op !== "notempty";
export const opAvailable = (op: FilterOp, ft: FieldType) => {
    switch(op) {
        case "<":
        case ">":
        case "<=":
        case ">=":
            return ft === FieldType.date
                || ft === FieldType.datetime
                || ft === FieldType.decimal
                || ft === FieldType.number;
        default:
            return true;
    }
}

export const isFilterActive = (f: Filter) => f.field && f.field.length && (f.value !== undefined || !opNeedsValue(f.op))

export type Row = Record<string, any>;
