import React from 'react';
import { CaseDocumentContentDialogData } from './useCaseRecordDocumentContent';
import { Dialog, LoadingIndicator, useSaveable } from '../../../toolympus/components/primitives';
import { Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { RefreshOutlined } from '@mui/icons-material';
import { LocusEditor } from '../../CMS/LocusEditor';

interface Props {
  data: CaseDocumentContentDialogData;
}

export const CaseRecordDocumentContentDialog = (props: Props) => {
  const { data } = props;
  useSaveable(data);

  return (
    <Dialog
      isOpen={data.isOpen}
      close={data.close}
      actions={<>
        {data.hasChanges &&
          <Button color="primary" variant="contained" onClick={() => data.save()}><FormattedMessage id="common.save" /></Button>}
        <Button onClick={() => data.close()}><FormattedMessage id="common.close" /></Button>
      </>}
      titleActions={<>
        {(data.isLoading || data.reparse.isRunning) && <LoadingIndicator />}
        <Button color="primary" startIcon={<RefreshOutlined />} onClick={() => data.reparse.run()}>перегенерировать</Button>
      </>}
      dialogTitle="Текст акта"
      maxWidth="md"
      fullWidth>
      <LocusEditor
        key={`${data.data._id}_${data.editorBumper}`}
        content={data.data.content}
        update={v => data.update({ content: v })}
        />
    </Dialog>
  );
}
