import React from 'react';
import { ActionDescription, Task } from './types';
import styled from '@emotion/styled';
import { PowerEditor } from '../PowerDoc';
import { Buttons } from '../primitives';
import { Button, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';


const TaskPanelWrapper = styled.div`
  padding: 0.5rem 0.75rem 0.25rem;
  border-radius: ${props => props.theme.shape.borderRadius}px;
  box-shadow: ${props => props.theme.shadows[3]};

  p {
    margin: 0.5em 0;
    font-size: 0.9rem;
  }

  & ${Buttons} {
    justify-content: flex-end;
    
    & > * {
      color: ${props => props.theme.palette.action.active};
      &:hover {
        color: ${props => props.theme.palette.action.hoverOpacity};
      }
    }
  }
`;
TaskPanelWrapper.defaultProps = { className: "task" };

interface Props {
  task: Task;
  actions: ActionDescription[] | undefined;
  executeAction: (task: Task, action: ActionDescription) => void;
  close: (task: Task) => void;
}

export const TaskPanel = (props: Props) => {
  const { task, actions } = props;
  return (
    <TaskPanelWrapper>
      {task.title}

      {!task.is_show_result && <>
        {task.description &&
          <PowerEditor
            content={task.description}
            update={() => {}}
            viewMode
            noDialogs
            />}

        {!!actions?.length &&
          <Buttons>
            {actions.map(a => (
              <Button
                size="small"
                onClick={() => props.executeAction(task, a)}>
                {a.label}
              </Button>
            ))}
          </Buttons>}
        
      </>}

      {!!task.is_show_result && <>
        {task.result_description &&
          <PowerEditor
            content={task.result_description}
            update={() => {}}
            viewMode
            />}

        <Buttons>
          <IconButton size="small" onClick={() => props.close(task)}>
            <Close />
          </IconButton>
        </Buttons>
      </>}

      
    </TaskPanelWrapper>
  );
}
