import React from 'react';
import { RenderElementProps } from 'slate-react';
import { extractCustomBlockFields } from '../../../toolympus/components/PowerDoc/plugins/CustomFieldsBlock';
import { DashboardPage } from '../../../toolympus/components/Dashboard/DashboardPage';

export const DashboardBlock = (props: RenderElementProps) => {
  const fields = extractCustomBlockFields(props.element);

  return fields.dashboard
    ? (
    <DashboardPage
      hideTitle
      isEmbeded
      defaultDashboard={fields.dashboard}
      hideRefresh={!!fields.no_refresh}
      />
    )
    : null;
}
