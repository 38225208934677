import { useIntl } from "react-intl";
import { useEditItem2, useNewItem } from "../../../toolympus/api/useNewItem";
import { useLocalizedList } from "../../../toolympus/components/LocalizedRecords";
import { useTextFilter } from "../../../toolympus/components/schemed/Filtering/useTextFilter";
import { useLoadedData } from "../../../toolympus/hooks/useLoadedData";
import { FieldType, Schema } from "../../../toolympus/hooks/useSchema";
import { DefaultLanguage } from "../../Common/Localization";
import { useItemActionWithConfirmation } from "../../../toolympus/api/useAction";
import { apiFetch } from "../../../toolympus/api/core";

export interface Institute {
  _id: number;
  title: string;
  title_short: string;
  sort_order: number;
  translations?: any;
  is_adhoc?: boolean;
}

export const InstituteSchema: Schema = {
  _id: { label: "ID", type: FieldType.number },
  sort_order: { label: "Порядок", type: FieldType.number },
  title: { label: "Название" },
  title_short: { label: "Краткое название" },
  is_adhoc: { label: "Ad hoc", type: FieldType.bool },
  is_hidden: { label: "Скрытый", type: FieldType.bool },
}


const ApiPath = "/api/settings/institute";

export const useManageInstitutes = () => {
  const data = useLoadedData<Institute[]>(ApiPath, []);
  const filter = useTextFilter<Institute>(c => `${c.title} ${c.title_short} ${c._id}`);
  const localized = useLocalizedList(data.data, {
    defaultLocale: DefaultLanguage,
    translatedFields: ["title", "title_short"],
    translationsField: "translations",
    updateItem: () => {},
  });

  const newInstitute = useNewItem<Omit<Institute, "_id">, Institute>(
    ApiPath,
    {
      title: "",
      title_short: "",
      is_adhoc: false,
      sort_order: Math.max(...data.data.map(c => c.sort_order), 0) + 1,
      translations: { en: { title: "", title_short: "", }},
    });

  const editInstitute = useEditItem2<Institute>({
    getApiPath: c => `${ApiPath}/${c._id}`,
  });

  const { formatMessage } = useIntl();
  const removeInstitute = useItemActionWithConfirmation<Institute, {}>(
    item => apiFetch<Institute>(`${ApiPath}/${item?._id}`, "delete")
      .then(x => {
        data.reload();
        return x;
    }),
    {
      title: formatMessage({ id: "dictionaries.remove_title" }),
      confirmationHint: formatMessage({ id: "dictionaries.remove_hint" }),
    }
  );

  return {
    ...data,
    data: filter.filterData(localized.items),
    filter,
    schema: InstituteSchema,

    newInstitute: {
      ...newInstitute,
      save: () => newInstitute.save().then(c => { data.reload(); return c; }),
    },
    editInstitute: {
      ...editInstitute,
      save: () => editInstitute.save().then(c => { data.reload(); return c; }),
    },
    removeInstitute,

    locale: localized.locale,
    setLocale: localized.setLocale,
  }
}
