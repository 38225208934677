import { useState } from "react";
import { apiFetch } from "../../../toolympus/api/core";
import { useAction } from "../../../toolympus/api/useAction";
import { useCrudItem } from "../../../toolympus/api/useSimpleCrud";

export interface CaseDocumentContent {
  _id: string;
  case_record_id: number;
  content: any;
}

export const useCaseDocumentContent = (caseRecordId: number, documentId: string) => {
  const apiPath = `/api/case-record/${caseRecordId}/document/${documentId}/content`;
  const data = useCrudItem<CaseDocumentContent>(apiPath, {
    defaultValue: { _id: "", case_record_id: -1, content: null },
    noLoad: !caseRecordId || !documentId,
  });
  
  const [editorBumper,setEditorBumper] = useState<number>(0);

  const reparse = useAction<CaseDocumentContent>(() => {
    return apiFetch<CaseDocumentContent>(`${apiPath}/reparse`, "post")
      .then(regenerated => {
        data.setData(regenerated);
        setEditorBumper(x => x+1);
        return regenerated;
      });
  });


  return {
    ...data,
    reparse,
    editorBumper,
  }
}

type DocKeys = Pick<CaseDocumentContent, "_id" | "case_record_id">;

export const useCaseDocumentContentDialog = () => {
  const [docKeys,setDocKeys] = useState<DocKeys | null>(null);
  const data = useCaseDocumentContent(docKeys?.case_record_id || 0, docKeys?._id || "");

  return {
    isOpen: !!docKeys,
    close: () => setDocKeys(null),
    open: (keys: DocKeys) => setDocKeys(keys),
    ...data,
  }
}

export type CaseDocumentContentDialogData = ReturnType<typeof useCaseDocumentContentDialog>;
