import React from 'react';
import { DashboardPage } from '../../toolympus/components/Dashboard/DashboardPage';
import { EditDashboardsContainer } from '../../toolympus/components/EditDashboards/EditDashboards';

export const DashboardsPage = () => {
   return <DashboardPage defaultDashboard="main" allowChangeDashboard getEditLink={d => `/dashboards?code=${d?.code || ""}`} />
}

export const EditDashboardsPage = () => {
  return <EditDashboardsContainer dataschemaApiPath="/api/dsqc" dataschemaCode="alpha" />
}
