import React from 'react';
import { ExecuteTaskActionData } from './useExecuteTaskAction';
import { Button, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Dialog, FormGrid, LoadingIndicator, useWindowHotkey } from '../primitives';
import { FormControlsForFields } from '../schemed';

interface Props {
  data: ExecuteTaskActionData;
}

export const ExecuteTaskActionDialog = (props: Props) => {
  const data = props.data;

  useWindowHotkey("mod+enter", () => {
    if(data.target && !data.isRunning) {
      data.run();
    }
  });

  return (
    <Dialog
      dialogTitle={data.target?.action?.label || "..."}
      isOpen={data.isActive}
      close={() => data.cancel()}
      noFullscreen
      actions={<>
        {data.isRunning && <LoadingIndicator />}
        <Button color="primary" disabled={data.isRunning} onClick={() => data.run()}>
          <FormattedMessage id="common.execute" />
        </Button>
        <Button disabled={data.isRunning} onClick={() => data.cancel()}>
          <FormattedMessage id="common.cancel" />
        </Button>
      </>}
      >
      
      {!!data.paramsFields.length &&
        <FormGrid columns="1fr" noMargin>
          <FormControlsForFields
            data={data.params}
            schema={data.paramsSchema}
            fields={data.paramsFields.map((f,i) => i === 0 ? [f, { controlProps: { autoFocus: true }}] : [f])}
            onChange={(o,c) => data.updateParams(c)}
            errors={data.errors}
            />
        </FormGrid>}

      {!!data.errorGeneral && <>
        <Typography color="error">
          {data.errorGeneral.error_code === "see_message"
            ? data.errorGeneral._message
            : <FormattedMessage id={data.errorGeneral.error_code || "error.general"} values={data.errorGeneral.values} />}
        </Typography>
      </>}
      
    </Dialog>
  );
}
