import React from 'react';
import { useParams } from 'react-router-dom';
import { useCaseRecordEdit } from './useCaseRecordEdit';
import { DeleteButton, Form, FormGrid, OccupyFreeSpace, SaveButton, TabPanel, TabsHeader, Tooltip, useSaveable, useTabsState } from '../../toolympus/components/primitives';
import { Divider, IconButton, Typography } from '@mui/material';
import { LocusEditor } from '../CMS/LocusEditor';
import { CaseRecordParties } from './CaseRecordDetails/CaseRecordParties';
import { CaseRecordDocuments } from './CaseRecordDetails/CaseRecordDocuments';
import { CaseRecordLinkedCases } from './CaseRecordDetails/CaseRecordLinkedCases';
import { CaseRecordLinkedComments } from './CaseRecordDetails/CaseRecordLinkedComments';
import { LanguageSwitch } from '../Common/Localization';
import { TopicsSelectorControl } from '../Administration/Topics';
import { DeleteOutlined, Notifications, NotificationsOutlined } from '@mui/icons-material';
import { useItemWithControls } from '../../toolympus/api/useWithControls';
import { CaseRecordReviewStatusButton } from './CaseRecordReviewStatusButton';

export const CaseRecordForm = () => {
  const { id } = useParams<{ id: string }>();
  const data = useCaseRecordEdit(+id);

  const { controls } = useItemWithControls(data, { schema: data.schema });

  useSaveable(data);

  const tabs = useTabsState([
    ["attributes","Атрибуты"],
    ["parties","Участники"],
    ["documents","Документы"],
    ["linked_cases","Похожие дела"],
    ["expert_comments","Экспертные мнения"],
  ], "attributes", "tab");

  return (
    <Form
      title="Решение"
      headerItems={<>
        <OccupyFreeSpace />
        {data.hasChanges && <SaveButton action={() => data.save()} />}

        <CaseRecordReviewStatusButton data={data.statusReview} />
        
        <Tooltip text={`Отправлять напоминания ответственному (сейчас ${data.data.is_ping_owner_enabled ? "включено" : "выключено"})`}>
          <IconButton
            size="small"
            color={data.data.is_ping_owner_enabled ? "primary" : undefined}
            onClick={() => data.save({ is_ping_owner_enabled: !data.data.is_ping_owner_enabled })}>
            {data.data.is_ping_owner_enabled ? <Notifications /> : <NotificationsOutlined />}
          </IconButton>
        </Tooltip>

        <DeleteButton
          remove={() => data.remove()}
          title="Удалить решение?"
          button={<IconButton size="small"><DeleteOutlined /></IconButton>}
          />
          
        <LanguageSwitch locale={data.locale} setLocale={data.setLocale} />
      </>}
      >
      <FormGrid columns="repeat(4,1fr)" style={{ alignItems: "flex-end" }}>
        {controls([
          ["number"],
          ["ruling_date"],
          ["created_datetime", { disabled: true, utcToLocal: true }],
          ["is_published"],
        ])}
      </FormGrid>

      <TabsHeader state={tabs} />

      <TabPanel state={tabs.forTab("attributes")}>
        <FormGrid>
          {controls([
            ["case_type", { autoComplete: true }],
            ["stage", { autoComplete: true }],
            ["court_id", { autoComplete: true }],
            ["institute_id", { autoComplete: true }],
            ["rules", { autoComplete: true }],
            ["adhoc_jury", { visibleIf: () => data.isAdhoc, autoComplete: true }],
            ["place_country", { autoComplete: true }],
            ["owner_id", { autoComplete: true }],
            ["is_not_interesting"],
          ])}
        </FormGrid>

        <FormGrid columns="1fr">
          {controls([
            ["case_category"],
            ["topics", { control: TopicsSelectorControl }],
          ])}

        <Typography variant="caption" component="p" color="textSecondary" style={{ marginTop: "1rem" }}>Краткое описание</Typography>

        <LocusEditor
          key={`${data.data._id}_${data.locale}`}
          content={data.data.description}
          update={v => data.update({ description: v })}
          />

        <Divider />


        </FormGrid>
      </TabPanel>

      <TabPanel state={tabs.forTab("parties")}>
        <CaseRecordParties caseRecordId={data.data._id} locale={data.locale} />
      </TabPanel>

      <TabPanel state={tabs.forTab("documents")}>
        <CaseRecordDocuments caseRecordId={data.data._id} />
      </TabPanel>

      <TabPanel state={tabs.forTab("linked_cases")}>
        <CaseRecordLinkedCases caseRecordId={data.data._id} caseSchema={data.schema} />
      </TabPanel>

      <TabPanel state={tabs.forTab("expert_comments")}>
        <CaseRecordLinkedComments caseRecordId={data.data._id} />
      </TabPanel>
    </Form>
  );
}
