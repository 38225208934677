import React, { ReactNode, useEffect, useState } from 'react';
import { Profile, useUserProfiles } from './useUserProfiles';
import { Buttons, Dialog, FormGrid, LoadingIndicator, PseudoLink2, SimpleList } from '../../../toolympus/components/primitives';
import { FieldType, Schema } from '../../../toolympus/hooks/useSchema';
import { Button, DialogActions, IconButton, InputAdornment } from '@mui/material';
import { CallMade } from '@mui/icons-material';
import { FormControlsForFields, TableForFields } from '../../../toolympus/components/schemed';
import { FormattedMessage } from 'react-intl';
import { EditItemProps } from '../../../toolympus/api/useNewItem';
import { useExpertCommentSchema, useExpertCommentsByAuthor } from '../../ExpertComments/useExpertCommentsList';
import { LanguageSwitch } from '../../Common/Localization';
import { ActionTriggerEditButton } from '../../../toolympus/components/Actions';

export const ProfileSchema: Schema = {
  email: { label: "Email" },
  lastname: { label: "Фамилия" },
  firstname: { label: "Имя" },
  middlename: { label: "Отчество" },
  phone: { label: "Телефон" },
  company: { label: "Компания" },
  position: { label: "Позиция" },
  is_expert: { label: "Эксперт", type: FieldType.bool },
  is_confirmed: { label: "Подтвержден", type: FieldType.bool },
}

export const UserListUserIDQueryParam = "user";

const UserProfileActionTriggers = [
  "locus.logic.profiles/user-sign-up",
]

export const UserProfilesList = () => {
  const data = useUserProfiles();

  useEffect(() => {
    const userIdFromQuery = new URLSearchParams(window.location.search).get(UserListUserIDQueryParam);
    if(userIdFromQuery && data.data.length) {
      const item = data.data.find(x => x._id === userIdFromQuery);
      if(item) {
        data.editItem.startEditing(item);

        const newUrl = new URL(window.location.href);
        newUrl.searchParams.delete(UserListUserIDQueryParam);
        const newUrlS = newUrl.toString();
        window.history.replaceState({ path: newUrlS }, "", newUrlS);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.data]);

  return (<>
    <SimpleList
      data={{
        ...data,
        filter: data.filter.filter,
        setFilter: data.filter.setFilter,
        create: () => { data.createExpert.startEditing({}); return Promise.resolve({} as Profile); },
      }}
      tabs={[
        ["Все", "all"],
        ["Эксперты", "experts"],
      ]}
      fields={[
        ["email"],
        ["lastname"],
        ["firstname"],
        ["middlename"],
        ["phone"],
        ["company"],
        ["position"],
        ["is_expert"],
        ["is_confirmed"],
      ]}
      linkFields={[]}
      getLinkValue={() => ""}
      schema={ProfileSchema}
      title="Пользователи"
      headerActions={<>
        <LanguageSwitch locale={data.locale} setLocale={data.setLocale} />
        <ActionTriggerEditButton
          triggers={UserProfileActionTriggers}
          apiPath="/api/actions/trigger"
          />
      </>}
      searchAuto
      searchAutoFocus
      fieldElement={f => {
        switch(f) {
          case "lastname":
            return (row,s,orig) => <PseudoLink2 border onClick={() => data.editItem.startEditing(row)}>{orig}</PseudoLink2>
          case "email":
            return (row,s,orig) => (
              <Buttons>
                <PseudoLink2 border onClick={() => data.editItem.startEditing(row)}>
                  {orig}
                </PseudoLink2>
                <IconButton size="small" href={`/system/users?filter=${row._id}`}><CallMade /></IconButton>
              </Buttons>
            )
        }
      }}
      />

      <UserProfileEditDialog data={data.editItem} />
      <UserProfileEditDialog data={data.createExpert} title="Создать эксперта" isCreate />
  </>);
}

const UserProfileEditDialog = ({ data, title, isCreate }: { data: EditItemProps<Profile>, title?: ReactNode, isCreate?: boolean }) => {
  return (
    <Dialog
      isOpen={data.isEditing}
      close={() => data.cancel()}
      noFullscreen
      maxWidth="md"
      fullWidth
      dialogTitle={title || "Пользователь"}>
      {data.item && <>
        <FormGrid columns="1fr 1fr">
          <UserAvatar id={data.item._id} />
          <FormControlsForFields
            data={data.item}
            onChange={(o,c) => data.update(c)}
            schema={ProfileSchema}
            fields={[
              ["is_expert"],
              ["email", {
                readOnly: !isCreate,
                controlProps: {
                  endAdornment: <InputAdornment position="end"><IconButton href={`/system/users?filter=${data.item._id}`} size="small"><CallMade /></IconButton></InputAdornment>
                }
              }],
              ["phone"],
            ]}
            />

          <FormGrid columns="1fr">
            <FormControlsForFields
              data={data.item}
              onChange={(o,c) => data.update(c)}
              schema={ProfileSchema}
              fields={[
                ["lastname"],
                ["firstname"],
                ["middlename"],
                ["company"],
                ["position"],
              ]}
              />
          </FormGrid>

          <FormGrid columns="1fr">
            <FormControlsForFields
              data={data.item.translations?.en || {}}
              onChange={(o,c) => data.update({ translations: { ...(data.item?.translations || {}), en: { ...(data.item?.translations?.en || {}), ...c }}})}
              schema={ProfileSchema}
              fields={[
                ["lastname", { label: "Фамилия (en)" }],
                ["firstname", { label: "Имя (en)" }],
                ["middlename", { label: "Отчество (en)" }],
                ["company", { label: "Компания (en)" }],
                ["position", { label: "Должность (en)" }],
              ]}
              />
          </FormGrid>
        </FormGrid>
      </>}

      {!isCreate && data.item?.is_expert &&
        <ExpertCommentsByAuthor expertId={data.item._id} />}
      
      <DialogActions>
        <Button onClick={() => data.cancel() }><FormattedMessage id="common.cancel" /></Button>
        <Button color="primary" variant="contained" onClick={() => data.save()}><FormattedMessage id="common.save" /></Button>
      </DialogActions>
    </Dialog>
  );
}

const UserAvatar = ({ id }: { id?: string }) => {
  const [isMissing, setIsMissing] = useState<boolean>(false);
  return isMissing || !id
    ? <div style={{ background: "#dddddd", width: 100, height: 100, borderRadius: 50 }} />
    : <img
      alt="avatar"
      src={`/avatar/${id}/avatar.png`}
      style={{ background: "#dddddd", width: 100, height: 100, borderRadius: 50 }}
      onError={() => setIsMissing(true)}
      />
}

const ExpertCommentsByAuthor = ({ expertId }: { expertId?: string }) => {
  const data = useExpertCommentsByAuthor(expertId);
  const schema = useExpertCommentSchema();

  return data.isLoading
    ? <LoadingIndicator />
    : <TableForFields
        fields={[
          ["title"],
          ["comment_date"],
          ["created_datetime"],
          ["is_published"],
        ]}
        data={data.data}
        schema={schema}
        fieldLink={f => {
          switch(f) {
            case "title":
              return row => `/expert-comment/${row._id}`;
          }
        }}
        />
}