import React, { useEffect } from 'react';
import { Form, TabPanel, TabsHeader, useTabsState } from '../../toolympus/components/primitives';
import { CourtsList } from './Courts';
import { DictionariesList } from '../../toolympus/components/Dictionaries/DictionariesList';
import { TopicsList } from './Topics';
import { InstituteList } from './Institutes';
import { KadVerifierList } from './KadVerifier/KadVerifierList';

interface Props {
  
}

const HiddenDictionaries = [
  "courts",
  "courtsshort",
  "rules",
  "institutes",
  "topics",
  "experts",
  "admins",
];

export const AllDictionariesPage = (props: Props) => {
  const tabs = useTabsState([
    ["dictionaries","Справочники"],
    ["courts","Суды"],
    ["institutes","Арбитражные институты"],
    ["topics","Темы"],
    ["kad","КАД верификатор"],
  ], "dictionaries", undefined, "__locus_dicts_tab");

  useEffect(() => {
    const tabFromQuery = new URLSearchParams(window.location.search).get("tab");
    if(tabFromQuery) {
      tabs.setCurrent(tabFromQuery);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form>
        <TabsHeader state={tabs} noMargin />
        
        <TabPanel state={tabs.forTab("dictionaries")}>
          <DictionariesList apiPath="/api/settings/dictionaries" languages={["ru", "en"]} hideDictionaries={HiddenDictionaries} />
        </TabPanel>

        <TabPanel state={tabs.forTab("courts")}>
          <CourtsList />
        </TabPanel>

        <TabPanel state={tabs.forTab("institutes")}>
          <InstituteList />
        </TabPanel>

        <TabPanel state={tabs.forTab("topics")}>
          <TopicsList />
        </TabPanel>

        <TabPanel state={tabs.forTab("kad")}>
          <KadVerifierList />
        </TabPanel>
    </Form>
  );
}
