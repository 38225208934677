import { useState, useEffect, useMemo, useCallback } from "react";
import { toMap } from "../../api/data";
import { Task, ActionDescription } from "./types";
import { apiFetch } from "../../api/core";
import { useValidationErrors } from "../schemed";

export const useExecuteTaskAction = (apiPath: string, onExecuted: (task: Task, action: string, result: any) => void) => {
  const [target,setTarget] = useState<{ task: Task, action: ActionDescription } | null>(null);
  const [params, setParams] = useState<Record<string, any>>({});
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [errorGeneral, setErrorGeneral] = useState<{ error_code: string, _message?: string, values: any } | null>(null);
  const errors = useValidationErrors();

  useEffect(() => {
    if(!target) {
      setParams({});
      setErrorGeneral(null);
      errors.clearErrors();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target]);

  const [paramsFields, paramsSchema] = useMemo(() => {
    if(!target?.action?.params?.length) {
      return [
        [],
        {}
      ];
    } else {
      return [
        target.action.params.map(p => p.field),
        toMap(target.action.params, p => p.field),
      ]
    }
  }, [target?.action]);

  const run = () => {
    if(!target) {
      return Promise.resolve({ task: null, result: { description: [] } });
    }
    setIsRunning(true);
    errors.clearErrors();
    setErrorGeneral(null);
    const action = target.action.action;
    return apiFetch<{ task: Task, result: { description: any }}>(`${apiPath}/action`, "post", {
      task: {
        task_id: target.task.task_id,
        task_type: target.task.task_type,
      },
      action: action,
      params,
    }).then(result => {
      setIsRunning(false);
      onExecuted(result.task, action, result.result);
      cancel();
      return result;
    }).catch(e => {
      setIsRunning(false);
      errors.captureErrors(e);
      setErrorGeneral(e.response?.data?.error_code || e.response?.data?._message ? e.response?.data : { error_code: "error.general" });
      return Promise.resolve({ task: null, result: { description: [] } });
    })

  }

  const cancel = () => {
    setTarget(null);
  }

  const start = useCallback(
    (task: Task, action: ActionDescription) => setTarget({ task, action }),
    []);

  return {
    target,
    isActive: !!target,
    start,

    params,
    paramsFields,
    paramsSchema,
    updateParams: (c: Record<string, any>) => setParams(x => ({ ...x, ...c })),

    run,
    cancel,
    isRunning,
    errors,
    errorGeneral,
  }
}


export type ExecuteTaskActionData = ReturnType<typeof useExecuteTaskAction>;
