import { useCrudItem } from "../../toolympus/api/useSimpleCrud"
import { useLocalizedRecord } from "../../toolympus/components/LocalizedRecords";
import { DefaultLanguage } from "../Common/Localization";
import { ExpertComment, ExpertCommentApiPath } from "./useExpertCommentsList";

export const useExpertCommentEdit = (id: number) => {
  const data = useCrudItem<ExpertComment>(`${ExpertCommentApiPath}/${id}`, {
    defaultValue: {
      _id: -1,
      title: "",
      created_datetime: "",
    }
  });

  const localized = useLocalizedRecord(data.data, {
    defaultLocale: DefaultLanguage,
    translatedFields: ["title", "description"],
    translationsField: "translations",
    translatedInPlaceFields: ["content"],
    updateItem: (o,c) => data.update(c),
  })

  return {
    ...data,
    data: localized.item,
    update: (c: Partial<ExpertComment>) => localized.updateItem(data.data, c),
    locale: localized.locale,
    setLocale: localized.setLocale,
  };
}
