import React from 'react';
import { CMSMediaLibProvider } from './CMSMediaLibProvider';
import { CMSMenu } from '../../toolympus/components/CMS';
import { SubjectOutlined } from '@mui/icons-material';
import { PowerEditorSettingsProvider } from '../../toolympus/components/PowerDoc/PowerEditorSettingsContext';
import { LocusEditorUnwrapped } from './LocusEditor';

interface Props {
  
}

const Icons = {
  cms_pages: <SubjectOutlined />,
}

export const CMS = (props: Props) => {
  return (
    <CMSMediaLibProvider>
      <PowerEditorSettingsProvider EditorComponent={LocusEditorUnwrapped}>
        <CMSMenu apiPath="/api/cms/" icons={Icons} includeMediaLib />
      </PowerEditorSettingsProvider>
    </CMSMediaLibProvider>
  );
}
