import React, { useState } from 'react';
import {
    useHistory,
} from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import InnerRoutes from './InnerRoutes';
import { clearApiToken, decodeToken, getApiToken } from './toolympus/api/core';
// import { SchemaProvider } from './toolympus/hooks/useSchema';
import { AppWrapper, Sidebar, Menu, Routes, getRoutesToRender, AppContentSimple, DrawerPaperView } from './toolympus/components/frame/new';
import { DefaultUser, UserContext } from './toolympus/userContext/UserContext';
import { ExitToApp, Task } from '@mui/icons-material';
import { useLoginGuard } from './toolympus/hooks/auth/useLoginGuard';
import { Logo } from './components/Common';
import { DictionariesProvider } from './toolympus/hooks/useDictionaries';
import styled from '@emotion/styled';
import { CMSMediaLibProvider } from './components/CMS/CMSMediaLibProvider';
import { TasksDrawer } from './toolympus/components/Tasks';
import { useDialogState } from './toolympus/components/primitives';
import { UserSettingsProvider } from './toolympus/components/Settings/UserSettingsContext';

const DrawerPaper = styled(DrawerPaperView)`
  & .MuiDrawer-paper {
    background-color: ${props => props.theme.palette.secondary.main};
  }
`;

const canUpdateGlobalSettings = (u: DefaultUser | undefined) => u?.roles?.includes("manager") || false;

const AppRouter: React.FC = () => {
    const token = getApiToken();
    const history = useHistory();
    const [user, setUser] = useState<DefaultUser>(token ? decodeToken(token) as DefaultUser : { _id: '', roles: [], email: '' });

    const logout = () => {
        clearApiToken();
        history.push('/login');
    }

    useLoginGuard("/login", { isAuthenticated: !!user && user.roles.length > 0 });

    const { routes, menuItems } = getRoutesToRender(InnerRoutes, user);

    const tasksDrawer = useDialogState();

    const bottom = (
        <List>
            <ListItem button key='tasks' onClick={() => tasksDrawer.open()}>
                <ListItemIcon><Task /></ListItemIcon>
                <ListItemText primary='Задачи' />
            </ListItem>
            <ListItem button key='logout' onClick={logout}>
                <ListItemIcon><ExitToApp /></ListItemIcon>
                <ListItemText primary='Выход' />
            </ListItem>
        </List>
    )

    return (
        <UserContext.Provider value={{ user: user, setUser: setUser }}>
          <DictionariesProvider apiPath="/api/settings/dictionaries">
            <CMSMediaLibProvider>
              <UserSettingsProvider apiPath="/api/admin-settings" canUpdateGlobal={canUpdateGlobalSettings} >
                <AppWrapper>
                    <Sidebar
                        top={<Logo />}
                        menu={<Menu menuItems={menuItems} />}
                        bottom={bottom}
                        open={true}
                        onClose={() => { }}
                        drawerPaperComponent={DrawerPaper}
                        drawerPaperProps={{ shadow: true }}
                    />
                    <TasksDrawer
                      apiPath="/api/task"
                      isOpen={tasksDrawer.isOpen}
                      close={tasksDrawer.close}
                      title={<Typography variant="h6">Задачи</Typography>}
                      />
                    <AppContentSimple fit100Height>
                        <Routes routes={routes} notFoundRedirect="/home" />
                    </AppContentSimple>
                </AppWrapper>
              </UserSettingsProvider>
            </CMSMediaLibProvider>
          </DictionariesProvider>
        </UserContext.Provider>
    );
}

export default AppRouter;

