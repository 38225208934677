import React from 'react';
import { ActionRow, MoreActionsMenuButton, OccupyFreeSpace, PseudoLink2 } from '../../../toolympus/components/primitives';
import { RulesEditDialog } from './RulesEditDialog';
import { useManageRules } from './useManageRules';
import { TableForFields } from '../../../toolympus/components/schemed';
import { Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Add } from '@mui/icons-material';

export const RulesList = (props: { instituteId: number }) => {
  const data = useManageRules(props.instituteId);
  return (<>
    <TableForFields
      data={data.data}
      schema={data.schema}
      fields={[
        ["_id", { width: 40 }],
        ["title"],
        ["title_en"],
        ["sort_order"],
      ]}
      fieldElement={f => {
        switch(f) {
          case "_id":
          case "title":
            return (row,schema,orig) => <PseudoLink2 border onClick={() => data.editRules.startEditing(row)}>{orig}</PseudoLink2>
          case "title_en":
            return (row,schema,orig) => <PseudoLink2 border onClick={() => data.editRules.startEditing(row)}>{row.translations?.en?.title || ""}</PseudoLink2>
        }
      }}
      rowButtons={row => (
        <MoreActionsMenuButton actions={[["remove", <FormattedMessage id="common.delete" />, () => data.removeRules.run(row)]]} />
      )}
      />
    <ActionRow>
      <OccupyFreeSpace />
      <Button size="small" color="primary" startIcon={<Add />} onClick={() => data.newRules.startEditing()}>
        <FormattedMessage id="common.add" />
      </Button>
    </ActionRow>

    <RulesEditDialog
      {...data.newRules}
      isOpen={data.newRules.isEditing}
      close={data.newRules.cancel}
      />
    <RulesEditDialog
      {...data.editRules}
      isOpen={data.editRules.isEditing}
      close={data.editRules.cancel}
      />
  </>);
}
