import React from 'react';
import { MoreActionsMenuButton, PseudoLink2, SimpleList } from '../../../toolympus/components/primitives';
import { useManageCourts } from './useManageCourts';
import { CourtEditDialog } from './CourtEditDialog';
import { LanguageSwitch } from '../../Common/Localization';
import { FormattedMessage } from 'react-intl';

export const CourtsList = () => {
  const data = useManageCourts();
  return (<>
    <SimpleList
      title=""
      data={{
        ...data,
        setFilter: data.filter.setFilter,
        filter: data.filter.filter,
        view: "",
        setView: () => {},
        create: () => { data.newCourt.startEditing(); return Promise.resolve({ _id: -1, title: "", title_short: "", title_middle: "", sort_order: -1 }); },
      }}
      searchAuto
      searchAutoFocus
      schema={data.schema}
      fields={[
        ["_id", { width: 40 }],
        ["title"],
        ["title_middle"],
        ["title_short"],
        ["stage"],
        ["sort_order"],
      ]}
      linkFields={[]}
      getLinkValue={() => ""}
      fieldElement={f => {
        switch(f) {
          case "_id":
          case "title":
          case "title_middle":
          case "title_short":
            return (row,schema,orig) => <PseudoLink2 border onClick={() => data.editCourt.startEditing(row)}>{orig}</PseudoLink2>
        }
      }}
      rowButtons={row => (
        <MoreActionsMenuButton actions={[["remove", <FormattedMessage id="common.delete" />, () => data.removeCourt.run(row)]]} />
      )}
      headerActions={<>
        <LanguageSwitch locale={data.locale} setLocale={data.setLocale} />
      </>}
      />

    <CourtEditDialog
      {...data.newCourt}
      isOpen={data.newCourt.isEditing}
      close={data.newCourt.cancel}
      />
    <CourtEditDialog
      {...data.editCourt}
      isOpen={data.editCourt.isEditing}
      close={data.editCourt.cancel}
      />
  </>);
}
