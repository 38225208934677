import React from "react";
import { PowerEditorBaseProps, PowerEditorBase } from "../../toolympus/components/PowerDoc";
import { ColumnsPlugin } from "../../toolympus/components/PowerDoc/plugins/Columns";
import { CustomFieldsBlockPlugin } from "../../toolympus/components/PowerDoc/plugins/CustomFieldsBlock";
import { ExpanderPlugin } from "../../toolympus/components/PowerDoc/plugins/Expander";
import { useMediaFilesPlugin } from "../../toolympus/components/PowerDoc/plugins/MediaFiles";
import { PanelPlugin } from "../../toolympus/components/PowerDoc/plugins/Panel/PanelPlugin";
import { ScriptPlugin } from "../../toolympus/components/PowerDoc/plugins/Script";
import { TablesPlugin } from "../../toolympus/components/PowerDoc/plugins/Tables";
import { TilesPlugin } from "../../toolympus/components/PowerDoc/plugins/Tiles";
import { PowerEditorSettingsProvider } from "../../toolympus/components/PowerDoc/PowerEditorSettingsContext";
import { UpdatePanelBlockPlugin } from "./UpdatePanelBlockPlugin";

export const LocusEditorUnwrapped = (props: PowerEditorBaseProps) => {
  const mediaFilesPlugin = useMediaFilesPlugin();

  return (
      <PowerEditorBase
          {...props}
          plugins={[
              mediaFilesPlugin,
              CustomFieldsBlockPlugin,
              TilesPlugin,
              ColumnsPlugin,
              PanelPlugin,
              ScriptPlugin,
              ExpanderPlugin,
              TablesPlugin,
              UpdatePanelBlockPlugin,
          ]}
          />
  );
}

export const LocusEditor = (props: PowerEditorBaseProps) => {
  return <PowerEditorSettingsProvider EditorComponent={LocusEditorUnwrapped}>
    <LocusEditorUnwrapped {...props} />
  </PowerEditorSettingsProvider>
}