import { useSettings } from "../../../toolympus/api/useSettings";
import { useLocalizedRecord } from "../../../toolympus/components/LocalizedRecords";
import { MenuItem } from "../../../toolympus/components/primitives/BasicMenuEditor";
import { DefaultLanguage } from "../../Common/Localization";
// import { useLocalizedRecord } from "../../../toolympus/components/LocalizedRecords";

interface SiteSettings {
    header_menu: {
      primary: MenuItem[],
      secondary: MenuItem[],
    };
    translations: any;
}

export const useSiteSettingsEdit = () => {
    const data = useSettings<SiteSettings>("/api/settings", { header_menu: { primary: [], secondary: [] }, translations: {} });
    const localized = useLocalizedRecord(data.settings, {
        defaultLocale: DefaultLanguage,
        translatedFields: ["header_menu"],
        translationsField: "translations",
        updateItem: (o,c) => data.update(c),
    });

    return {
        ...data,
        ...localized,
        update: localized.updateItem,
    }
}
