import { CrudItemData } from "../../toolympus/api/useSimpleCrud";
import { useDialogState } from "../../toolympus/components/primitives";
import { CaseRecord } from "./useCaseRecordsList";

export const StatusLabel = {
  "considered-done": "заполнено",
  "confirmed-done": "подтверждено",
  "default": "в процессе",
  "needs-fixing": "требуется доработка",
}

export const StatusColor = {
  "considered-done": "#05a605",
  "confirmed-done": "#1F9DD9",
  "needs-fixing": "#cc0000",
  "default": "#e5b902",
}

export const useCaseRecordReviewStatus = (data: CrudItemData<CaseRecord>) => {
  const dialog = useDialogState();

  const update = (newStatus: CaseRecord["review_status"]) => {
    data.save({ review_status: newStatus });
    dialog.close();
  }

  const status = data.data?.review_status;

  return {
    status,
    statusInfo: {
      label: StatusLabel[status || "default"],
      color: StatusColor[status || "default"],
    },
    statusDatetime: data.data?.review_status_datetime,
    update,
    dialog,
  }
}

export type CaseRecordReviewStatusData = ReturnType<typeof useCaseRecordReviewStatus>;
