import React from 'react';
import { useCaseRecordLinkedCases } from './useCaseRecordLinkedCases';
import { TableForFields } from '../../../toolympus/components/schemed';
import { Schema } from '../../../toolympus/hooks/useSchema';
import { ActionRow, Dialog, LoadingIndicator, OccupyFreeSpace, SearchField } from '../../../toolympus/components/primitives';
import { Button, IconButton } from '@mui/material';
import { Add, DeleteOutlined } from '@mui/icons-material';

interface Props {
  caseRecordId: number;
  caseSchema: Schema;
}

export const CaseRecordLinkedCases = (props: Props) => {
  const data = useCaseRecordLinkedCases(props.caseRecordId);

  return (<>
    <ActionRow firstItemNoMargin>
      {data.isLoading && <LoadingIndicator size="small" />}
      <OccupyFreeSpace />
      <IconButton
        size="small"
        onClick={() => data.newItem.open()}
        >
          <Add />
      </IconButton>
    </ActionRow>
    <TableForFields
      data={data.data}
      schema={props.caseSchema}
      fields={[
        ["number"],
        ["ruling_date"],
        ["court_id"],
        ["created_datetime"],
      ]}
      fieldLink={f => {
        if(f === "number") {
          return r => `/case-record/${r._id}`
        }
      }}
      rowButtons={r => <IconButton size="small" onClick={() => data.removeRelatedCase.run(r)}><DeleteOutlined /></IconButton>}
      />

    <Dialog
      isOpen={data.newItem.isOpen}
      close={() => data.newItem.close()}
      dialogTitle={<>Добавить решение {data.allCases.isLoading && <LoadingIndicator size="small" />}</>}
      maxWidth="md"
      noFullscreen>

        <SearchField
          filter={data.search}
          setFilter={data.setSearch}
          noButton
          autoFocus
          small
          fullWidth
          />

        <TableForFields
          data={data.allCases.data}
          schema={props.caseSchema}
          fields={[
            ["number"],
            ["ruling_date"],
            ["court_id"],
            ["created_datetime"],
          ]}
          fieldLink={f => {
            if(f === "number") {
              return r => `/case-record/${r._id}`
            }
          }}
          rowButtons={row => <Button size="small" color="primary" onClick={() => data.newItem.save(row._id)}>добавить</Button>}
          autoexpander={{ initialRows: 10, increment: 10 }}
          />
    </Dialog>
  </>);
}
