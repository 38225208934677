import React, { ReactNode, useMemo } from 'react';
import styled from '@emotion/styled';
import { SelectChip, SelectChipContainer, useMultiStyle } from '../../../toolympus/components/schemed/Select';
import {
  Checkbox,
  FormControl as FormControlMUI,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select as SelectMUI
} from '@mui/material';
import { useDictionaries } from '../../../toolympus/hooks/useDictionaries';
import { Topic, assembleTree, disassembleTree } from './useManageTopics';
import { FormControlProps } from '../../../toolympus/components/schemed/FormControlProps';

interface Props {
  value: string[] | null | undefined;
  update: (v: string[] | null) => void;
  label: ReactNode;
  error?: boolean;
}

const OptionTitleWrapper = styled.span`
  & > span.level {
    padding-left: 17px;
    letter-spacing: 12px;
    opacity: 0.25;
  }
`;

const OptionCheckbox = ({ checked, level }: { checked?: boolean, level?: number}) => {
  return (
    <OptionTitleWrapper>
      {!!level && <span className="level">{new Array(level || 0).fill("–").join(" ")}</span>}
      <Checkbox checked={!!checked} />
    </OptionTitleWrapper>
  )
}

const useTopicsOptions = (): Topic[] => {
  const dictionaries = useDictionaries();
  const topicsDict = dictionaries.Topics; 
  const options: Topic[] = useMemo(() => {
    const topics = (topicsDict.records || [])
      .map(o => ({
        code: o.code,
        title: o.label,
        parent_code: o.extra?.parent_code,
        is_hidden: o.extra?.is_hidden,
        sort_order: o.sortorder,
      }));

    return disassembleTree(assembleTree(topics));
  }, [topicsDict]);

  return options;
}

export const TopicsSelector = (props: Props) => {
  const { value, update, label, error } = props;
  const { inputId, labelId } = useMultiStyle();

  const selected = (value || []) as any[];
  
  const options = useTopicsOptions();

  return <FormControlMUI fullWidth margin="dense">
      <InputLabel id={labelId}>{label}</InputLabel>
      <SelectMUI
          id={inputId}
          labelId={labelId}
          multiple
          error={error}
          value={selected}
          onChange={e => update(e.target.value as string[] | null)}
          input={<Input />}
          renderValue={_ => (
            <SelectChipContainer>
              {options
                .filter(({code}) => selected.includes(code))
                .map(({code, title}) => 
                    <SelectChip
                        key={code || 'undef'}
                        label={title} />)}
            </SelectChipContainer>
          )}
      >
          {options.map(({ code, title, level, is_hidden }) => (
            <MenuItem onClick={e => e.preventDefault()} key={code || 'undef'} value={code}>
              <OptionCheckbox checked={selected.includes(code)} level={level} />
              <ListItemText primary={title} style={{ opacity: is_hidden ? 0.5 : 1 }} />
            </MenuItem>
          ))}
      </SelectMUI>
  </FormControlMUI>
}

export const TopicsSelectorControl = (props: FormControlProps) => {
  return (
    <TopicsSelector
      label={props.schema?.label || "Темы"}
      value={props.row[props.field]}
      update={topics => props.onChange(props.row, { [props.field]: topics })}
      error={props.errors?.fieldHasErrors(props.field)}
      />
  )
}
