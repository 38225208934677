import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useCaseRecordDocuments } from './useCaseRecordDocuments';
import { FormControlsForFields, TableForFields } from '../../../toolympus/components/schemed';
import { ActionRow, Buttons, ClipboardButton, DeleteButton, FormGrid, LoadingIndicator, OccupyFreeSpace, PseudoLink2, Dialog, Tooltip } from '../../../toolympus/components/primitives';
import { Button, IconButton } from '@mui/material';
import { Add, CallMade, EditOutlined, GetAppOutlined, Link, SwapHoriz } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { createFileDropHandler } from '../../../toolympus/api/files';
import { useCaseDocumentContentDialog } from './useCaseRecordDocumentContent';
import { CaseRecordDocumentContentDialog } from './CaseRecordDocumentContentDialog';

const Wrapper = styled.div<{ isDragTarget?: boolean }>`
  border: 3px dashed ${props => props.isDragTarget ? props.theme.palette.secondary.main : "transparent"};
  border-radius: 8px;
`;

interface Props {
  caseRecordId: number;
}

export const CaseRecordDocuments = (props: Props) => {
  const documents = useCaseRecordDocuments(props.caseRecordId);
  const contentDialog = useCaseDocumentContentDialog();
  const [isDragTarget, setIsDragTarget] = useState<boolean>(false);

  return (<Wrapper
    isDragTarget={isDragTarget}
    onDragEnter={e => { e.preventDefault(); e.stopPropagation(); setIsDragTarget(true); }}
    onDragOver={e => { e.preventDefault(); e.stopPropagation(); setIsDragTarget(true); }}
    onDragLeave={() => setIsDragTarget(false)}
    onDrop={createFileDropHandler(f => { setIsDragTarget(false); documents.upload(f); }, true)}>

    <ActionRow>
      {documents.isLoading && <LoadingIndicator sizeVariant="s" />}
      <OccupyFreeSpace />
      <IconButton
        size="small"
        onClick={() => documents.create()}>
        <Add />
      </IconButton>
    </ActionRow>
    <TableForFields
        fields={[
          ["title"],
          ["comment"],
          ["filename"],
          ["external_url"],
        ]}
        data={documents.data}
        schema={documents.schema}
        fieldElement={f => {
          switch(f) {
            case "title":
              return (row,s,orig) => <PseudoLink2 border onClick={() => documents.editItem.startEditing(row)}>{orig}</PseudoLink2>
            case "external_url":
              return (row,s,orig) => row.external_url
                ? <>{orig} <IconButton size="small" href={row.external_url} target="_blank" rel="noreferrer noopener" style={{ minWidth: "unset" }}><CallMade /></IconButton></>
                : <>{orig}</>;
          }
        }}
        rowButtons={doc => <Buttons style={{ justifyContent: "end" }}>
          <Tooltip text_id="files.copyLink" withWrapper>
              <ClipboardButton value={`/api/case-record/published/document/${doc._id}`} icon={<Link />} />
          </Tooltip>
          <Tooltip text_id="common.download">
              <IconButton size="small" onClick={() => documents.download(doc.fileinfo)}><GetAppOutlined /></IconButton>
          </Tooltip>
          <Tooltip text_id="common.edit">
              <IconButton size="small" onClick={() => documents.editItem.startEditing(doc)}><EditOutlined /></IconButton>
          </Tooltip>
          <Tooltip text_id="common.replace">
              <IconButton size="small" onClick={() => documents.replace(doc.fileinfo)}><SwapHoriz /></IconButton>
          </Tooltip>
          <Tooltip text_id="common.delete" withWrapper>
              <DeleteButton
                  title={<FormattedMessage id="files.removeConfirmation" />}
                  remove={() => documents.remove(doc.fileinfo)}
                  size="small"
                  preventGoBack />
          </Tooltip>
        </Buttons>}
        />

      <Dialog
        dialogTitle="Документ"
        isOpen={documents.editItem.isEditing}
        close={() => documents.editItem.cancel()}
        noFullscreen
        noSubmitOnEnter
        actions={<>
          <Button color="primary" variant="contained" onClick={() => documents.editItem.save()}><FormattedMessage id="common.save" /></Button>
          <Button onClick={() => documents.editItem.cancel()}><FormattedMessage id="common.close" /></Button>
        </>}
        titleActions={<>
          <Button
            size="small"
            color="primary"
            startIcon={<EditOutlined />}
            onClick={() => {
              contentDialog.open({ case_record_id: props.caseRecordId, _id: documents.editItem.item?._id || "" });
              }}>
              текст
          </Button>
        </>}
      >
        {!!documents.editItem.item && <FormGrid columns="1fr" noMargin>
          <FormControlsForFields
            data={documents.editItem.item}
            schema={documents.schema}
            fields={[
              ["title"],
              ["title_en"],
              ["comment", { autoRows: true }],
              ["comment_en", { autoRows: true }],
              ["external_url"],
            ]}
            onChange={(o,c) => documents.editItem.update(c)}
            />
        </FormGrid>}
      </Dialog>

      <CaseRecordDocumentContentDialog
        data={contentDialog}
        />
  </Wrapper>);
}
