import { useIntl } from "react-intl";
import { useEditItem2, useNewItem } from "../../../toolympus/api/useNewItem";
import { useLocalizedList } from "../../../toolympus/components/LocalizedRecords";
import { useTextFilter } from "../../../toolympus/components/schemed/Filtering/useTextFilter";
import { useLoadedData } from "../../../toolympus/hooks/useLoadedData";
import { FieldType, Schema } from "../../../toolympus/hooks/useSchema";
import { DefaultLanguage } from "../../Common/Localization";
import { useItemActionWithConfirmation } from "../../../toolympus/api/useAction";
import { apiFetch } from "../../../toolympus/api/core";

export interface Rules {
  _id: number;
  title: string;
  sort_order: number;
  translations?: any;
  institute_id?: number;
}

export const RulesSchema: Schema = {
  _id: { label: "ID", type: FieldType.number },
  sort_order: { label: "Порядок", type: FieldType.number },
  title: { label: "Название" },
  title_en: { label: "Название (en)" },
  is_adhoc: { label: "Ad hoc", type: FieldType.bool }
}


const ApiPath = "/api/settings/rules";

export const useManageRules = (institute_id: number) => {
  const data = useLoadedData<Rules[]>(`${ApiPath}?institute_id=${institute_id}`, []);
  const filter = useTextFilter<Rules>(c => `${c.title} ${c._id}`);
  const localized = useLocalizedList(data.data, {
    defaultLocale: DefaultLanguage,
    translatedFields: ["title",],
    translationsField: "translations",
    updateItem: () => {},
  });

  const newRules = useNewItem<Omit<Rules, "_id">, Rules>(
    ApiPath,
    {
      title: "",
      institute_id,
      sort_order: Math.max(...data.data.map(c => c.sort_order), 0) + 1,
      translations: { en: { title: "", }},
    });

  const editRules = useEditItem2<Rules>({
    getApiPath: c => `${ApiPath}/${c._id}`,
  });

  const { formatMessage } = useIntl();
  const removeRules = useItemActionWithConfirmation<Rules, {}>(
    item => apiFetch<Rules>(`${ApiPath}/${item?._id}`, "delete")
      .then(x => {
        data.reload();
        return x;
    }),
    {
      title: formatMessage({ id: "dictionaries.remove_title" }),
      confirmationHint: formatMessage({ id: "dictionaries.remove_hint" }),
    }
  );

  return {
    ...data,
    data: filter.filterData(localized.items),
    filter,
    schema: RulesSchema,

    newRules: {
      ...newRules,
      save: () => newRules.save().then(c => { data.reload(); return c; }),
    },
    editRules: {
      ...editRules,
      save: () => editRules.save().then(c => { data.reload(); return c; }),
    },
    removeRules,

    locale: localized.locale,
    setLocale: localized.setLocale,
  }
}
