import React from 'react';
import { Rules, RulesSchema } from './useManageRules';
import { FormGrid, SimpleDialog } from '../../../toolympus/components/primitives';
import { FormControlsForFields } from '../../../toolympus/components/schemed';

interface Props {
  isOpen: boolean;
  close: () => void;
  item: Omit<Rules, "_id"> | null;
  update: (changes: Partial<Rules>) => void;
  save: () => Promise<Rules>;
}

export const RulesEditDialog = (props: Props) => {
  const { isOpen, close, item, update, save } = props;
  return (
    <SimpleDialog
      dialogTitle="Правила"
      close={close}
      isOpen={isOpen}
      save={save}
      maxWidth="sm">

        <FormGrid columns="1fr 1fr">
          {item && <FormControlsForFields
            data={item}
            onChange={(o,c) => update(c)}
            schema={RulesSchema}
            fields={[
              ["title"],
            ]}
            />}

          {item && <FormControlsForFields
            data={item.translations?.en || {}}
            onChange={(o,c) => update({ translations: { en: { ...o, ...c } }})}
            schema={RulesSchema}
            fields={[
              ["title", { label: "Название (en)"}],
            ]}
            />}

          <div />
          {item && <FormControlsForFields
            data={item}
            onChange={(o,c) => update(c)}
            schema={RulesSchema}
            fields={[
              ["sort_order"],
            ]}
            />}
        </FormGrid>
      
    </SimpleDialog >
  );
}
