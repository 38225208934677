import { useState } from "react";
import { apiFetch } from "../../../toolympus/api/core";
import { useItemActionWithConfirmation } from "../../../toolympus/api/useAction";
import { useEditItem2, useNewItem } from "../../../toolympus/api/useNewItem";
import { useLoadedData } from "../../../toolympus/hooks/useLoadedData";
import { useSingleSchema } from "../../../toolympus/hooks/useSchema";
import { CaseRecordParty } from "../types";
import { CaseRecordApiPath } from "../useCaseRecordsList"

export type CasePartySuggestion = Pick<CaseRecordParty, "tin" | "title" | "country" | "translations">;

export interface CasePartySuggestions {
  isLoading: boolean;
  suggestions: CasePartySuggestion[];
  requestSuggestions: (text: string) => void;
  clearSuggestions: () => void;
  createSuggestionChanges: (s: CasePartySuggestion, old: Partial<CaseRecordParty> | undefined | null) => Partial<CaseRecordParty>;
}


export const useCasePartySuggestions = (): CasePartySuggestions => {
  const [text,setText] = useState<string>("");
  const data = useLoadedData<CasePartySuggestion[]>(`${CaseRecordApiPath}/party-suggest?tin=${text}`, [], !!text);

  const createSuggestionChanges = (s: CasePartySuggestion, old?: Partial<CaseRecordParty> | undefined | null) => ({
    title: s.title,
    country: s.country,
    tin: s.tin,
    translations: {
      ...(old?.translations || {}),
      en: {
        ...(old?.translations?.en || {}),
        title: s.translations?.en?.title,
      },
    },
  });

  return {
    isLoading: data.isLoading,
    suggestions: data.data,
    requestSuggestions: v => { data.setData([]); setText(v); },
    clearSuggestions: () => { setText(""); data.setData([]); },
    createSuggestionChanges,
  }
}

export const useCaseRecordParties = (caseRecordId: number) => {
  const data = useLoadedData<CaseRecordParty[]>(`${CaseRecordApiPath}/${caseRecordId}/party`, [], caseRecordId >= 0);

  const { schema } = useSingleSchema(`${CaseRecordApiPath}/0/party/uiconfig`)

  const suggestions = useCasePartySuggestions();

  const newItem = useNewItem<Omit<CaseRecordParty, "_id" | "case_record_id">, CaseRecordParty>(
    `${CaseRecordApiPath}/${caseRecordId}/party`,
    { title: "", kind: "claimant" },
  );

  const editItem = useEditItem2<CaseRecordParty>({
    getApiPath: (x) => `${CaseRecordApiPath}/${caseRecordId}/party/${x._id}`,
  });

  const removeItem = useItemActionWithConfirmation<CaseRecordParty,{}>(
    item => apiFetch<{}>(`${CaseRecordApiPath}/${item.case_record_id}/party/${item._id}`, "delete").then(x => { data.reload(); return x; }),
    { 
      title: "Удалить участника",
    });
    

  return {
    ...data,
    schema,
    newItem: {
      ...newItem,
      save: () => newItem.save().then(x => { data.reload(); return x; })
    },
    editItem: {
      ...editItem,
      save: () => editItem.save().then(x => { data.reload(); return x; })
    },
    removeItem: removeItem.run,

    suggestions,
  }
}
