import React, { PropsWithChildren } from 'react';
import { MediaLibProvider } from '../../toolympus/components/medialib';

export const CMSMediaLibProvider = (props: PropsWithChildren<{}>) => {
  return (
    <MediaLibProvider
      apiPath="/api/media"
      spaceId="cms"
      getFilepath={mf => `/media/${mf.space_id}/${mf.filename}`}>
      {props.children}
    </MediaLibProvider>
  );
}
