import React, { memo, useCallback } from 'react';
import styled from '@emotion/styled';
import { Buttons, Dialog, Form, FormGrid, LoadingIndicator, MoreActionsMenuButton, OccupyFreeSpace, PseudoLink2, SearchField } from '../../../toolympus/components/primitives';
import { LanguageSwitch } from '../../Common/Localization';
import { Topic, TopicSchema, useManageTopics } from './useManageTopics';
import { TopicEditDialog } from './TopicEditDialog';
import { Button, Checkbox, DialogActions, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { Add, ArrowRight, Close, TrendingFlat, TrendingUp } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';

const LevelMarker = ({ level }: { level?: number }) => {
  return <>
    {!!level && <svg
      x="0px" y="0px"
      viewBox="0 0 20 15"
      style={{ width: 20, height: 15, marginLeft: (level || 0) * 20 -20 + 15 }}>
      <path style={{ fill: "none", stroke: "#00000000", strokeWidth: 1.5 }} d="M1,0V7H30" />
    </svg>}
  </>
}

const ChangeParentPanel = styled.div`
  display: flex;
  flex-flow: row;
  gap: 0.5rem;
  align-items: center;

  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 300;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px #00000040;
  padding: 0.5rem 1rem;

  & .title {
    color: ${props => props.theme.palette.primary.main};
    border-bottom: 1px dotted ${props => props.theme.palette.primary.main};
  }
`;

const CollapseButton = styled(ArrowRight)<{ isCollapsed?: boolean, isCollapsible?: boolean }>`
  cursor: pointer;
  transform: rotate(${props => props.isCollapsed ? 0 : 90}deg);
  opacity: ${props => props.isCollapsed ? 0.35 : 0.15};
  visibility: ${props => props.isCollapsible ? "visible" : "hidden"};
`;
CollapseButton.defaultProps = { role: "button" };

const TopicRow = styled(TableRow)<{ isHighlight?: boolean }>`
  background: ${props => props.isHighlight ? "#1F9DD920" : "transparent"};
`;

interface TopicRowProps {
  row: Topic;
  locale: string;
  handleRowClick: (row: Topic) => void;
  isCollapsible?: boolean;
  isCollapsed?: boolean;
  toggleCollapsed: (r: Topic) => void;
  startChangingParent: (row: Topic) => void;
  startAddingTopic: (row: Partial<Topic>) => void;
  startRemoveTopic: (row: Topic) => any;
}

const TopicRowX = (props: TopicRowProps) => {
  const { row, isCollapsible, isCollapsed, toggleCollapsed, handleRowClick, locale } = props;
  return (
    <TopicRow key={row.code} >
      <TableCell>
        <Buttons>
          <LevelMarker level={row.level} />
          <CollapseButton
            isCollapsible={isCollapsible}
            isCollapsed={isCollapsed}
            onClick={() => toggleCollapsed && toggleCollapsed(row)}
            />
          <PseudoLink2
            border
            onClick={() => handleRowClick(row)}
            >
            {locale === "en" ? row.translations?.en?.title || "..." : row.title}
          </PseudoLink2>
        </Buttons>
      </TableCell>
      <TableCell>
        <Checkbox disabled checked={!!row.is_hidden} />
      </TableCell>
      <TableCell>
        <Buttons>
          <IconButton size="small" onClick={() => props.startAddingTopic({ parent_code: row.code })}><Add /></IconButton>
          <IconButton size="small" onClick={() => props.startChangingParent(row)}><TrendingUp /></IconButton>
          <MoreActionsMenuButton actions={[["remove", <FormattedMessage id="common.delete" />, () => props.startRemoveTopic(row)]]} />
        </Buttons>
      </TableCell>
    </TopicRow>
  )
}

const TopicRowMemo = memo(TopicRowX);

export const TopicsList = () => {
  const data = useManageTopics();

  const handleRowClick = useCallback((row: Topic) => {
    if(!!data.changeParent.item) {
      data.changeParent.selectNewParent(row);
    } else {
      data.editTopic.startEditing(row);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!data.changeParent.item]);

  return (<>
    <Form
      title=""
      headerItems={<>
        <IconButton color='primary' size="small" onClick={() => data.newTopic.startEditing()}><Add /></IconButton>
        <LanguageSwitch locale={data.locale} setLocale={data.setLocale} />
        <Button size="small" onClick={() => data.collapser.collapseAll()}>свернуть все</Button>
        <Button size="small" onClick={() => data.collapser.uncollapseAll()}>развернуть все</Button>

        <OccupyFreeSpace />

        {data.isLoading && <LoadingIndicator sizeVariant="m" />}
        <SearchField
            filter={data.filter.filter}
            setFilter={data.filter.setFilter}
            buttonLabel=" "
            noButton
            autoFocus
        />
      </>}>

      <TableContainer>
        <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  {TopicSchema.title?.label}
                </TableCell>
                <TableCell>
                  {TopicSchema.is_hidden?.label}
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {data.data.filter(row => !data.collapser.isHidden(row)).map((row) => (
                <TopicRowMemo
                  key={row.code}
                  row={row}
                  handleRowClick={handleRowClick}
                  locale={data.locale}
                  isCollapsible={data.collapser.isCollapsible(row)}
                  isCollapsed={data.collapser.isCollapsed(row)}
                  toggleCollapsed={data.collapser.toggleCollapsed}
                  startAddingTopic={data.newTopic.startEditing}
                  startChangingParent={data.changeParent.startChanging}
                  startRemoveTopic={data.removeTopic.run}
                  />
                ))}
            </TableBody>
        </Table>
      </TableContainer>
    </Form>

    <TopicEditDialog
      {...data.newTopic}
      isOpen={data.newTopic.isEditing}
      close={data.newTopic.cancel}
      isNew
      />
    <TopicEditDialog
      {...data.editTopic}
      isOpen={data.editTopic.isEditing}
      close={data.editTopic.cancel}
      />

    {!!data.changeParent.item &&
      <ChangeParentPanel>
        <Typography>Изменить группу для</Typography>
        <Typography className="title">{data.changeParent.item.title}</Typography>
        <IconButton size="small" onClick={() => data.changeParent.cancel()}><Close /></IconButton>
      </ChangeParentPanel>}

    <Dialog
      dialogTitle="Изменить группу?"
      noFullscreen
      isOpen={!!data.changeParent.item && !!data.changeParent.newParent}
      close={() => data.changeParent.cancel()}
      >
      <FormGrid columns="1fr max-content 1fr">
        <TextField value={data.changeParent.item?.title} label="Переносим" />
        <IconButton size="small" style={{ gridRow: "span 2", alignSelf: "center" }}><TrendingFlat /></IconButton>
        <TextField value={data.changeParent.newParent?.title} label="В группу" />

        <TextField value={data.changeParent.item?.code} />
        <TextField value={data.changeParent.newParent?.code} />
      </FormGrid>

      <DialogActions>
        <Button onClick={() => data.changeParent.cancel()}><FormattedMessage id="common.cancel" /></Button>
        <Button variant="contained" color="primary" onClick={() => data.changeParent.commitChange()}><FormattedMessage id="common.save" /></Button>
      </DialogActions>
    </Dialog>
  </>);
}
