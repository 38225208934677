import { Button, Typography } from '@mui/material';
import React, { ReactNode, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormGrid } from './Forms';

interface Props {
  message?: ReactNode;
  messageAfterReset?: ReactNode;
  redirectAfterReset?: string;
  redirectTimeout?: number;
  resetAuto?: boolean;
}

export const ResetLocalStorage = (props: Props) => {
  const [doReset,setDoReset] = useState<boolean>(props.resetAuto || false);
  const [resetDone,setResetDone] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    if(doReset) {
      localStorage.clear();
      setResetDone(true);
      if(props.redirectAfterReset) {
        setTimeout(() => history.push(props.redirectAfterReset || "/"), props.redirectTimeout || 3000);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doReset]);

  return (
    <FormGrid columns="1fr">
      {!resetDone && <Typography>{props.message || "To reset app state press the button below. It will clear all app settings stored in the browser."}</Typography>}
      {!resetDone && <Button size="small" color="primary" onClick={() => setDoReset(true)}>reset</Button>}
      {!!resetDone && <Typography>{props.messageAfterReset || "App state has been reset. Please go to the login page to sign in and start fresh."}</Typography>}
    </FormGrid>
  );
}
