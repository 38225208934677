import React from 'react';
import { CaseRecordReviewStatusData, StatusColor } from './useCaseRecordReviewStatus';
import { Button, Typography } from '@mui/material';
import { FormGrid, SimpleDialog } from '../../toolympus/components/primitives';
import { CaseRecord } from './useCaseRecordsList';
import { checkRole, useUser } from '../../toolympus/userContext/UserContext';
import { Check, WarningOutlined } from '@mui/icons-material';
import { useFormats } from '../../toolympus/components/schemed';
import { utc } from '../../toolympus/components/timezone';

interface Props {
  data: CaseRecordReviewStatusData;
}

export const CaseRecordReviewStatusButton = (props: Props) => {
  const { data } = props;

  const { user } = useUser();

  const formats = useFormats();

  return (
    <>
      <Typography variant="caption">Статус:</Typography>
      <Button
        size="small"
        startIcon={data.status === "confirmed-done"
            ? <Check />
            : data.status === "needs-fixing"
              ? <WarningOutlined />
              : undefined
        }
        onClick={() => data.dialog.open()}
        style={{ color: data.statusInfo.color }}>
        {data.statusInfo.label}
      </Button>

      <SimpleDialog
        dialogTitle={`Статус: ${data.statusInfo.label}`}
        isOpen={data.dialog.isOpen}
        save={() => data.dialog.close()}
        close={() => data.dialog.close()}
        noSave
        noFullscreen>

        {!!data.statusDatetime &&
          <Typography>
            Статус обновлен: <span style={{ fontWeight: 500 }}>{formats.formatDatetime(utc.toLocal(data.statusDatetime))}</span>
          </Typography>}
        
        {!data.status &&
          <Typography>
            Если заполнение информации о решении завершено, вы можете отметить решение как завершенное - в таком случае оно пропадет из дэшбордов с ошибками.

            Решение с незаполненными полями, отмеченное как звершенное, попадет в дэшборды для ревью. Если потребуется, его вернут на доработку.
          </Typography>}

          {data.status === "considered-done" &&
            <Typography>
              Решение отмечено как завершенное. Если требуется, его можно вернуть на доработку.
            </Typography>}

          {data.status === "confirmed-done" &&
            <Typography>
              Решение отмечено как завершенное и прошло ревью. Если требуется, его можно вернуть на доработку.
            </Typography>}

          {data.status === "needs-fixing" &&
            <Typography>
              По решению запрошена доработка. После внесения правок отметьте его как завершенное.
            </Typography>}

          <FormGrid columns="1fr">
            {([
              ["considered-done", "заполнение завершено", !data.status || data.status === "needs-fixing"],
              ["confirmed-done", "подтвердить завершение", checkRole(user, "manager") && data.status !== "confirmed-done"],
              ["needs-fixing", "требуются правки", data.status !== "needs-fixing"],
            ] as [NonNullable<CaseRecord["review_status"]>,string,boolean][])
            .filter(([action,label,available]) => !!available)
            .map(([action,label]) => (
              <Button size="small" key={action} onClick={() => data.update(action)} style={{ color: StatusColor[action] }}>
                {label}
              </Button>
            ))}
          </FormGrid>
      </SimpleDialog>
    </>
  );
}
