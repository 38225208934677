import { useCrudItem } from "../../toolympus/api/useSimpleCrud"
import { useLocalizedRecord } from "../../toolympus/components/LocalizedRecords";
import { DefaultLanguage } from "../Common/Localization";
import { CountryPractice, CountryPracticeApiPath } from "./useCountryPracticeList";

export const useCountryPracticeEdit = (id: number) => {
  const data = useCrudItem<CountryPractice>(`${CountryPracticeApiPath}/${id}`, {
    defaultValue: {
      _id: -1,
      title: "",
      slug: "",
      created_datetime: "",
      authors: [],
      partners: [],
    }
  });

  const localized = useLocalizedRecord(data.data, {
    defaultLocale: DefaultLanguage,
    translatedFields: ["title", "description", "authors", "partners"],
    translationsField: "translations",
    translatedInPlaceFields: ["content"],
    updateItem: (o,c) => data.update(c),
  })

  return {
    ...data,
    data: localized.item,
    update: (c: Partial<CountryPractice>) => localized.updateItem(data.data, c),
    locale: localized.locale,
    setLocale: localized.setLocale,
  };
}
