import React, { useMemo } from 'react';
import { useCaseRecordParties } from './useCaseRecordParties';
import { ActionRow, FormGrid, LoadingIndicator, OccupyFreeSpace, PseudoLink2, SimpleDialog } from '../../../toolympus/components/primitives';
import { FieldDefinition, FormControlsForFields, TableForFields } from '../../../toolympus/components/schemed';
import { IconButton } from '@mui/material';
import { Add, DeleteOutlined } from '@mui/icons-material';
import { CaseRecordParty } from '../types';
import { createTranslator2 } from '../../../toolympus/components/LocalizedRecords';
import { DefaultLanguage } from '../../Common/Localization';
import { TinEditor } from './TinEditor';

interface Props {
  caseRecordId: number;
  locale: string;
}

const PartyFields: FieldDefinition[] = [
  ["kind", { labelIdPrefix: "case_records.participants.kind" }],
  ["title"],
  ["country"],
];

const updateTranslation = (orig: Partial<CaseRecordParty>, lang: string, f: keyof CaseRecordParty, v: string) => {
  return { translations: { ...(orig.translations || {}), [lang]: { ...((orig.translations || {})[lang] || {}), [f]: v }}};
}

export const CaseRecordParties = (props: Props) => {
  const data = useCaseRecordParties(props.caseRecordId);
  
  const translate = useMemo(() => props.locale === DefaultLanguage
    ? (x: CaseRecordParty) => x
    : createTranslator2<CaseRecordParty>({ translationsField: "translations", translatedFields: ["title", "kind_other"] }, props.locale),
    [props.locale]);
  
  return (
    <>
      <ActionRow>
        {data.isLoading && <LoadingIndicator sizeVariant="m" />}
        <OccupyFreeSpace />
        <IconButton
          size="small"
          onClick={() => data.newItem.startEditing()}
          >
          <Add />
        </IconButton>
      </ActionRow>

      <TableForFields
        data={data.data.map(translate)}
        fields={PartyFields}
        schema={data.schema}
        onDblClick={row => data.editItem.startEditing(row)}
        fieldElement={f => {
          switch(f) {
            case "title":
              return (row,s,orig) => <PseudoLink2 border onClick={() => data.editItem.startEditing(row)}>{orig}</PseudoLink2>
            case "kind":
              return (row,s,orig) => (
                <PseudoLink2 border onClick={() => data.editItem.startEditing(row)}>
                  {row.kind === "other" && row.kind_other ? row.kind_other : orig}
                </PseudoLink2>)

          }
        }}
        rowButtons={row => <IconButton size="small" color="default" onClick={() => data.removeItem(row)}><DeleteOutlined /></IconButton>}
        />

      <SimpleDialog
        dialogTitle="Новый участник"
        isOpen={data.newItem.isEditing}
        close={() => data.newItem.cancel()}
        save={() => data.newItem.save()}
        submitOnModEnter
      >
        {!!data.newItem.item && <FormGrid columns="1fr" noMargin>
          <FormControlsForFields
            data={data.newItem.item}
            schema={data.schema}
            fields={[
              ["kind", { labelIdPrefix: "case_records.participants.kind", controlProps: { autoFocus: true }, autoComplete: true }],
            ]}
            onChange={(o,c) => data.newItem.update(c)}
            />

          {data.newItem.item?.kind === "other" &&
            <FormGrid columns="1fr 1fr" noMargin>
              <FormControlsForFields
                data={data.newItem.item}
                schema={data.schema}
                fields={[
                  ["kind_other", { label: "Сторона" }],
                ]}
                onChange={(o,c) => data.newItem.update(c)}
                />
              <FormControlsForFields
                data={data.newItem.item.translations?.en || {}}
                schema={data.schema}
                fields={[["kind_other", { label: "Сторона (en)" }]]}
                onChange={(o,c) => data.newItem.update(updateTranslation(data.newItem.item || {}, "en", "kind_other", c.kind_other))}
                />
            </FormGrid>}

          <TinEditor
            value={data.newItem.item?.tin || ""}
            update={tin => data.newItem.update({ tin })}
            schema={data.schema.tin}
            suggestions={data.suggestions}
            selectSuggestion={s => {
              data.newItem.update(data.suggestions.createSuggestionChanges(s, data.newItem.item))
            }}
            />

          <FormControlsForFields
            data={data.newItem.item}
            schema={data.schema}
            fields={[
              ["title"],
            ]}
            onChange={(o,c) => data.newItem.update(c)}
            />
          <FormControlsForFields
            data={data.newItem.item.translations?.en || {}}
            schema={data.schema}
            fields={[["title", { label: "Название (en)" }]]}
            onChange={(o,c) => data.newItem.update(updateTranslation(data.newItem.item || {}, "en", "title", c.title))}
            />
          <FormControlsForFields
            data={data.newItem.item}
            schema={data.schema}
            fields={[
              ["country", { autoComplete: true }],
            ]}
            onChange={(o,c) => data.newItem.update(c)}
            />
        </FormGrid>}
      </SimpleDialog>


      <SimpleDialog
        dialogTitle="Участник"
        isOpen={data.editItem.isEditing}
        close={() => data.editItem.cancel()}
        save={() => data.editItem.save()}
        submitOnModEnter
      >
        {!!data.editItem.item && <FormGrid columns="1fr" noMargin>
          <FormControlsForFields
            data={data.editItem.item}
            schema={data.schema}
            fields={[
              ["kind", { labelIdPrefix: "case_records.participants.kind", autoComplete: true }],
            ]}
            onChange={(o,c) => data.editItem.update(c)}
            />

          {data.editItem.item?.kind === "other" &&
            <FormGrid columns="1fr 1fr" noMargin>
              <FormControlsForFields
                data={data.editItem.item}
                schema={data.schema}
                fields={[
                  ["kind_other", { label: "Сторона" }],
                ]}
                onChange={(o,c) => data.editItem.update(c)}
                />
              <FormControlsForFields
                data={data.editItem.item.translations?.en || {}}
                schema={data.schema}
                fields={[["kind_other", { label: "Сторона (en)" }]]}
                onChange={(o,c) => data.editItem.update(updateTranslation(data.editItem.item || {}, "en", "kind_other", c.kind_other))}
                />
            </FormGrid>}

          <TinEditor
            value={data.editItem.item?.tin || ""}
            update={tin => data.editItem.update({ tin })}
            schema={data.schema.tin}
            suggestions={data.suggestions}
            selectSuggestion={s => {
              data.editItem.update(data.suggestions.createSuggestionChanges(s, data.editItem.item))
            }}
            />

          <FormControlsForFields
            data={data.editItem.item}
            schema={data.schema}
            fields={[
              ["title"],
            ]}
            onChange={(o,c) => data.editItem.update(c)}
            />
          <FormControlsForFields
            data={data.editItem.item.translations?.en || {}}
            schema={data.schema}
            fields={[["title", { label: "Название (en)" }]]}
            onChange={(o,c) => data.editItem.update(updateTranslation(data.editItem.item || {}, "en", "title", c.title))}
            />
          <FormControlsForFields
            data={data.editItem.item}
            schema={data.schema}
            fields={[
              ["country", { autoComplete: true, controlProps: { onKeyPress: (e: any) => { if(e.key === "Enter") { e.stopPropagation(); }}} }],
            ]}
            onChange={(o,c) => data.editItem.update(c)}
            />
        </FormGrid>}
      </SimpleDialog>
    </>
  );
}
