import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { useTextFilter } from "../../toolympus/components/schemed/Filtering/useTextFilter";
import { useSingleSchema } from "../../toolympus/hooks/useSchema";
import { useNewItem } from "../../toolympus/api/useNewItem";
import { useLoadedList } from "../../toolympus/hooks/useLoadedList";

export interface ExpertComment {
  _id: number;
  title: string;
  created_datetime: string;
  comment_date?: string;
  is_published?: boolean;
  topics?: string[] | null;

  description?: any;
  content?: any;
  translations?: any;
}

export type NewExpertComment = Omit<ExpertComment, "_id" | "created_datetime">;

export const ExpertCommentApiPath = "/api/expert-comment"

export const useExpertCommentSchema = () => {
  const { schema } = useSingleSchema(`${ExpertCommentApiPath}/uiconfig`);
  return schema;
}

export const useExpertCommentsList = () => {
  const data = useLoadedList<ExpertComment>(ExpertCommentApiPath, {
    sorting: {
      disallowedFields: ["description"],
    },
    viewDefault: "unpublished",
    viewLSKey: "__locus_eclist_view",
  });
  const filter = useTextFilter<ExpertComment>(cr => `${cr.title} ${cr._id}`);

  const newRecord = useNewItem<NewExpertComment, ExpertComment>(ExpertCommentApiPath, { title: "" });

  return {
    ...data,
    data: filter.filterData(data.data),
    filter,

    newRecord,
  }
}

export type ExpertCommentListData = ReturnType<typeof useExpertCommentsList>;

export const useExpertCommentsByAuthor = (expertId?: string) => {
  return useLoadedData<ExpertComment[]>(`${ExpertCommentApiPath}?author_id=${expertId}`, [], !!expertId);
}
