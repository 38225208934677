import { useState } from "react";
import { CreateExpertProfileApiPath, Profile } from "../Administration/Users/useUserProfiles";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { useEditItem2 } from "../../toolympus/api/useNewItem";
import { useTextFilter } from "../../toolympus/components/schemed/Filtering/useTextFilter";
import { apiFetch } from "../../toolympus/api/core";

export interface ExpertApplication extends Profile {
  topics?: string[] | null;
  comment?: string;
  cv_file_id?: string;
  education: string[];

  user_id?: string;
  approval_status?: string;
  created_datetime?: string;
}

const applicationToExpert = (a: ExpertApplication): Profile => {
  const result: Profile = {
    email: a.email,
    phone: a.phone,
    is_expert: a.is_expert,
    
    firstname: a.firstname,
    lastname: a.lastname,
    middlename: a.middlename,
    
    company: a.company,
    position: a.position,
  };
  if(a.user_id) {
    result._id = a.user_id;
  }
  return result;
};

const ExpertApplicationsApiPath = "/api/expert/application";

export const useExpertsApplicationsList = () => {
  const [view, setView] = useState<string>("all");
  const data = useLoadedData<ExpertApplication[]>(`${ExpertApplicationsApiPath}?view=${view}`, []);

  const editItem = useEditItem2<ExpertApplication>({
    getApiPath: e => `${ExpertApplicationsApiPath}/${e._id}`,
  });

  const filter = useTextFilter<ExpertApplication>(p => `${p.email} ${p.lastname} ${p.firstname} ${p.company}`);

  const reject = () => {
    if(editItem.item) {
      editItem.save();
      apiFetch<ExpertApplication>(`${ExpertApplicationsApiPath}/${editItem.item._id}`, "put", { approval_status: "rejected" })
        .then(a => {
          data.reload();
          editItem.setItem(a);
        })
    }
  }

  const cancelReject = () => {
    if(editItem.item) {
      editItem.save();
      apiFetch<ExpertApplication>(`${ExpertApplicationsApiPath}/${editItem.item._id}`, "put", { approval_status: "new" })
        .then(a => {
          data.reload();
          editItem.setItem(a);
        })
    }
  }

  const approve = () => {
    if(editItem.item) {
      editItem.save();
      apiFetch<Profile>(CreateExpertProfileApiPath, "post", applicationToExpert(editItem.item))
        .then(profile => editItem.item && apiFetch<ExpertApplication>(`${ExpertApplicationsApiPath}/${editItem.item._id}`, "put", { approval_status: "approved", user_id: profile._id }))
        .then(a => {
          data.reload();
          if(a) {
            editItem.setItem(a);
          }
        });
    }
  }

  return {
    ...data,
    data: filter.filterData(data.data),
    filter,
    view,
    setView,

    editItem: {
      ...editItem,
      save: () => editItem.save().then(x => { data.reload(); return x; }),
      reject,
      cancelReject,
      approve,
    }
  }
}
