import React, { ReactNode, useEffect } from 'react';
import styled from '@emotion/styled';
import { useState } from "react";
import { TaskListData, useTaskList } from "./useTaskList"
import { Drawer, Paper } from "@mui/material";
import { TaskList } from "./TaskList";

interface TasksDrawerProps {
  data: TaskListData;
  isOpen: boolean;
  close: () => void;
  title?: ReactNode;
}

const PaperX = styled(Paper)`
  padding: 0.5rem 1rem;
  max-width: 60%;
  min-width: 500px;
`;

export const TasksDrawerUnbound = (props: TasksDrawerProps) => {
  return (
    <Drawer
      PaperProps={{
        component: PaperX
      }}
      anchor="right"
      open={props.isOpen}
      onClose={() => props.close()}>
      <TaskList title={props.title} data={props.data} />
    </Drawer>
  )
}


export const TasksDrawer = (props: { apiPath: string, title?: React.ReactNode, isOpen: boolean, close: () => void }) => {
  const [doLoad, setDoLoad] = useState<boolean>(false);
  useEffect(() => {
    setDoLoad(true);
  }, [props.isOpen]);
  const data = useTaskList(props.apiPath, { dontLoad: !doLoad });

  return (
    <TasksDrawerUnbound
      data={data}
      isOpen={props.isOpen}
      close={props.close}
      title={props.title}
      />
  )
}
