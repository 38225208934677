import React from 'react';
import { IconButton, Typography } from '@mui/material';
import { SaveOutlined } from '@mui/icons-material';
import { Form, FormGrid, LoadingIndicator, OccupyFreeSpace, TabPanel, TabsHeader, useSaveable, useTabsState } from '../../../toolympus/components/primitives';
import { useSiteSettingsEdit } from './useSiteSettingsEdit';
import { LanguageSwitch } from '../../Common/Localization';
import { BasicMenuEditor } from '../../../toolympus/components/primitives/BasicMenuEditor';

export const SiteSettingsPage = () => {
    const data = useSiteSettingsEdit();

    useSaveable(data);

    const tabs = useTabsState([
        ["header_menu", "Меню в шапке"],
    ], "header_menu", "tab");

    return (
        <Form title="Настройки"
            headerItems={<>
                <OccupyFreeSpace />
                {data.isLoading && <LoadingIndicator />}
                {data.hasChanges && <IconButton size="small" onClick={() => data.save()}><SaveOutlined /></IconButton>}
                <LanguageSwitch locale={data.locale} setLocale={data.setLocale} />
            </>}>
            <TabsHeader state={tabs} noMargin />

            <TabPanel state={tabs.forTab("header_menu")}>
                <FormGrid columns="1fr">
                  <BasicMenuEditor
                    menu={data.item.header_menu.primary}
                    update={v => data.update(data.item, { header_menu: { ...(data.item.header_menu || {}), primary: v }})}
                    label={<Typography>Первая строка</Typography>}
                    />

                  <BasicMenuEditor
                    menu={data.item.header_menu.secondary}
                    update={v => data.update(data.item, { header_menu: { ...(data.item.header_menu || {}), secondary: v }})}
                    label={<Typography>Вторая строка</Typography>}
                    />
                </FormGrid>
            </TabPanel>
        </Form>
    );
}
