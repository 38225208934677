import React from 'react';
import { SimpleList } from '../../toolympus/components/primitives';
import { useHistory } from 'react-router-dom';
import { useFields } from '../../toolympus/components/schemed';
import { ExpertComment, useExpertCommentSchema, useExpertCommentsList } from './useExpertCommentsList';
import { CreateExpertCommentDialog } from './CreateExpertCommentDialog';
import { DescriptionViewer } from '../CaseRecords';



export const ExpertCommentsList = () => {
  const data = useExpertCommentsList();
  const history = useHistory();
  const schema = useExpertCommentSchema();


  const fields = useFields({
    defaultFields: [
      "title",
      "comment_date",
      "created_datetime",
      "is_published",
    ],
    blacklist: ["content"],
    schema,
    storageKey: "_locus_expert_comments_fields",
    extraSettings: {
      description: { tableControl: DescriptionViewer },
    }
  });

  return (<>
    <SimpleList
      data={{
        ...data,
        filter: data.filter.filter,
        setFilter: data.filter.setFilter,
        create: () => { data.newRecord.startEditing(); return Promise.resolve({ _id: -1 } as ExpertComment); }
      }}
      fields={[
        ["title"],
        ["comment_date"],
        ["created_datetime"],
      ]}
      tabs={[
        ["Черновики", "unpublished"],
        ["Опубликованные", "public"],
        ["Все", "all"],
      ]}
      schema={schema}
      getLinkValue={cr => `/expert-comment/${cr._id}`}
      linkFields={[ "_id", "title" ]}
      title="Экспертные мнения"
      searchAuto
      searchAutoFocus
      fieldSetings={fields}
      sorting={data.sorting}
      tableProps={{
        tailAnchor: data.limitTrigger.pagingAnchor,
      }}
      />

    <CreateExpertCommentDialog
      {...data.newRecord}
      save={() => data.newRecord.save().then(cr => { history.push(`/expert-comment/${cr._id}`); return cr; })}
      schema={schema}
      />
  </>);
}
