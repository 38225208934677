import React, { useEffect } from 'react';
import { SimpleList } from '../../toolympus/components/primitives';
import { useHistory } from 'react-router-dom';
import { useFields } from '../../toolympus/components/schemed';
import { CountryPractice, useCountryPracticeList, useCountryPracticeSchema } from './useCountryPracticeList';
import { CreateCountryPracticeDialog } from './CreateCountryPracticeDialog';
import { DescriptionViewer, DescriptionWrapper } from '../CaseRecords';


const ViewLSKey = "__locus_icplist_view";


export const CountryPracticeList = () => {
  const data = useCountryPracticeList();
  const history = useHistory();
  const schema = useCountryPracticeSchema();

  useEffect(() => {
    const view = localStorage.getItem(ViewLSKey);
    if(view) {
      data.setView(view);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    localStorage.setItem(ViewLSKey, data.view);
  }, [data.view]);

  const fields = useFields({
    defaultFields: [
      "title",
      "slug",
      "created_datetime",
      "is_published",
    ],
    blacklist: ["content"],
    schema,
    storageKey: "_locus_expert_comments_fields",
    extraSettings: {
      description: { tableControl: DescriptionViewer },
    },
  });

  return (<>
    <SimpleList
      data={{
        ...data,
        filter: data.filter.filter,
        setFilter: data.filter.setFilter,
        create: () => { data.newRecord.startEditing(); return Promise.resolve({ _id: -1 } as CountryPractice); }
      }}
      fields={[
        ["title"],
        ["slug"],
        ["created_datetime"],
      ]}
      tabs={[
        ["Черновики", "unpublished"],
        ["Опубликованные", "public"],
        ["Все", "all"],
      ]}
      schema={schema}
      getLinkValue={cr => `/country-practice/${cr._id}`}
      linkFields={[ "_id", "title", "slug" ]}
      title="Иностранная практика"
      searchAuto
      searchAutoFocus
      fieldSetings={fields}
      sorting={data.sorting}
      fieldElement={f => {
        switch(f) {
          case "authors":
            return (row) => <DescriptionWrapper>{(row.authors || []).map(a => a.title).join("\n")}</DescriptionWrapper>
          case "partners":
            return (row) => <DescriptionWrapper>{(row.partners || []).map(p => p.title).join("\n")}</DescriptionWrapper>
        }
      }}
      />

    <CreateCountryPracticeDialog
      {...data.newRecord}
      save={() => data.newRecord.save().then(cr => { history.push(`/country-practice/${cr._id}`); return cr; })}
      schema={schema}
      />
  </>);
}
