import React from 'react';
import { FormGrid, SimpleDialog } from '../../../toolympus/components/primitives';
import { FormControlsForFields } from '../../../toolympus/components/schemed';
import { Topic, TopicSchema } from './useManageTopics';

interface Props {
  isOpen: boolean;
  close: () => void;
  item: Omit<Topic, "_id"> | null;
  update: (changes: Partial<Topic>) => void;
  save: () => Promise<Topic>;
  isNew?: boolean;
}

export const TopicEditDialog = (props: Props) => {
  const { isOpen, close, item, update, save } = props;
  return (
    <SimpleDialog
      dialogTitle="Тема"
      close={close}
      isOpen={isOpen}
      save={save}
      noFullscreen>

        <FormGrid columns="2fr 1fr" style={{ alignItems: "end" }}>
          {item && <FormControlsForFields
            data={item}
            onChange={(o,c) => update(c)}
            schema={TopicSchema}
            fields={[
              ["code", { disabled: !props.isNew }],
              ["parent_code", { disabled: true }],
              ["title"],
              ["sort_order"],
            ]}
            />}

          {item && <FormControlsForFields
            data={item.translations?.en || {}}
            onChange={(o,c) => update({ translations: { en: { ...(o.translations?.en || {}), ...c } }})}
            schema={TopicSchema}
            fields={[
              ["title", { label: "Название (en)" }],
            ]}
            />}

          {item && <FormControlsForFields
            data={item}
            onChange={(o,c) => update(c)}
            schema={TopicSchema}
            fields={[
              ["is_hidden"],
            ]}
            />}

          
        </FormGrid>

        <FormGrid columns="1fr" noMargin>
          {item && <FormControlsForFields
            data={item}
            onChange={(o,c) => update(c)}
            schema={TopicSchema}
            fields={[
              ["description"],
            ]}
            />}

          {item && <FormControlsForFields
            data={item.translations?.en || {}}
            onChange={(o,c) => update({ translations: { en: { ...(o.translations?.en || {}), ...c } }})}
            schema={TopicSchema}
            fields={[
              ["description", { label: "Описание (en)" }],
            ]}
            />}

        </FormGrid>
      
    </SimpleDialog >
  );
}
