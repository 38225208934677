import { useEditItem2 } from "../../../toolympus/api/useNewItem";
import { FileInfo, useFileList } from "../../../toolympus/components/files";
import { FieldType, Schema, mergeSchema } from "../../../toolympus/hooks/useSchema";
import { CaseRecordDocument } from "../types";
import { CaseRecordApiPath } from "../useCaseRecordsList"

const fileInfoToDocument = (fi: FileInfo): CaseRecordDocument & { fileinfo: FileInfo } => ({
  _id: fi._id,
  filename: fi.filename,
  case_record_id: +(fi.meta?.case_record_id || "-1"),
  title: fi.meta?.title || "",
  title_en: fi.meta?.title_en || "",
  comment: fi.meta?.comment || "",
  comment_en: fi.meta?.comment_en || "",
  external_url: fi.meta?.external_url,
  fileinfo: fi,
})

const DocumentSchema: Schema = {
  title: { label: "Название" },
  title_en: { label: "Название (en)" },
  comment: { label: "Комментарий", type: FieldType.textlong },
  comment_en: { label: "Комментарий (en)", type: FieldType.textlong },
  external_url: { label: "Ссылка" },
}

export const useCaseRecordDocuments = (caseRecordId: number) => {
  const filelist = useFileList(`${CaseRecordApiPath}/${caseRecordId}/document`, undefined, {
    noLoad: caseRecordId < 0,
  });

  const editItem = useEditItem2<CaseRecordDocument>({
    getApiPath: doc => `${CaseRecordApiPath}/${caseRecordId}/document/${doc._id}/info`,
  });

  return {
    ...filelist,
    schema: mergeSchema(filelist.schema, DocumentSchema),
    data: filelist.data.map(fileInfoToDocument),
    editItem: {
      ...editItem,
      save: () => editItem.save().then(x => { filelist.reload(); return x; }),
    },
  }
}
