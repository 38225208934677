import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { useSingleSchema } from "../../toolympus/hooks/useSchema";
import { useBrowserStoredValue } from "../../toolympus/hooks/useBrowserStoredValue";
import { useState } from "react";
import { useSmartList } from "../../toolympus/hooks/useSmartList";

export interface CaseRecord {
  _id: number;
  number: string;
  created_datetime: string;
  is_published?: boolean;

  description?: any;
  content?: any;

  institute_id?: string;
  ruling_date?: string;
  case_type?: string;
  rules?: string | null;
  adhoc_jury?: string | null;
  court_id?: string;
  topics?: string[] | null;
  place_country?: string;

  review_status?: "considered-done" | "confirmed-done" | "needs-fixing" | null;
  review_status_datetime?: string;

  is_ping_owner_enabled?: boolean;

  translations?: any;
}

export type NewCaseRecord = Partial<CaseRecord>;

export const CaseRecordApiPath = "/api/case-record"

export const useCaseRecordSchema = () => {
  const { schema } = useSingleSchema(`${CaseRecordApiPath}/uiconfig`);
  return schema;
}

const useCaseRecordDuplicatesCheck = () => {
  const [numberIn, setNumberIn] = useState<string>("");

  const duplicates = useLoadedData<CaseRecord[]>(`${CaseRecordApiPath}?searchx=${numberIn}`, [], !!numberIn && numberIn.length > 2);

  return {
    requestCheck: (number: string) => setNumberIn((number || "").replace(/^[аАaA]/, "")),
    ...duplicates,
  }
}

const caseRecordToText = (cr: CaseRecord) => `${cr.number} ${cr._id}`;

export const useCaseRecordsList = () => {
  const { value: myOnly, update: setMyOnly } = useBrowserStoredValue("f", "my", "__locus_crlist_my");

  const schema = useCaseRecordSchema();

  const data = useSmartList<CaseRecord>(CaseRecordApiPath, {
    sorting: {
      disallowedFields: ["description"],
    },
    viewDefault: "unpublished",
    lsKeysPrefix: "__locus_crlist",
    extraParams: { my_only: myOnly },
    schema,
    newRecordDefault: { number: "" },
    textFilterFn: caseRecordToText,
  })


  
  const duplicatesCheck = useCaseRecordDuplicatesCheck();

  return {
    ...data,
    myOnly,
    setMyOnly,

    duplicatesCheck,
  }
}

export type CaseRecordListData = ReturnType<typeof useCaseRecordsList>;
