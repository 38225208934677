import React from 'react';
import { ExpertApplication, useExpertsApplicationsList } from './useExpertsApplicationsList';
import { Buttons, Dialog, FormGrid, PseudoLink2, SimpleList } from '../../toolympus/components/primitives';
import { ProfileSchema } from '../Administration/Users';
import { Button, DialogActions, IconButton, InputAdornment, TextField } from '@mui/material';
import { CallMade, GetAppOutlined } from '@mui/icons-material';
import { FieldType, mergeSchema } from '../../toolympus/hooks/useSchema';
import { downloadFile } from '../../toolympus/api/core';
import { EditItemProps } from '../../toolympus/api/useNewItem';
import { FieldDefinition, FormControlsForFields } from '../../toolympus/components/schemed';
import { FormattedMessage } from 'react-intl';
import { Status } from '../../toolympus/components/StatusAction';
import { TopicsSelector } from '../Administration/Topics';
import { ActionTriggerEditButton } from '../../toolympus/components/Actions';

const ApprovalStatuses = [{ value: "new", label: "На рассмотрении"}, { value: "approved", label: "Одобрена"}, { value: "rejected", label: "Отказ"}];

const ExpertApplicationSchema = mergeSchema(ProfileSchema, {
  created_datetime: { type: FieldType.datetime, label: "Создана" },
  cv_file_id: { label: "CV" },
  education: { label: "Образование" },
  comment: { label: "Комментарии", type: FieldType.textlong },
  topics: { label: "Темы" },
  approval_status: { label: "Статус", type: FieldType.select, values: ApprovalStatuses, valueDict: ApprovalStatuses.reduce<Record<string,string>>((r,v) => { r[v.value] = v.label; return r; }, {})}
});


const ExpertApplicationsActionTriggers = [
  "locus.logic.experts.applications/application-submitted",
]

export const ExpertsApplicationsList = () => {
  const data = useExpertsApplicationsList();
  
  return (<>
    <SimpleList
      data={{
        ...data,
        filter: data.filter.filter,
        setFilter: data.filter.setFilter,
        create: () => Promise.resolve({} as ExpertApplication),
      }}
      tabs={[
        ["Все", "all"],
        ["На рассмотрении", "new"],
        ["Отказ", "rejected"],
        ["Одобренные", "approved"],
      ]}
      fields={[
        ["email"],
        ["lastname"],
        ["firstname"],
        ["middlename"],
        ["phone"],
        ["company"],
        ["position"],
        ["cv_file_id"],
        ["approval_status"],
        ["created_datetime", { utcToLocal: true }],
      ]}
      linkFields={[]}
      getLinkValue={() => ""}
      schema={ExpertApplicationSchema}
      title="Заявки экспертов"
      searchAuto
      searchAutoFocus
      noCreate
      headerActions={<>
        <ActionTriggerEditButton
          triggers={ExpertApplicationsActionTriggers}
          apiPath="/api/actions/trigger"
          />
        </>}
      fieldElement={f => {
        switch(f) {
          case "lastname":
            return (row,s,orig) => <PseudoLink2 border onClick={() => data.editItem.startEditing(row)}>{orig}</PseudoLink2>
          case "email":
            return (row,s,orig) => row.user_id
              ? (
                <Buttons>
                  <PseudoLink2 border onClick={() => data.editItem.startEditing(row)}>
                    {orig}
                  </PseudoLink2>
                  <IconButton size="small" href={`/system/users?filter=${row.user_id}`}><CallMade /></IconButton>
                </Buttons>
              )
              : <>{orig}</>
          case "cv_file_id":
            return (row,s,orig) => <IconButton size="small" color="primary" onClick={() => downloadFile(`/api/expert/application/file/${row.cv_file_id}`)}><GetAppOutlined /></IconButton>
          case "approval_status":
            return (row) => <ApprovalStatus status={row.approval_status} />
        }
      }}
      />

      <ExpertApplicationEditDialog data={data.editItem} />
  </>);
}

const ApprovalStatus = ({ status }: { status?: string }) => (
  <Status
    status={status || ""}
    labels={ExpertApplicationSchema.approval_status.valueDict}
    hints={{
      approved: { color: "#008800", },
      rejected: { color: "#cc0000", },
    }}
    />
);

const ExpertApplicationEditDialog = ({ data }: { data: EditItemProps<ExpertApplication> & { approve: () => void, reject: () => void, cancelReject: () => void } }) => {
  const controls = (fields: FieldDefinition[]) => (
    <FormControlsForFields
      data={data.item}
      onChange={data.item?.user_id ? (o,c) => {} : (o,c) => data.update(c)}
      schema={ExpertApplicationSchema}
      fields={fields}
      />);

  return (
    <Dialog
      isOpen={data.isEditing}
      close={() => data.cancel()}
      maxWidth="md"
      fullWidth
      noFullscreen
      dialogTitle="Заявка"
      titleActions={<>
        <ApprovalStatus status={data.item?.approval_status} />
        {data.item?.approval_status === "new" && <Button size="small" color="primary" onClick={() => data.approve()}>Утвердить</Button>}
        {data.item?.approval_status === "new" && <Button size="small" color="primary" onClick={() => data.reject()}>Отказ</Button>}
        {data.item?.approval_status === "rejected" && <Button size="small" color="primary" onClick={() => data.cancelReject()}>Отменить отказ</Button>}
      </>}>

      {data.item && <FormGrid columns="1fr 1fr" style={{ alignItems: "end" }}>
        {controls([
          ["email", {
            controlProps: {
              endAdornment: data.item.user_id ? <InputAdornment position="end"><IconButton href={`/system/users?filter=${data.item.user_id}`} size="small"><CallMade /></IconButton></InputAdornment> : null,
            }
          }],
          ["phone"],
          ["created_datetime", { utcToLocal: true, readOnly: true }]
        ])}

        <Button size="small" color="primary" endIcon={<GetAppOutlined />} onClick={() => downloadFile(`/api/expert/application/file/${data.item?.cv_file_id}`)}>скачать CV</Button>
      </FormGrid>}

      {data.item && <FormGrid columns="1fr">
        {controls([
          ["lastname"],
          ["firstname"],
          ["middlename"],
          ["company"],
          ["position"],
        ])}

        <TopicsSelector
          label="Темы"
          value={data.item.topics || null}
          update={topics => data.update({ topics })}
          error={data.errors?.fieldHasErrors("topics")}
          />

        <TextField
          label="Образование"
          multiline
          value={(data.item.education || []).join("\n")}
          onChange={e => data.update({ education: (e.target.value || "").split("\n")})}
          />

        {controls([
          ["comment"],
        ])}
      </FormGrid>}
      
      <DialogActions>
        <Button onClick={() => data.cancel() }><FormattedMessage id="common.cancel" /></Button>
        <Button color="primary" variant="contained" onClick={() => data.save()}><FormattedMessage id="common.save" /></Button>
      </DialogActions>
    </Dialog>
  );
}
