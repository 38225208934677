import { useIntl } from "react-intl";
import { apiFetch } from "../../../toolympus/api/core";
import { useItemActionWithConfirmation } from "../../../toolympus/api/useAction";
import { useEditItem2, useNewItem } from "../../../toolympus/api/useNewItem";
import { useLocalizedList } from "../../../toolympus/components/LocalizedRecords";
import { useTextFilter } from "../../../toolympus/components/schemed/Filtering/useTextFilter";
import { useLoadedData } from "../../../toolympus/hooks/useLoadedData";
import { FieldType, Schema } from "../../../toolympus/hooks/useSchema";
import { DefaultLanguage } from "../../Common/Localization";

export interface Court {
  _id: number;
  title: string;
  title_short: string;
  title_middle: string;
  stage?: string | null;
  sort_order: number;
  translations?: any;
}

export const CourtSchema: Schema = {
  _id: { label: "ID", type: FieldType.number },
  sort_order: { label: "Порядок", type: FieldType.number },
  title: { label: "Название" },
  title_middle: { label: "Сокращенное название" },
  title_short: { label: "Краткое название" },
  stage: { label: "Инстанция", dictionary: "Stages", type: FieldType.dictionarySelect },
}

const TitleReplacements = [
  ["Арбитражный Суд", "АС"],
  ["Arbitration Court", "AC"],
]

export const generateTitleMiddle = (title?: string) => {
  return title
    ? TitleReplacements.reduce<string>((r,[full,short]) => r.replace(full,short), title)
    : "";
}

const ApiPath = "/api/settings/court";

export const useManageCourts = () => {
  const data = useLoadedData<Court[]>(ApiPath, []);
  const filter = useTextFilter<Court>(c => `${c.title} ${c.title_short} ${c.title_middle} ${c._id}`);
  const localized = useLocalizedList(data.data, {
    defaultLocale: DefaultLanguage,
    translatedFields: ["title", "title_short", "title_middle"],
    translationsField: "translations",
    updateItem: () => {},
  });

  const newCourt = useNewItem<Omit<Court, "_id">, Court>(
    ApiPath,
    {
      title: "",
      title_short: "",
      title_middle: "",
      sort_order: Math.max(...data.data.map(c => c.sort_order), 0) + 1,
      translations: { en: { title: "", title_short: "", title_middle: "" }},
    });

  const editCourt = useEditItem2<Court>({
    getApiPath: c => `${ApiPath}/${c._id}`,
  });

  const { formatMessage } = useIntl();

  const removeCourt = useItemActionWithConfirmation<Court, {}>(
    item => apiFetch<Court>(`${ApiPath}/${item?._id}`, "delete")
      .then(x => {
        data.reload();
        return x;
    }),
    {
      title: formatMessage({ id: "dictionaries.remove_title" }),
      confirmationHint: formatMessage({ id: "dictionaries.remove_hint" }),
    }
  );

  return {
    ...data,
    data: filter.filterData(localized.items),
    filter,
    schema: CourtSchema,

    newCourt: {
      ...newCourt,
      save: () => newCourt.save().then(c => { data.reload(); return c; }),
    },
    editCourt: {
      ...editCourt,
      save: () => editCourt.save().then(c => { data.reload(); return c; }),
    },
    removeCourt,

    locale: localized.locale,
    setLocale: localized.setLocale,
  }
}
