import { useState } from "react";
import { useEditItem2 } from "../../../toolympus/api/useNewItem";
import { useTextFilter } from "../../../toolympus/components/schemed/Filtering/useTextFilter";
import { useLoadedData } from "../../../toolympus/hooks/useLoadedData";
import { apiFetch } from "../../../toolympus/api/core";
import { useLocalizedList } from "../../../toolympus/components/LocalizedRecords";
import { DefaultLanguage } from "../../Common/Localization";

export interface Profile {
  _id?: string;
  email?: string;
  phone?: string;
  lastname?: string;
  middlename?: string;
  firstname?: string;
  position?: string;
  company?: string;
  is_expert?: boolean;
  translations?: any;
}
  
export const ProfileApiPath = "/api/profile/admin";
export const CreateExpertProfileApiPath = "/api/profile/expert";

export const useUserProfiles = () => {
  const [view, setView] = useState<string>("all");
  const data = useLoadedData<Profile[]>(`${ProfileApiPath}?view=${view}`, []);

  const localized = useLocalizedList(data.data, {
    defaultLocale: DefaultLanguage,
    translatedFields: ["lastname", "firstname", "middlename", "company", "position"],
    translationsField: "translations",
    updateItem: (o,c) => {},
  });

  const editItem = useEditItem2<Profile>({
    getApiPath: p => `${ProfileApiPath}/${p._id}`,
  });

  const createExpert = useEditItem2<Profile>({
    getApiPath: p => `${ProfileApiPath}/${p._id}`,
    save: (profile) => apiFetch<Profile>(CreateExpertProfileApiPath, "post", profile),
  });

  const filter = useTextFilter<Profile>(p => `${p.email} ${p.lastname} ${p.firstname} ${p.company}`);

  return {
    ...data,
    data: filter.filterData(localized.items),
    editItem: {
      ...editItem,
      save: () => editItem.save().then(x => { data.reload(); return x; }),
    },
    createExpert: {
      ...createExpert,
      save: () => createExpert.save().then(x => { data.reload(); return x; }),
    },
    filter,
    view,
    setView,

    locale: localized.locale,
    setLocale: localized.setLocale,
  }
}
