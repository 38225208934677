import React from 'react';
import { CaseRecord, CaseRecordListData, NewCaseRecord } from './useCaseRecordsList';
import { Buttons, EmptyState, FormGrid, Link, LoadingIndicator, SimpleDialog } from '../../toolympus/components/primitives';
import { Schema } from '../../toolympus/hooks/useSchema';
import { FormControlsForFields } from '../../toolympus/components/schemed';
import { NewItemProps } from '../../toolympus/api/useNewItem';
import { Typography } from '@mui/material';

interface Props extends NewItemProps<NewCaseRecord, CaseRecord> {
  schema: Schema;
  duplicatesCheck: CaseRecordListData["duplicatesCheck"];
}

export const CreateCaseRecordDialog = (props: Props) => {
  const { isEditing, cancel, errors, item, update, save, schema } = props;
  return (
    <SimpleDialog
      isOpen={isEditing}
      close={cancel}
      dialogTitle="Новое решение"
      submitOnModEnter
      isSaving={props.isLoading}
      save={() => save()}>
      {item && <FormGrid columns="1fr">
        <FormControlsForFields
          fields={[
            ["number", { controlProps: { autoFocus: true, onBlur: () => props.duplicatesCheck.requestCheck(item.number || "") }}],
            ["case_type", { autoComplete: true }],
            ["rules", { autoComplete: true }],
            ["stage", { autoComplete: true }],
            ["court_id", { autoComplete: true }],
            ["place_country", { autoComplete: true }],
          ]}
          data={item}
          onChange={(o,c) => update(c)}
          errors={errors}
          schema={schema}
          />
        
        {props.duplicatesCheck.isLoading && <LoadingIndicator />}
        {!!props.duplicatesCheck.data.length && 
          <EmptyState
            text={<>
              <Typography>
                Найдены похожие дела. Пожалуйста убедитесь, что вы не создаете дубликат:
              </Typography>
              <Buttons>{props.duplicatesCheck.data.map(d => <Link to={`/case-record/${d._id}`} target="_blank">{d.number}</Link>)}</Buttons>
            </>}
            />}
      </FormGrid>}
    </SimpleDialog>
  );
}
