import { useState } from "react";
import { apiFetch } from "../../../toolympus/api/core";
import { useLoadedData } from "../../../toolympus/hooks/useLoadedData"
import { CaseRecordApiPath } from "../useCaseRecordsList"
import { useDialogState } from "../../../toolympus/components/primitives";
import { useItemActionWithConfirmation } from "../../../toolympus/api/useAction";
import { ExpertComment, ExpertCommentApiPath } from "../../ExpertComments/useExpertCommentsList";

export const useCaseRecordLinkedComments = (caseRecordId: number) => {
  const data = useLoadedData<ExpertComment[]>(`${CaseRecordApiPath}/${caseRecordId}/expert-comment`, [], caseRecordId >= 0);

  const newItem = useDialogState();

  const addComment = (otherRecordId: number) => {
    return apiFetch<{}>(`${CaseRecordApiPath}/${caseRecordId}/expert-comment/${otherRecordId}`, "post")
      .then(() => { data.reload(); newItem.close(); });
  }

  const removeComment = useItemActionWithConfirmation<ExpertComment,{}>(
    item => apiFetch<{}>(`${CaseRecordApiPath}/${caseRecordId}/expert-comment/${item._id}`, "delete").then(x => { data.reload(); return x; }),
    { 
      title: "Удалить мнение",
    });

  const [search, setSearch] = useState<string>("");

  const allComments = useLoadedData<ExpertComment[]>(`${ExpertCommentApiPath}`, [], newItem.isOpen);

  const allFiltered = (search
      ? allComments.data.filter(c => c.title.toLowerCase().includes(search.toLowerCase()))
      : allComments.data)
      .filter(r => !data.data.find(included => included._id === r._id));

  return {
    ...data,
    newItem: {
      ...newItem,
      save: addComment,
    },
    allComments: {
      ...allComments,
      data: allFiltered,
    },
    removeComment,
    
    search,
    setSearch,
  }
}
