import { useState } from "react";
import { Profile } from "../../Administration/Users/useUserProfiles";
import { useLoadedData } from "../../../toolympus/hooks/useLoadedData";
import { useEditItem2 } from "../../../toolympus/api/useNewItem";
import { useTextFilter } from "../../../toolympus/components/schemed/Filtering/useTextFilter";

export interface CaseSuggestion {
  _id: number;
  created_datetime: string;
  is_processed: boolean;

  author_user_id?: string | null;
  author?: Profile | null;

  number?: string;
  external_url?: string;
  ruling_date?: string;
  jurisdiction?: string;
  case_type?: string;
  stage?: string;
  court?: string;

  file_id?: string;
  short_description?: string;
}

// const applicationToExpert = (a: CaseSuggestion): Profile => ({
//   email: a.email,
//   phone: a.phone,
//   is_expert: a.is_expert,
  
//   firstname: a.firstname,
//   lastname: a.lastname,
//   middlename: a.middlename,
  
//   company: a.company,
//   position: a.position,
// });

export const CaseSuggestionApiPath = "/api/case_record/suggestion";

export const useCaseSuggestionsList = () => {
  const [view, setView] = useState<string>("all");
  const data = useLoadedData<CaseSuggestion[]>(`${CaseSuggestionApiPath}?view=${view}`, []);

  const editItem = useEditItem2<CaseSuggestion>({
    getApiPath: e => `${CaseSuggestionApiPath}/${e._id}`,
  });

  const filter = useTextFilter<CaseSuggestion>(p => `${p.number} ${p.short_description}`);

  return {
    ...data,
    data: filter.filterData(data.data),
    filter,
    view,
    setView,

    editItem: {
      ...editItem,
      save: () => editItem.save().then(x => { data.reload(); return x; }),
    }
  }
}
