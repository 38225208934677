import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useBrowserStoredValue } from "./useBrowserStoredValue";
import { SortingConfig, useFieldSorting } from "./useFieldSorting";
import { generateRequestId, useLoadedData } from "./useLoadedData";
import { useVisibilityLimitTrigger } from "../components/primitives/useVisibilityLimitTrigger";
import { apiFetch } from "../api/core";
import { useVisibilityTrigger } from "../components/primitives";

interface LoadedListConfig {
  viewDefault?: string;
  viewLSKey?: string;

  noView?: boolean;
  noLimit?: boolean;
  extraParams?: Record<string, any>;
  sorting?: SortingConfig;
}

export const useLoadedList = <T,>(apiPath: string, cfg?: LoadedListConfig) => {
  const { value: view, update: setView } = useBrowserStoredValue(cfg?.viewDefault || "", "view", cfg?.viewLSKey);
  const [limit,setLimit] = useState<number>(30);

  const sorting = useFieldSorting(cfg?.sorting);

  const extraParamsS = JSON.stringify(cfg?.extraParams || {});

  const [query, queryParams] = useMemo(() => {
    let p: Record<string, any> = {};
    if(!cfg?.noView && view) {
      p.view = view;
    }
    if(!cfg?.noLimit && limit) {
      p.limit = limit;
    }
    if(cfg?.extraParams && Object.keys(cfg?.extraParams).length) {
      p = {
        ...p,
        ...cfg?.extraParams,
      }
    }
    const paramsWithVals = Object.entries(p).map(([k,v]) => `${k}=${v}`);
    if(!!cfg?.sorting && sorting.queryParameter) {
      paramsWithVals.push(sorting.queryParameter);
    }

    return [paramsWithVals.join("&"), paramsWithVals];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view, cfg?.noView, sorting.queryParameter, limit, cfg?.noLimit, extraParamsS])
  
  const data = useLoadedData<T[]>(`${apiPath}?${query}`, []);

  const limitTrigger = useVisibilityLimitTrigger({ limit: limit, update: setLimit, currentDataLength: data.data.length });

  return {
    view,
    setView,
    limit,
    setLimit,
    sorting,
    limitTrigger,
    queryParams,

    ...data,
  }
}

export const useChunkedLoadedList = <T,>(apiPath: string, cfg?: LoadedListConfig) => {
  const { value: view, update: setView } = useBrowserStoredValue(cfg?.viewDefault || "", "view", cfg?.viewLSKey);
  const offsetStep = 30;

  const sorting = useFieldSorting(cfg?.sorting);

  const extraParamsS = JSON.stringify(cfg?.extraParams || {});

  
  const requestId = useRef<number>(0);

  const [queryNoLimit, queryParams] = useMemo(() => {
    let p: Record<string, any> = {};
    if(!cfg?.noView && view) {
      p.view = view;
    }
    if(cfg?.extraParams && Object.keys(cfg?.extraParams).length) {
      p = {
        ...p,
        ...cfg?.extraParams,
      }
    }
    const paramsWithVals = Object.keys(p).sort().map(k => `${k}=${p[k]}`);
    if(!!cfg?.sorting && sorting.queryParameter) {
      paramsWithVals.push(sorting.queryParameter);
    }

    return [
      paramsWithVals.join("&"),
      paramsWithVals,
    ];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view, cfg?.noView, sorting.queryParameter, extraParamsS]);
    
  

  const [params, setParams] = useState<{
    apiPath: string,
    limit: number,
    offset: number,
    query: string,
    requestId: number,
  } | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [records,setRecords] = useState<T[]>([]);

  const reload = useCallback(() => {
    setRecords([]);
    const paramsRequestId = generateRequestId();
    requestId.current = paramsRequestId;
    setParams({
      apiPath: apiPath,
      limit: offsetStep,
      offset: 0,
      query: queryNoLimit,
      requestId: paramsRequestId,
    });
  }, [apiPath, queryNoLimit]);

  useEffect(() => {
    reload();
  }, [reload]);


  useEffect(() => {
    if(!params) {
      return;
    }
    const paramsRequestId = params.requestId;

    const q = cfg?.noLimit
    ? params.query
    : [params.query,`limit=${params.limit}`, `offset=${params.offset}`].filter(x => !!x).join("&");

    const pathWithQuery = `${params.apiPath}?${q}`;

    setIsLoading(true);
    apiFetch<T[]>(pathWithQuery, "get", null)
      .then(data => {
        if(requestId.current === paramsRequestId) {
          setRecords(x => [...x, ...data]);
        }
      })
      .then(() => setIsLoading(false))
      .catch(e => {
        setIsLoading(false);
        throw e;
      });
  }, [params, cfg?.noLimit]);

  const { anchor: pagingAnchor } = useVisibilityTrigger({
    onBecameVisible: () => setParams(x => x ? ({ ...x, offset: x.offset + offsetStep}) : x),
    isDisabled: !params || (records.length < params.offset + offsetStep),
  });

  return {
    view,
    setView,
    sorting,
    limitTrigger: { pagingAnchor },
    queryParams,
    queryNoLimit,

    data: records,
    isLoading,
    setData: setRecords,
    reload,
  }
}
