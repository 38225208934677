import React from 'react';
import { FormGrid, SimpleDialog, SlugEdit } from '../../toolympus/components/primitives';
import { Schema } from '../../toolympus/hooks/useSchema';
import { FormControlsForFields } from '../../toolympus/components/schemed';
import { NewItemProps } from '../../toolympus/api/useNewItem';
import { CountryPractice, NewCountryPractice } from './useCountryPracticeList';

interface Props extends NewItemProps<NewCountryPractice, CountryPractice> {
  schema: Schema;
}

export const CreateCountryPracticeDialog = (props: Props) => {
  const { isEditing, cancel, errors, item, update, save, schema } = props;
  return (
    <SimpleDialog
      isOpen={isEditing}
      close={cancel}
      dialogTitle="Новая запись иностранной практики"
      save={() => save()}>
      {item && <FormGrid columns="1fr">
        <FormControlsForFields
          fields={[
            ["title"],
          ]}
          data={item}
          onChange={(o,c) => update(c)}
          errors={errors}
          schema={schema}
          />
        <SlugEdit
          field="slug"
          row={item}
          schema={schema}
          onChange={(o,c) => update(c)}
          sourceField="title"
          errors={errors}
          />
      </FormGrid>}
    </SimpleDialog>
  );
}
