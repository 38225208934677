import moment from "moment";
import { apiFetch } from "../../../toolympus/api/core";
import { useEditItem2 } from "../../../toolympus/api/useNewItem";
import { useLoadedData } from "../../../toolympus/hooks/useLoadedData";
import { useSingleSchema } from "../../../toolympus/hooks/useSchema";
import { DateInternalFormat } from "../../../toolympus/api/datetimeUtil";
import { useEffect } from "react";
import { useThrottledState } from "../../../toolympus/components/primitives";
import { importXlsxRows } from "../../../toolympus/api/xlsx";

export interface KadVerifierRecord {
  _id: number;
  kad_number: string;
  kad_url: string;
  kad_date?: string | null;
  kad_finished_in_first_date?: string | null;
  is_hidden?: boolean;
  category?: string;
  owner_id?: string;
  comment?: string;
  case_record_id?: number;
}

export interface KadMassAssignParams extends Partial<KadVerifierRecord> {
  include_owned?: boolean;
  include_hidden?: boolean;
  include_with_cases?: boolean;
  date_from?: string | null;
  date_to?: string | null;
  finished_first_from?: string | null;
  finished_first_to?: string | null;
  ids?: number[];
  is_tried_loading?: boolean;
  is_done?: boolean;
  new_owner_id?: string | null;
}

const processDate = (text: string | undefined) => {
  const result = text 
    ? moment(text, "DD.MM.YYYY", undefined).format(DateInternalFormat)
    : null;
  
    return result?.length === 10 ? result : null;
}

const readKadVerifierRecordsFromXlsx = (file: File): Promise<Partial<KadVerifierRecord>[]> => {
  return importXlsxRows<Partial<KadVerifierRecord>>(file, row => ({
    kad_number: row.getCell(1)?.text,
    kad_date: processDate(row.getCell(2)?.text),
    kad_finished_in_first_date: processDate(row.getCell(3)?.text),
    kad_url: row.getCell(4)?.text,
  }), {
    ignoreFirstRow: true,
  })
}

export const KadApiPath = "/api/kad";

export const useKadVerifier = () => {
  const filters = useEditItem2<{ limit?: number, hidden?: string, owned?: string, with_cases: string, search?: string }>({
    startWith: { limit: 30, hidden: "exclude", owned: "include", with_cases: "include", search: "" },
  });

  const [queryParams,setQueryParams] = useThrottledState("", 500);
  useEffect(() => {
    setQueryParams(Object.entries(filters.item || {}).map(([k,v]) => `${k}=${v}`).join("&"));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.item]);

  
  const data = useLoadedData<KadVerifierRecord[]>(`${KadApiPath}?${queryParams}`, []);
  const { schema } = useSingleSchema(`${KadApiPath}/uiconfig`);

  const recordsImport = useEditItem2<Pick<KadVerifierRecord, "category"> & { items: Partial<KadVerifierRecord>[] }>({
    save: item => {
      if(!item.items.length) {
        return Promise.resolve(item);
      } else {
        const itemsWithCategory = item.category
          ? item.items.map(i => ({ ...i, category: item.category }))
          : item.items;
        return apiFetch<{}>(`${KadApiPath}/import`, "post", { items: itemsWithCategory })
          .then(() => Promise.resolve(item));
      }
    }
  });

  const editItem = useEditItem2<KadVerifierRecord>({
    getApiPath: r => `${KadApiPath}/${r._id}`,
  });

  const massAssign = useEditItem2<KadMassAssignParams>({
    save: (item) => {
      if(!item.ids?.length) {
        const filters: any = {};
        if(item.date_from) {
          filters.date_from = item.date_from;
        }
        if(item.date_to) {
          filters.date_to = item.date_to;
        }
        if(item.finished_first_from) {
          filters.finished_first_from = item.finished_first_from;
        }
        if(item.finished_first_to) {
          filters.finished_first_to = item.finished_first_to;
        }
        if(item.category) {
          filters.category = item.category;
        }
        if(item.owner_id) {
          filters.owner_id = item.owner_id;
        }
        if(!item.include_owned) {
          filters.owned = "exclude";
        }
        if(item.include_hidden) {
          filters.hidden = "include";
        }
        if(item.include_with_cases) {
          filters.with_cases = "include";
        }
        return apiFetch<KadVerifierRecord[]>(`${KadApiPath}?${Object.entries(filters).map(([k,v]) => `${k}=${v}`).join("&")}`)
          .then(records => {
            return { ...item, is_tried_loading: true, ids: records.map(r => r._id) };
          })
      } else {
        return apiFetch<{}>(`${KadApiPath}/mass-update`, "post", { ids: item.ids, owner_id: item.new_owner_id })
          .then(() => {
            return { ...item, is_done: true };
          });
      }
    },
    dontResetOnSave: true,
  });

  return {
    ...data,
    schema,
    filters,
    editItem: {
      ...editItem,
      save: (changes?: Partial<KadVerifierRecord>) => {
        return editItem.save(changes)
          .then(x => {
            data.reload();
            return x;
          })
      }
    },
    recordsImport: {
      ...recordsImport,
      uploadFile: (f: File) => {
        return readKadVerifierRecordsFromXlsx(f)
          .then(records => {
            recordsImport.update({ items: records });
          });
      }
    },
    massAssign: {
      ...massAssign,
      save: (extraChanges?: Partial<KadMassAssignParams>) => {
        return massAssign.save(extraChanges)
          .then(x => {
            if(x.is_done) {
              data.reload();
              massAssign.cancel();
              return x;
            } else {
              massAssign.setItem(x);
              return x;
            }
          });
      }
    },
  }
}
