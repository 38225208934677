import React, { useEffect } from 'react';
import { useKadVerifier } from './useKadVerifier';
import { ButtonOptionPicker, Buttons, Form, FormGrid, LoadingIndicator, OccupyFreeSpace, PseudoLink2, SearchField, SimpleDialog, useVisibilityTrigger } from '../../../toolympus/components/primitives';
import { FormControlsForFields, TableForFields } from '../../../toolympus/components/schemed';
import { Button, IconButton, InputAdornment, Typography } from '@mui/material';
import { CallMadeOutlined, UploadFileOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import { FileEditUnbound } from '../../../toolympus/components/files/FileEdit';
import { KadVerifierMassAssignDialog } from './KadVerifierMassAssignDialog';
import { Link, useHistory } from 'react-router-dom';

interface Props {
  
}

const SearchQueryParam = "search";

export const KadVerifierList = (props: Props) => {
  const data = useKadVerifier();
  const history = useHistory();

  const { anchor: pagingAnchor } = useVisibilityTrigger({
    onBecameVisible: () => data.filters.update({ limit: (data.filters.item?.limit || 30) + 30 }),
    isDisabled: data.data.length < (data.filters?.item?.limit || 30),
  });

  useEffect(() => {
    const searchFromQuery = new URLSearchParams(history.location.search).get(SearchQueryParam);
    if(searchFromQuery) {
      data.filters.update({ search: searchFromQuery });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateSearch = (v: string) => {
    const urlp = new URLSearchParams(history.location.search);
    if(!v) {
      urlp.delete(SearchQueryParam);
    } else {
      urlp.set(SearchQueryParam, v);
    }
    history.replace({ pathname: history.location.pathname, search: `?${urlp.toString()}` });
    data.filters.update({ search: v });
  }

  return (<>
    <Form
      noTitle
      headerItems={<>
        <Buttons>
          <Typography variant="caption">
            Скрытые
          </Typography>
          <ButtonOptionPicker
            options={[
              [<Visibility />,"include"],
              [<VisibilityOff />,"exclude"],
            ]}
            selected={data.filters.item?.hidden}
            setSelected={v => data.filters.update({ hidden: v })}
            />
        </Buttons>

        <Buttons>
          <Typography variant="caption">
            Назначенные
          </Typography>
          <ButtonOptionPicker
            options={[
              [<Visibility />,"include"],
              [<VisibilityOff />,"exclude"],
            ]}
            selected={data.filters.item?.owned}
            setSelected={v => data.filters.update({ owned: v })}
            />
        </Buttons>

        <Buttons>
          <Typography variant="caption">
            С решениями
          </Typography>
          <ButtonOptionPicker
            options={[
              [<Visibility />, "include"],
              [<VisibilityOff />,"exclude"],
            ]}
            selected={data.filters.item?.with_cases}
            setSelected={v => data.filters.update({ with_cases: v })}
            />
        </Buttons>

        <OccupyFreeSpace />

        {data.isLoading && <LoadingIndicator />}
        <SearchField
          filter={data.filters.item?.search || ""}
          setFilter={updateSearch}
          noButton
          />
        <Button color="primary" onClick={() => data.massAssign.startEditing({ include_hidden: false, include_owned: true, ids: [], is_tried_loading: false })}>
          назначение
        </Button>
        <Button color="primary" startIcon={<UploadFileOutlined />} onClick={() => data.recordsImport.startEditing({ items: [], category: "" })}>
          импорт
        </Button>
      </>}>
      <TableForFields
        fields={[
          ["kad_number"],
          ["kad_date"],
          ["kad_finished_in_first_date"],
          ["category"],
          ["is_not_arbitration"],
          ["is_not_interesting"],
          ["is_hidden"],
          ["owner_id"],
          ["comment"],
          ["case_record_id"],
        ]}
        schema={data.schema}
        data={data.data}
        tailAnchor={pagingAnchor}
        fieldElement={f => {
          switch(f) {
            case "kad_number":
              return (r,s,o) => (
                <Buttons>
                  <PseudoLink2 onClick={() => data.editItem.startEditing(r)}>{o}</PseudoLink2>
                  <a href={r.kad_url} target="_blank" rel="noreferrer noopener"><IconButton size="small"><CallMadeOutlined /></IconButton></a>
                </Buttons>);
            case "case_record_id":
              return (r,s,o) => (r.case_record_id
                ? <Buttons>
                    <Link to={`/case-record/${r.case_record_id}`}><IconButton size="small"><CallMadeOutlined /></IconButton></Link>
                </Buttons>
                : <></>
              )
          }
        }}
        />
    </Form>

    <SimpleDialog
      dialogTitle="Импорт"
      submitOnModEnter
      isOpen={data.recordsImport.isEditing}
      close={() => data.recordsImport.cancel()}
      save={() => data.recordsImport.item?.items?.length && data.recordsImport.save()}>
        {!!data.recordsImport.item &&
          <FormGrid columns="1fr">
            <FormControlsForFields
              fields={[
                ["category"],
              ]}
              schema={data.schema}
              data={data.recordsImport.item}
              onChange={(o,c) => data.recordsImport.update(c)}
              />

            {!data.recordsImport.item.items.length
              ? <FileEditUnbound
                  data={{
                    download: () => {},
                    remove: () => Promise.resolve(),
                    file: null,
                    isLoading: false,
                    upload: f => {
                      data.recordsImport.uploadFile(f);
                      return Promise.resolve({ _id: "", contenttype: "", filename: "", kind: "", owner_id: "", url: "" });
                    },
                  }}
                />
              : <Typography>Загружено {data.recordsImport.item.items.length} строк</Typography>}
          </FormGrid>}
    </SimpleDialog>

    <SimpleDialog
      dialogTitle="Запись"
      submitOnModEnter
      isOpen={data.editItem.isEditing}
      close={() => data.editItem.cancel()}
      save={() => data.editItem.save()}>
        {!!data.editItem.item &&
          <FormGrid columns="1fr" noMargin>
            <FormGrid columns="1fr 1fr" noMargin>
              <FormControlsForFields
                fields={[
                  ["is_not_arbitration"],
                  ["is_not_interesting"],
                  ["is_hidden"],
                ]}
                schema={data.schema}
                data={data.editItem.item}
                onChange={(o,c) => data.editItem.update(c)}
                />
            </FormGrid>

            <FormControlsForFields
              fields={[
                ["kad_number"],
                ["kad_url", {
                  controlProps: {
                    endAdornment: <InputAdornment position="end">
                      <a href={data.editItem.item.kad_url} target="_blank" rel="noreferrer noopener"><IconButton size="small"><CallMadeOutlined /></IconButton></a>
                    </InputAdornment>
                  }
                }],
                ["kad_date"],
                ["kad_finished_in_first_date"],
                ["category"],
                ["owner_id"],
                ["comment"],
              ]}
              schema={data.schema}
              data={data.editItem.item}
              onChange={(o,c) => data.editItem.update(c)}
              />
          </FormGrid>}
    </SimpleDialog>

    <KadVerifierMassAssignDialog
      data={data.massAssign}
      schema={data.schema}
      />
  </>);
}
