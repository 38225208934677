import React from 'react';
import { FieldType, createSelectSchema } from '../../../toolympus/hooks/useSchema';
import { CaseSuggestion, CaseSuggestionApiPath, useCaseSuggestionsList } from './useCaseSuggestionsList';
import { Dialog, FormGrid, PseudoLink2, SimpleList, useCopyText } from '../../../toolympus/components/primitives';
import { Button, DialogActions, IconButton, InputAdornment } from '@mui/material';
import { downloadFile } from '../../../toolympus/api/core';
import { CallMade, FileCopyOutlined, GetAppOutlined } from '@mui/icons-material';
import { EditItemProps } from '../../../toolympus/api/useNewItem';
import { FieldDefinition, FormControlsForFields } from '../../../toolympus/components/schemed';
import { FormattedMessage } from 'react-intl';
import { useLoadedData } from '../../../toolympus/hooks/useLoadedData';
import { Profile, ProfileApiPath } from '../../Administration/Users/useUserProfiles';
import { ProfileSchema, UserListUserIDQueryParam } from '../../Administration/Users';
import { ActionTriggerEditButton } from '../../../toolympus/components/Actions';

const Stages = [
  { value: "constitutional_court", label: "Конституционный суд" },
  { value: "higher_court", label: "Верховный суд" },
  { value: "other", label: "Иное" },
]

const CaseSuggestionSchema = {
  _id: { label: "ID" },
  created_datetime: { type: FieldType.datetime, label: "Создана" },
  file_id: { label: "PDF" },
  is_processed: { label: "Обработана", type: FieldType.bool },

  number: { label: "Номер решения" },
  external_url: { label: "Ссылка" },
  ruling_date: { label: "Дата решения", type: FieldType.date },
  jurisdiction: { label: "Юрисдикция" },
  case_type: { label: "Темы", type: FieldType.dictionarySelect, dictionary: "CaseTypes" },
  stage: createSelectSchema(Stages, { label: "Стадия" }) ,
  court: { label: "Суд" },

  short_description: { label: "Краткий вывод суда", type: FieldType.textlong },
};


const CaseSuggestionsActionTriggers = [
  "locus.logic.case-records.suggestions/suggestion-submitted",
]

interface Props {
  
}

export const CaseSuggestionsList = (props: Props) => {
  const data = useCaseSuggestionsList();

  return (<>
    <SimpleList
      data={{
        ...data,
        filter: data.filter.filter,
        setFilter: data.filter.setFilter,
        create: () => Promise.resolve({} as CaseSuggestion),
      }}
      tabs={[
        ["Все", "all"],
        ["На рассмотрении", "new"],
        ["Обработанные", "processed"],
      ]}
      fields={[
        ["_id"],
        ["number"],
        ["ruling_date"],
        ["jurisdiction"],
        ["case_type"],
        ["stage"],
        ["court"],
        ["file_id"],
        ["created_datetime", { utcToLocal: true }],
      ]}
      linkFields={[]}
      getLinkValue={() => ""}
      schema={CaseSuggestionSchema}
      title="Предложения решений"
      searchAuto
      searchAutoFocus
      noCreate
      headerActions={<>
        <ActionTriggerEditButton
          triggers={CaseSuggestionsActionTriggers}
          apiPath="/api/actions/trigger"
          />
        </>}
      fieldElement={f => {
        switch(f) {
          case "_id":
            return (row,s,orig) => <PseudoLink2 border onClick={() => data.editItem.startEditing(row)}>{orig}</PseudoLink2>
          case "file_id":
            return (row,s,orig) => <IconButton size="small" color="primary" onClick={() => downloadFile(`${CaseSuggestionApiPath}/file/${row.file_id}`)}><GetAppOutlined /></IconButton>
        }
      }}
      />

      <CaseSuggestionEditDialog data={data.editItem} />
  </>);
}



const CaseSuggestionEditDialog = ({ data }: { data: EditItemProps<CaseSuggestion> }) => {
  const controls = (fields: FieldDefinition[]) => (
    <FormControlsForFields
      data={data.item}
      onChange={(o,c) => data.update(c)}
      schema={CaseSuggestionSchema}
      fields={fields}
      />);

  const authorUserProfile = useLoadedData<Profile>(`${ProfileApiPath}/${data.item?.author_user_id}`, {} as Profile, !!data.item?.author_user_id);
  const author = data.item?.author || authorUserProfile.data;
  const profileControls = (fields: FieldDefinition[]) => (
    <FormControlsForFields
      data={author}
      onChange={(o,c) => {}}
      schema={ProfileSchema}
      fields={fields}
      />);

  const copyText = useCopyText();

  return (
    <Dialog
      isOpen={data.isEditing}
      close={() => data.cancel()}
      maxWidth="md"
      fullWidth
      noFullscreen
      dialogTitle="Решение"
      titleActions={<>
        
      </>}>

      {author && <FormGrid columns="repeat(6,1fr)" gap="dense">
        {profileControls([
          ["lastname", { disabled: true, wrapperStyle: { gridColumn: "span 2" }}],
          ["firstname", { disabled: true, wrapperStyle: { gridColumn: "span 2" }}],
          ["middlename", { disabled: true, wrapperStyle: { gridColumn: "span 2" }}],
          ["company", { disabled: true, wrapperStyle: { gridColumn: "span 3" }}],
          ["position", { disabled: true, wrapperStyle: { gridColumn: "span 3" }}],
          ["email", {
            disabled: true,
            wrapperStyle: { gridColumn: "span 3" },
            controlProps: {
              endAdornment: data.item?.author_user_id
                ? <InputAdornment position="end"><IconButton href={`/user-profile?${UserListUserIDQueryParam}=${data.item.author_user_id}`} target="_blank" size="small"><CallMade /></IconButton></InputAdornment>
                : <InputAdornment position="end"><IconButton onClick={() => copyText(author.email || "")} size="small"><FileCopyOutlined /></IconButton></InputAdornment>,
            }
          }],
          ["phone", { disabled: true, wrapperStyle: { gridColumn: "span 3" }}],
        ])}
        </FormGrid>}

      {data.item && <FormGrid columns="1fr 1fr 1fr max-content" style={{ alignItems: "end", marginTop: "2rem" }}>
        {controls([
          ["number"],
          ["ruling_date"],
          ["created_datetime", { utcToLocal: true }],
        ])}

        <Button size="small" color="primary" endIcon={<GetAppOutlined />} onClick={() => downloadFile(`${CaseSuggestionApiPath}/file/${data.item?.file_id}`)}>скачать PDF</Button>
      </FormGrid>}

      {data.item && <FormGrid columns="1fr 1fr" noMargin>
        {controls([
          ["case_type"],
          ["jurisdiction"],
          ["stage"],
          ["court"],
        ])}
      </FormGrid>}

      {data.item && <FormGrid columns="1fr" noMargin>
        {controls([
          ["short_description"],
          ["is_processed"],
        ])}
      </FormGrid>}

      
      
      <DialogActions>
        <Button onClick={() => data.cancel() }><FormattedMessage id="common.cancel" /></Button>
        <Button color="primary" variant="contained" onClick={() => data.save()}><FormattedMessage id="common.save" /></Button>
      </DialogActions>
    </Dialog>
  );
}

