import React from 'react';
import { Institute, InstituteSchema } from './useManageInstitutes';
import { FormGrid, SimpleDialog } from '../../../toolympus/components/primitives';
import { FormControlsForFields } from '../../../toolympus/components/schemed';
import { RulesList } from './RulesList';

interface Props {
  isOpen: boolean;
  close: () => void;
  item: (Omit<Institute, "_id"> & { _id?: number }) | null;
  update: (changes: Partial<Institute>) => void;
  save: () => Promise<Institute>;
}

export const InstituteEditDialog = (props: Props) => {
  const { isOpen, close, item, update, save } = props;
  return (
    <SimpleDialog
      dialogTitle="Арбитражный институт"
      close={close}
      isOpen={isOpen}
      save={save}
      maxWidth="md">

        <FormGrid columns="2fr 1fr">
          {item && <FormControlsForFields
            data={item}
            onChange={(o,c) => update(c)}
            schema={InstituteSchema}
            fields={[
              ["title"],
              ["title_short"],
            ]}
            />}

          {item && <FormControlsForFields
            data={item.translations?.en || {}}
            onChange={(o,c) => update({ translations: { en: { ...o, ...c } }})}
            schema={InstituteSchema}
            fields={[
              ["title"],
              ["title_short", { label: "Краткое название (en)"}],
            ]}
            />}

          {item && <FormGrid columns="1fr 1fr" noMargin>
            <FormControlsForFields
              data={item}
              onChange={(o,c) => update(c)}
              schema={InstituteSchema}
              fields={[
                ["is_adhoc"],
                ["is_hidden"],
              ]}
              />
            </FormGrid>}

          {item && <FormControlsForFields
            data={item}
            onChange={(o,c) => update(c)}
            schema={InstituteSchema}
            fields={[
              ["sort_order"],
            ]}
            />}
        </FormGrid>

        {!!item && !!item._id && !item.is_adhoc && <RulesList instituteId={item._id} />}
      
    </SimpleDialog >
  );
}
