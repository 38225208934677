export const LocusMessages = {
  caserecord: {
    _id: "ID",
    number: "Номер",
    created_datetime: "Создано",
    is_published: "Опубликовано",
    is_ping_owner_enabled: "Отслеживать",

    description: "Краткое описание",
    content: "Текст акта",

    ruling_date: "Дата акта",
    case_type: "Тип",
    rules: "Правила",
    stage: "Стадия",
    court: "Суд",
    topics: "Темы",
    case_category: "Категория",
    institute: "Ad hoc / Институт",
    adhoc_jury: "Ad hoc состав",
    place_country: "Место арбитража",
    is_not_interesting: "Неинтересное",
  },

  case_records: {
    participants: {
      labels: {
        kind: "Сторона",
        title: "Название",
        country: "Страна",
      },
      
      kind: {
        claimant: "Истец",
        respondant: "Ответчик",
        other: "Другое",
      },
    },

  },

  expert_comment: {
    _id: "ID",
    created_datetime: "Создано",
    is_published: "Опубликовано",
    title: "Название",
    comment_date: "Дата",
    description: "Справка",
    content: "Текст",
    author: "Автор",
  },

  kad: {
    tasks: {
      case_record_not_found: "Решение не найдено",
    },
  },

  tasks: {
    error: {
      kad_record_not_found: "Запись КАД не найдена",
    },

    task_type: {
      "locus.logic.tasks.moderation-tasks/kad_not_interesting_returned": "Не интересное - возврат",
      "locus.logic.tasks.moderation-tasks/kad_not_interesting": "Модерация: не интересное",
      "locus.logic.tasks.moderation-tasks/kad_not_arbitration": "Модерация: не арбитраж",
      "locus.logic.kad-verifier.kad-verifier-tasks/kad-verifier-task": "КАД",
    },
  },
}