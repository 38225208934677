import React from 'react';
import { RenderElementProps, useSelected } from 'slate-react';
import { usePowerEditorContext } from '../../../toolympus/components/PowerDoc/slate/PowerEditorContext';
import { usePowerEditorSettings } from '../../../toolympus/components/PowerDoc/PowerEditorSettingsContext';
import { PowerEditorBase } from '../../../toolympus/components/PowerDoc';
import { PanelWrapper } from '../../../toolympus/components/PowerDoc/plugins/Panel/PanelBlockElement';
import { FormGrid } from '../../../toolympus/components/primitives';
import { useBlockEdit } from '../../../toolympus/components/PowerDoc/plugins';
import { FormControlsForFields } from '../../../toolympus/components/schemed';
import { FieldType } from '../../../toolympus/hooks/useSchema';

export const UpdatePanelBlockElementType = "update-panel-block";

export const UpdatePanelBlockElement = (props: RenderElementProps) => {
  const { element, attributes, children } = props;

  const isSelected = useSelected();

  const { viewMode } = usePowerEditorContext();
  
  const settings = usePowerEditorSettings();
  const EditorComponent = settings.EditorComponent || PowerEditorBase;

  const data = useBlockEdit(element, {
    getElementState: e => ({ content: e.content, title: (e as any).title, date: (e as any).date }),
  })
  
  return (
    <PanelWrapper {...attributes} className="panel-block-element" isSelected={isSelected} viewMode={viewMode}>
        {children}
        <div style={{ width: "100%" }} contentEditable={false}>
          <FormGrid columns="2fr 1fr" noMargin gap="dense">
            <FormControlsForFields
              data={data}
              fields={[
                ["title"],
                ["date"],
              ]}
              onChange={(o,c) => data.update(c)}
              schema={{
                title: { label: "Заголовок" },
                date: { label: "Дата", type: FieldType.date },
              }}
              />
            
          </FormGrid>
          
          
          <EditorComponent
            content={data.content}
            update={v => data.update({ content: v})}
            viewMode={viewMode}
            />
        </div>
    </PanelWrapper>
  );
}
