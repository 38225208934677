import { useState } from "react";
import { apiFetch } from "../../../toolympus/api/core";
import { useLoadedData } from "../../../toolympus/hooks/useLoadedData"
import { CaseRecord, CaseRecordApiPath } from "../useCaseRecordsList"
import { useDialogState } from "../../../toolympus/components/primitives";
import { useItemActionWithConfirmation } from "../../../toolympus/api/useAction";

export const useCaseRecordLinkedCases = (caseRecordId: number) => {
  const data = useLoadedData<CaseRecord[]>(`${CaseRecordApiPath}/${caseRecordId}/case-link`, [], caseRecordId >= 0);

  const newItem = useDialogState();

  const addRelatedCase = (otherRecordId: number) => {
    return apiFetch<{}>(`${CaseRecordApiPath}/${caseRecordId}/case-link/${otherRecordId}`, "post")
      .then(() => { data.reload(); newItem.close(); });
  }

  const removeRelatedCase = useItemActionWithConfirmation<CaseRecord,{}>(
    item => apiFetch<{}>(`${CaseRecordApiPath}/${caseRecordId}/case-link/${item._id}`, "delete").then(x => { data.reload(); return x; }),
    { 
      title: "Удалить связанное решение",
    });

  const [search, setSearch] = useState<string>("");

  const allCases = useLoadedData<CaseRecord[]>(`${CaseRecordApiPath}`, [], newItem.isOpen);

  const allFiltered = (search
      ? allCases.data.filter(c => c.number.toLowerCase().includes(search.toLowerCase()))
      : allCases.data)
      .filter(r => r._id !== caseRecordId && !data.data.find(included => included._id === r._id));

  return {
    ...data,
    newItem: {
      ...newItem,
      save: addRelatedCase,
    },
    allCases: {
      ...allCases,
      data: allFiltered,
    },
    removeRelatedCase,
    
    search,
    setSearch,
  }
}
