import React from 'react';
import styled from '@emotion/styled';
import { Chip } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Close } from '@mui/icons-material';

const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 3px;

  & .clear-all {
    border-color: transparent;
  }
`;

interface Props {
  task_types: string[];
  update: (v: string[]) => void;
  noRemove?: boolean;
}

export const TaskTypesFilterChips = (props: Props) => {
  return (
    <Wrapper>
      {props.task_types.map(t => (
        <Chip
          size="small"
          label={<FormattedMessage id={`tasks.task_type.${t}`} />}
          onDelete={props.noRemove ? undefined : () => props.update(props.task_types.filter(x => x !== t))}
          deleteIcon={<Close />}
          />
      ))}
    </Wrapper>
  );
}

interface TogglesProps {
  task_types_all: string[];
  task_types: string[];
  update: (v: string[]) => void;
}

export const TaskTypesFilterChipsToggles = (props: TogglesProps) => {
  return (
    <Wrapper>
      {props.task_types_all.map(t => (
        <Chip
          size="small"
          variant={props.task_types.includes(t) ? "filled" : "outlined"}
          color="primary"
          label={<FormattedMessage id={`tasks.task_type.${t}`} />}
          onClick={() => props.update(props.task_types.includes(t) ? props.task_types.filter(x => x !== t) : [...props.task_types, t])}
          />
      ))}

      {!!props.task_types.length && <Chip
        size="small"
        variant="outlined"
        className="clear-all"
        onClick={() => props.update([])}
        label={<FormattedMessage id="common.clear" />}
        avatar={<Close />}
        />}
    </Wrapper>
  );
}
