import React from 'react';
import { EditItemProps } from '../../../toolympus/api/useNewItem';
import { KadMassAssignParams } from './useKadVerifier';
import { FormGrid, LoadingIndicator, SimpleDialog } from '../../../toolympus/components/primitives';
import { useItemWithControls } from '../../../toolympus/api/useWithControls';
import { FieldType, Schema } from '../../../toolympus/hooks/useSchema';
import { Typography } from '@mui/material';
import { FormControlsForFields } from '../../../toolympus/components/schemed';

interface Props {
  data: EditItemProps<KadMassAssignParams>;
  schema: Schema;
}

const KadMassAssignParamsSchema: Schema = {
  date_from: { type: FieldType.date, label: "С" },
  date_to: { type: FieldType.date, label: "По" },
  finished_first_from: { type: FieldType.date, label: "Завершение в I инстанции с" },
  finished_first_to: { type: FieldType.date, label: "Завершение в I инстанции по" },
  include_owned: { type: FieldType.bool, label: "Включая назначенные" },
  include_with_cases: { type: FieldType.bool, label: "Включая с решениями" },
  include_hidden: { type: FieldType.bool, label: "Включая скрытые" },
}

export const KadVerifierMassAssignDialog = (props: Props) => {
  const { data, schema } = props;

  const { controls } = useItemWithControls(
    { data: data.item || {}, update: (c: Partial<KadMassAssignParams>) => data.update({ ...c, is_tried_loading: false, ids: [] }), errors: data.errors },
    { schema, schemaChanges: KadMassAssignParamsSchema });

  return (
    <SimpleDialog
      dialogTitle="Назначить"
      isOpen={data.isEditing}
      noSubmitOnEnter
      close={() => data.cancel()}
      save={() => (!data.item?.ids?.length || data.item?.new_owner_id) && data.save()}
      saveLabel={data.item?.ids?.length
        ? "Назначить"
        : "Загрузить записи"
      }>
      {!!data.item &&
        <FormGrid columns="1fr 1fr">
          {controls([
            ["category"],
            ["owner_id"],
            ["date_from"],
            ["date_to"],
            ["finished_first_from"],
            ["finished_first_to"],
            ["include_owned"],
            ["include_hidden"],
            ["include_with_cases"],
          ])}
        </FormGrid>}

      <FormGrid columns="1fr">
        {data.item?.is_tried_loading && !!data.item?.ids?.length &&
          <Typography>Найдено записей: {data.item?.ids?.length}</Typography>}
        {data.item?.is_tried_loading && !data.item?.ids?.length &&
          <Typography>Записи не найдены</Typography>}

        {!!data.item?.ids?.length &&
          <FormControlsForFields
            data={data.item || {}}
            fields={[["new_owner_id"]]}
            onChange={(o,c) => data.update(c)}
            schema={{ new_owner_id: { ...schema.owner_id, label: "Назначить" } }}
            />}

        {data.isLoading && <LoadingIndicator />}
      </FormGrid>

    </SimpleDialog>
  );
}
