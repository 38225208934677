import { useState } from "react";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { useTextFilter } from "../../toolympus/components/schemed/Filtering/useTextFilter";
import { useSingleSchema } from "../../toolympus/hooks/useSchema";
import { useNewItem } from "../../toolympus/api/useNewItem";
import { useFieldSorting } from "../../toolympus/hooks/useFieldSorting";

export interface Partner {
  _id: string;
  title: string;
  link: string;
  image_url?: string | null;
}

export interface Author {
  _id: string;
  title: string;
  job?: string;
  link?: string;
  image_url?: string | null;
  description?: any;
}

export interface CountryPractice {
  _id: number;
  title: string;
  slug: string;
  created_datetime: string;
  is_published?: boolean;
  
  image_url?: string | null;
  description?: any;
  content?: any;
  authors: Author[];
  partners: Partner[];
  translations?: any;
}

export type NewCountryPractice = Omit<CountryPractice, "_id" | "created_datetime" | "authors" | "partners">;

export const CountryPracticeApiPath = "/api/international/practice"

export const useCountryPracticeSchema = () => {
  const { schema } = useSingleSchema(`${CountryPracticeApiPath}/uiconfig`);
  return schema;
}

export const useCountryPracticeList = () => {
  const [view, setView] = useState<string>("unpublished");
  const sorting = useFieldSorting({
    disallowedFields: ["description"],
  });
  const data = useLoadedData<CountryPractice[]>(`${CountryPracticeApiPath}?view=${view}&${sorting.queryParameter}`, []);
  const filter = useTextFilter<CountryPractice>(cr => `${cr.title} ${cr.slug} ${cr._id}`);

  const newRecord = useNewItem<NewCountryPractice, CountryPractice>(CountryPracticeApiPath, { title: "", slug: "" });

  return {
    ...data,
    data: filter.filterData(data.data),
    filter,
    view,
    setView,
    sorting,

    newRecord,
  }
}

export type CountryPracticeListData = ReturnType<typeof useCountryPracticeList>;
