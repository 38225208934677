import React, { ReactNode } from 'react';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';

import LocalizationProvider from './toolympus/components/localization/LocalizationProvider';
import { FormatsProvider } from './toolympus/components/schemed';

import { LocalizationProvider as DatesLocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

import { theme } from './theme';
import { LocusMessages } from './locusMessages';
import { ConfirmationDialogProvider } from './toolympus/components/primitives';
import { ApiConfgurationProvider } from './toolympus/components/ApiConfguration';
import { ActionsConfigProvider } from './toolympus/components/Actions/ActionsConfigProvider';

interface Props {
    children?: ReactNode;
}

export const Providers = (props: Props) => {
    return (
        <ThemeProvider theme={theme}>
            <StylesProvider injectFirst >
              <ApiConfgurationProvider emailTemplatesApiPath="/api/emails" actionsApiPath="/api/actions">
                <LocalizationProvider locale="ru" defaultLocale="ru" messages={LocusMessages}>
                  <ActionsConfigProvider>
                      <FormatsProvider formats={{ date: "DD.MM.YYYY" }}>
                          <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                            <DatesLocalizationProvider dateAdapter={AdapterMoment}>
                              <ConfirmationDialogProvider>
                                {props.children}
                              </ConfirmationDialogProvider>
                            </DatesLocalizationProvider>
                          </SnackbarProvider>
                      </FormatsProvider>
                    </ActionsConfigProvider>
                  </LocalizationProvider>
              </ApiConfgurationProvider>
            </StylesProvider>
        </ThemeProvider>
    );
}
