import { useEffect, useMemo } from "react";
import { useCrudItem } from "../../toolympus/api/useSimpleCrud"
import { useLocalizedRecord } from "../../toolympus/components/LocalizedRecords";
import { DefaultLanguage } from "../Common/Localization";
import { CaseRecord, CaseRecordApiPath, useCaseRecordSchema } from "./useCaseRecordsList"
import { useDictionaries } from "../../toolympus/hooks/useDictionaries";
import { FieldType, mergeSchema } from "../../toolympus/hooks/useSchema";
import { useCaseRecordReviewStatus } from "./useCaseRecordReviewStatus";

export const useCaseRecordEdit = (id: number) => {
  const data = useCrudItem<CaseRecord>(`${CaseRecordApiPath}/${id}`, {
    defaultValue: {
      _id: -1,
      number: "",
      created_datetime: "",
    }
  });

  const localized = useLocalizedRecord(data.data, {
    defaultLocale: DefaultLanguage,
    translationsField: "translations",
    translatedFields: ["description"],
    translatedInPlaceFields: ["content"],
    updateItem: (o,c) => data.update(c),
  });

  const dictionaries = useDictionaries();
  const rulesDict = dictionaries.Rules;
  const institutesDict = dictionaries.Institutes;
  
  const crSchema = useCaseRecordSchema();
  
  const [,availableRules,schema,isAdhoc] = useMemo(() => {
    if(!data.data.institute_id) {
      return [null, rulesDict.records, crSchema, false];
    }
    const institute = (institutesDict.records || []).find(i => i.code === data.data.institute_id);
    if(!institute || institute.extra?.is_adhoc) {
      return [institute || null, rulesDict.records, crSchema, !!institute?.extra?.is_adhoc];
    }

    const availableRules = rulesDict.records.filter(rules => `${rules.extra?.institute_id}` === institute.code);
    const s = mergeSchema(crSchema, {
      rules: {
        values: availableRules.map(r => ({ value: r.code, label: r.label })),
        valueDict: availableRules.reduce<Record<string,string>>((r,rules) => { r[rules.code] = rules.label; return r; }, {}),
        type: FieldType.select,
      },
    });
    return [institute, availableRules, s, !!institute?.extra?.is_adhoc];

  }, [data.data.institute_id, rulesDict, institutesDict, crSchema]);

  useEffect(() => {
    if(!!data.data.rules && availableRules && availableRules.length && !availableRules.find(r => r.code === data.data.rules)) {
      data.update({ rules: null });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableRules, data.data.rules]);

  useEffect(() => {
    if(!isAdhoc && data.data.adhoc_jury) {
      data.update({ adhoc_jury: null });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdhoc]);

  const statusReview = useCaseRecordReviewStatus(data);

  return {
    ...data,
    schema,
    ...localized,
    data: localized.item,
    update: (changes: Partial<CaseRecord>) => localized.updateItem(data.data, changes),
    isAdhoc,
    statusReview,
  };
}
