import { createTheme } from '@mui/material/styles';
import { BaseMuiThemeComponentsSettings } from './toolympus/components/primitives/BaseThemeSettings';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#1F9DD9',
        },
        secondary: {
            main: '#0A2745',
        },
    },

    typography: {
      fontFamily: "HelveticaNeue",
    },



    components: {
      ...BaseMuiThemeComponentsSettings,
    },
});
