import { useCallback, useMemo, useState } from "react";
import { useEditItem2, useNewItem } from "../../../toolympus/api/useNewItem";
import { useLocalizedList } from "../../../toolympus/components/LocalizedRecords";
import { useTextFilter } from "../../../toolympus/components/schemed/Filtering/useTextFilter";
import { LoadedData, useLoadedData } from "../../../toolympus/hooks/useLoadedData";
import { FieldType, Schema } from "../../../toolympus/hooks/useSchema";
import { DefaultLanguage } from "../../Common/Localization";
import { apiFetch } from "../../../toolympus/api/core";
import { useIntl } from "react-intl";
import { useItemActionWithConfirmation } from "../../../toolympus/api/useAction";

export interface Topic {
  code: string;
  parent_code?: string;
  title: string;
  
  sort_order: number;
  is_hidden?: boolean;
  translations?: any;
  level?: number;
}

export const TopicSchema: Schema = {
  code: { label: "Код" },
  parent_code: { label: "Группа" },
  sort_order: { label: "Порядок", type: FieldType.number },
  title: { label: "Название" },
  description: { label: "Описание", type: FieldType.textlong },
  
  is_hidden: { label: "Скрыта", type: FieldType.bool },
}

const ApiPath = "/api/settings/topic";

type TopicsTreeNode = { node?: Topic, children: Topic[] };
type TopicsTree = Record<string, TopicsTreeNode>;

const NoParentCode = "::NO::";
export const assembleTree = (records: Topic[]): TopicsTree => {
  return records.reduce<TopicsTree>(
    (r,topic) => {
      const present = r[topic.code];
      if(present) {
        present.node = topic;
      } else {
        r[topic.code] = { node: topic, children: [] };
      }
      const parentCode = topic.parent_code || NoParentCode;
      const presentParent = r[parentCode];
      if(presentParent) {
        presentParent.children = [...presentParent.children, topic];
      } else {
        r[parentCode] = { children: [topic] };
      }

      return r;
    },
    {}
  );
}

export function disassembleTree(tree: TopicsTree, parentCode: string = NoParentCode, level: number = 0): Topic[] {
  const children = tree[parentCode]?.children || [];
  return children
    .sort((a,b) => (a.sort_order || 0) <= (b.sort_order || 0) ? -1 : 1)
    .reduce<Topic[]>((r,topic) => {
      r.push({ ...topic, level });
      r.push(...disassembleTree(tree, topic.code, level+1))
      return r;
    }, []);
}

const pathToRoot = (tree: TopicsTree, code: string | undefined): string[] => {
  if(!code) {
    return [];
  }
  const node = tree[code];
  if(!node.node) {
    return [];
  } else {
    return [node.node.code, ...pathToRoot(tree, node.node.parent_code)];
  }
}

const treeWithDescendantsCodes = (tree: TopicsTree, code: string | undefined): string[] => {
  if(!code) {
    return [];
  }
  const node = tree[code];
  if(!node.node) {
    return [];
  } else {
    return [node.node.code, ...((node.children || []).map(c => treeWithDescendantsCodes(tree, c.code)).reduce((r,items) => { r.push(...items); return r;}, []))];
  }
}


const useChangeParent = (data: LoadedData<Topic[]>, tree: TopicsTree) => {
  const [itemToChangeParent, setItemToChangeParent] = useState<Topic | null>(null);
  const [itemNewParent, setItemNewParent] = useState<Topic | null>(null);
  const itemToChangeParentSubtreeCodes = useMemo(
    () => itemToChangeParent ? treeWithDescendantsCodes(tree, itemToChangeParent.code) : [],
    [itemToChangeParent, tree]);

  const startChanging = useCallback((item: Topic) => setItemToChangeParent(item), []);

  return {
    item: itemToChangeParent,
    newParent: itemNewParent,
    movedSubtreeCodes: itemToChangeParentSubtreeCodes,
    startChanging,
    selectNewParent: (item: Topic) => {
      if(!itemToChangeParentSubtreeCodes.find(code => code === item.code)) {
        setItemNewParent(item);
      }
    },
    commitChange: () => {
      if(itemToChangeParent && itemNewParent) {
        apiFetch<Topic>(`${ApiPath}/${itemToChangeParent.code}`, "put", { parent_code: itemNewParent.code })
          .then(() => {
            setItemToChangeParent(null);
            setItemNewParent(null);
            data.reload();
          })
      }
    },
    cancel: () => { setItemToChangeParent(null); setItemNewParent(null); },
  }
}


const useCollapseTree = (tree: TopicsTree) => {
  const [collapsed, setCollapsed] = useState<string[]>([]);
  
  const isCollapsed = useCallback(
    (item: Topic) => !!collapsed.find(c => c === item.code),
    [collapsed]);

  const isCollapsible = useCallback(
    (item: Topic) => (tree[item.code]?.children || []).length > 0,
    [tree]);

  const hidden = useMemo(() => {
    const allHidden = collapsed
      .map(code => treeWithDescendantsCodes(tree, code).slice(1))
      .reduce((r,codes) => { r.push(...codes); return r; }, []);
    return new Set(allHidden);
  }, [tree, collapsed]);

  const isHidden = useCallback((item: Topic) => {
    return hidden.has(item.code);
  }, [hidden]);

  const toggleCollapsed = useCallback((item: Topic) => setCollapsed(x => {
    const without = x.filter(c => c !== item.code);
    return without.length === x.length ? [...x, item.code] : without;
  }), []);
    
  return {
    collapse: (item: Topic) => setCollapsed(x => [...x, item.code]),
    uncollapse: (item: Topic) => setCollapsed(x => x.filter(c => c !== item.code)),
    toggleCollapsed,
    collapseAll: () => {
      const nonLeafs = Object.values(tree).filter(node => (node.children || []).length > 0).map(node => node.node?.code);
      setCollapsed(nonLeafs.filter(x => !!x) as string[]);
    },
    uncollapseAll: () => setCollapsed([]),
    
    isCollapsed,
    isHidden,
    isCollapsible,
  }
}


export const useManageTopics = () => {
  const data = useLoadedData<Topic[]>(ApiPath, []);
  const filter = useTextFilter<Topic>(c => `${c.title} ${c.code}`);


  const [treeData,tree] = useMemo(
    () => {
      const tree = assembleTree(data.data);
      return [disassembleTree(tree), tree];
    },
    [data.data]);

  const filtered = useMemo(
    () => {
      if(filter.filter.length === 0) {
        return treeData;
      }

      const matchingItems = filter.filterData(treeData);
      const codesWithParents = matchingItems.map(topic => pathToRoot(tree, topic.code)).reduce((r,x) => { r.push(...x); return r; }, []);
      const fullCodesSet = new Set(codesWithParents);
      return treeData.filter(topic => fullCodesSet.has(topic.code));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [treeData, filter.filter]
  )

  const localized = useLocalizedList([], {
    defaultLocale: DefaultLanguage,
    translatedFields: [],
    translationsField: "translations",
    updateItem: () => {},
  });

  const maxSortOrder = Math.max(...data.data.map(c => c.sort_order), 0);

  const newTopicDefault = useMemo(() => ({
    code: "",
    title: "",
    sort_order: maxSortOrder + 1,
    translations: { en: { title: "", title_short: "" }},
  }), [maxSortOrder])

  const newTopic = useNewItem<Topic, Topic>(ApiPath, newTopicDefault);

  const editTopic = useEditItem2<Topic>({
    getApiPath: c => `${ApiPath}/${c.code}`,
  });

  const { formatMessage } = useIntl();

  const removeTopic = useItemActionWithConfirmation<Topic, {}>(
    item => apiFetch<Topic>(`${ApiPath}/${item?.code}`, "delete")
      .then(x => {
        data.reload();
        return x;
    }),
    {
      title: formatMessage({ id: "dictionaries.remove_title" }),
      confirmationHint: formatMessage({ id: "dictionaries.remove_hint" }),
    }
  );

  const changeParent = useChangeParent(data, tree);

  const collapser = useCollapseTree(tree);

  return {
    ...data,
    data: filtered,
    filter,
    schema: TopicSchema,

    newTopic: {
      ...newTopic,
      save: () => newTopic.save().then(c => { data.reload(); return c; }),
    },
    editTopic: {
      ...editTopic,
      save: () => editTopic.save().then(c => { data.reload(); return c; }),
    },
    removeTopic,

    changeParent,
    collapser,

    locale: localized.locale,
    setLocale: localized.setLocale,
  }
}
