import React from 'react';
import { MoreActionsMenuButton, PseudoLink2, SimpleList } from '../../../toolympus/components/primitives';
import { useManageInstitutes } from './useManageInstitutes';
import { InstituteEditDialog } from './InstituteEditDialog';
import { LanguageSwitch } from '../../Common/Localization';
import { FormattedMessage } from 'react-intl';

export const InstituteList = () => {
  const data = useManageInstitutes();
  return (<>
    <SimpleList
      title=""
      data={{
        ...data,
        setFilter: data.filter.setFilter,
        filter: data.filter.filter,
        view: "",
        setView: () => {},
        create: () => { data.newInstitute.startEditing(); return Promise.resolve({ _id: -1, title: "", title_short: "", sort_order: -1 }); },
      }}
      searchAuto
      searchAutoFocus
      schema={data.schema}
      fields={[
        ["_id", { width: 40 }],
        ["title"],
        ["title_short"],
        ["is_adhoc"],
        ["sort_order"],
      ]}
      linkFields={[]}
      getLinkValue={() => ""}
      fieldElement={f => {
        switch(f) {
          case "_id":
          case "title":
          case "title_short":
            return (row,schema,orig) => <PseudoLink2 border onClick={() => data.editInstitute.startEditing(row)}>{orig}</PseudoLink2>
        }
      }}
      rowButtons={row => (
        <MoreActionsMenuButton actions={[["remove", <FormattedMessage id="common.delete" />, () => data.removeInstitute.run(row)]]} />
      )}
      headerActions={<>
        <LanguageSwitch locale={data.locale} setLocale={data.setLocale} />
      </>}
      />

    <InstituteEditDialog
      {...data.newInstitute}
      isOpen={data.newInstitute.isEditing}
      close={data.newInstitute.cancel}
      />
    <InstituteEditDialog
      {...data.editInstitute}
      isOpen={data.editInstitute.isEditing}
      close={data.editInstitute.cancel}
      />
  </>);
}
