import { useEffect, useMemo, useState } from "react";
import { useNewItem } from "../api/useNewItem";
import { FieldFiltersQueryParam, useFieldFilters } from "../components/schemed/Filtering";
import { useTextFilter } from "../components/schemed/Filtering/useTextFilter";
import { SortingConfig } from "./useFieldSorting";
import { useLoadedData } from "./useLoadedData";
import { useChunkedLoadedList } from "./useLoadedList";
import { Schema } from "./useSchema";
import { base64encode } from "../api/base64";

export interface SmartListConfig<T> {
  viewDefault?: string;
  lsKeysPrefix?: string;

  noView?: boolean;
  noLimit?: boolean;
  noApiFieldFiltering?: boolean;
  extraParams?: Record<string, any>;
  sorting?: SortingConfig;
  schema: Schema;

  textFilterFn?: (r: T) => string;
  newRecordDefault: Partial<T>;
  onNewRecordSaved?: (r: T) => void;
}

const ConstantlyEmptyStr = () => "";

export const useSmartList = <T extends Record<string, any>>(apiPath: string, cfg: SmartListConfig<T>) => {
  const [fieldFiltersParam, setFieldFiltersParam] = useState<string>("");
  const data = useChunkedLoadedList<T>(apiPath, {
    sorting: cfg.sorting,
    viewDefault: cfg.viewDefault,
    viewLSKey: cfg.lsKeysPrefix ? `${cfg.lsKeysPrefix}_view` : undefined,
    extraParams: fieldFiltersParam ? { ...cfg.extraParams, [FieldFiltersQueryParam]: fieldFiltersParam } : cfg.extraParams,
  });

  const count = useLoadedData<{ total_records: number }>(`${apiPath}/count?${data.queryNoLimit}`, { total_records: 0 }, !cfg.noLimit);

  const schema = cfg.schema;

  const filtering = useFieldFilters(schema, data.data, {
    storageKey: cfg.lsKeysPrefix ? `${cfg.lsKeysPrefix}_filtering` : undefined,
    userSettingsKey: cfg.lsKeysPrefix ? `${cfg.lsKeysPrefix}_filtering` : undefined,
  });

  useEffect(() => {
    setFieldFiltersParam(filtering.filtersParam && !cfg.noApiFieldFiltering
        ? encodeURIComponent(base64encode(JSON.stringify(filtering.filtersParam)))
        : "")
  }, [filtering.filtersParam, cfg.noApiFieldFiltering]);


  const filter = useTextFilter<T>(cfg?.textFilterFn ||  ConstantlyEmptyStr);

  const newRecord = useNewItem<Partial<T>, T>(apiPath, cfg.newRecordDefault);

  const filteredData = useMemo(() => {
    const dataF1 = filter.filterData(data.data);
    const dataF2 = cfg.noApiFieldFiltering ? dataF1.filter(filtering.filterFn) : dataF1;
    return dataF2;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.data, filter.filter, cfg.noApiFieldFiltering, filtering.filters, schema]);

  return {
    ...data,
    data: filteredData,
    schema,
    filtering,
    filter,
    count,

    newRecord: {
      ...newRecord,
      save: (c?: Partial<T>) => newRecord.save(c)
        .then(x => {
          if(cfg.onNewRecordSaved) {
            cfg.onNewRecordSaved(x);
          }
          data.reload();
          return x;
        }),
    },
  }
}
