import React, { ReactNode, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { TaskListData, useTaskList } from './useTaskList';
import { TaskPanel } from './TaskPanel';
import { ExecuteTaskActionDialog } from './ExecuteTaskActionDialog';
import { Buttons, LoadingIndicator, OccupyFreeSpace, SearchField } from '../primitives';
import { Button, IconButton, MenuItem, Select, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { FilterAltOutlined, RefreshOutlined, SearchOutlined, SortOutlined, VisibilityOffOutlined, Visibility } from '@mui/icons-material';
import { TaskTypesFilterChips, TaskTypesFilterChipsToggles } from './TaskTypesFilterChips';


const TaskListWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 0.5rem;

  & .tail {
    justify-content: center;
  }

  & .total {
    white-space: nowrap;
    align-self: flex-end;
  }

  & .toggle {
    text-transform: none;
    font-weight: 400;
    white-space: nowrap;
  }
  
`;
TaskListWrapper.defaultProps = { className: "task-list" };

interface TaskListDisplayProps {
  title?: ReactNode;
}

interface Props extends TaskListDisplayProps {
  data: TaskListData;
}

const TaskPanelMemo = React.memo(TaskPanel);

export const TaskList = (props: Props) => {
  const { data } = props;
  const [isSearchVisible, setIsSearchVisible] = useState<boolean>(false);
  const [isSortVisible, setIsSortVisible] = useState<boolean>(false);
  const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);
  useEffect(() => {
    if(!isSearchVisible) {
      data.setSearch("");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearchVisible]);

  return (
    <TaskListWrapper>
      <Buttons className="head">
        {props.title}

        <Typography variant="caption" color="textSecondary" className="total">
          {`${data.tasksCount}`}
        </Typography>

        <IconButton className="reload" size="small" onClick={() => data.reload()}>
          <RefreshOutlined />
        </IconButton>
        <IconButton className="search" size="small" color={isSortVisible || (data.sort && data.sort !== "created_at:desc") ? "primary" : undefined} onClick={() => setIsSortVisible(x => !x)}>
          <SortOutlined />
        </IconButton>
        <IconButton className="filter" size="small" color={isFilterVisible || !!data.typesFilter.length ? "primary" : undefined} onClick={() => setIsFilterVisible(x => !x)}>
          <FilterAltOutlined />
        </IconButton>
        <IconButton className="search" size="small" color={isSearchVisible ? "primary" : undefined} onClick={() => setIsSearchVisible(x => !x)}>
          <SearchOutlined />
        </IconButton>

        <OccupyFreeSpace />

        {data.isLoading && <LoadingIndicator sizeVariant="s" />}

        <Button
          size="small"
          className="toggle toggle-unassigned"
          startIcon={data.isShowUnassigned ? <Visibility color="primary" /> : <VisibilityOffOutlined />}
          onClick={() => data.setIsShowUnassigned(!data.isShowUnassigned)}>
            <FormattedMessage id="tasks.show_unassigned" />
        </Button>
      </Buttons>

      {(isSearchVisible || isSortVisible) && <Buttons className="head2">
        {isSearchVisible &&
          <SearchField
            autoFocus
            filter={data.search}
            setFilter={data.setSearch}
            noButton
            small
            />}

        {isSortVisible &&
          <Select
            value={data.sort || "created_at:desc"}
            onChange={e => data.setSort(e.target.value)}>
              {[
                { value: "created_at:desc", label: "tasks.sort.created_at_desc" },
                { value: "created_at:asc", label: "tasks.sort.created_at_asc" },
                { value: "title:asc", label: "tasks.sort.title_asc" },
                { value: "title:desc", label: "tasks.sort.title_desc" },
              ].map(x => (
                <MenuItem key={x.value} value={x.value}>
                  <FormattedMessage id={x.label} />
                </MenuItem>
              ))}
        </Select>}
      </Buttons>}

      <Buttons className="head3">
        {isFilterVisible &&
          <TaskTypesFilterChipsToggles
            task_types={data.typesFilter}
            update={data.setTypesFilter}
            task_types_all={data.taskTypesAll}
            />}

        {!isFilterVisible && !!data.typesFilter.length &&
          <TaskTypesFilterChips
            task_types={data.typesFilter}
            update={data.setTypesFilter}
            />}
        <OccupyFreeSpace />
        
      </Buttons>

      {data.data.map(t => (
        <TaskPanelMemo
          key={t.task_id}
          task={t}
          actions={data.actions[t.task_type]}
          executeAction={data.executeAction.start}
          close={data.removeExecuted}
          />
      ))}

      {(data.canShowMore || data.isLoading) && <Buttons className="tail">
        {data.isLoading && !!data.data.length && <LoadingIndicator />}
        {!data.isLoading && <Button color="primary" onClick={() => data.showMore()}><FormattedMessage id="tasks.show_more" /></Button>}
      </Buttons>}

      <ExecuteTaskActionDialog data={data.executeAction} />
    </TaskListWrapper>
  );
}


export const TaskListBound = (props: TaskListDisplayProps & { apiPath: string }) => {
  const data = useTaskList(props.apiPath);

  return <TaskList {...props} data={data} />
}
