import React from 'react';
import { useParams } from 'react-router-dom';
import { DeleteButton, Form, FormGrid, OccupyFreeSpace, SaveButton, TabPanel, TabsHeader, useSaveable, useTabsState } from '../../toolympus/components/primitives';
import { FormControlsForFields } from '../../toolympus/components/schemed';
import { Divider, IconButton, TextField, Typography } from '@mui/material';
import { LocusEditor } from '../CMS/LocusEditor';
import { useCountryPracticeEdit } from './useCountryPracticeEdit';
import { Author, Partner, useCountryPracticeSchema } from './useCountryPracticeList';
import { DeleteOutlined } from '@mui/icons-material';
import { LanguageSwitch } from '../Common/Localization';
import { ItemEditorProps, ListEditor } from '../../toolympus/components/primitives/ListEditor';
import { MediaLibPicker, useMediaLibContext } from '../../toolympus/components/medialib';
import { generateCode } from '../../toolympus/components/PowerDoc/plugins/common';

export const CountryPracticeForm = () => {
  const { id } = useParams<{ id: string }>();
  const data = useCountryPracticeEdit(+id);
  const schema = useCountryPracticeSchema();
  useSaveable(data);

  const medialib = useMediaLibContext();

  const tabs = useTabsState([
    ["attributes","Атрибуты"],
    ["authors","Авторы"],
    ["partners","Партнеры"],
    ["content","Текст"],
  ], "attributes", "tab");

  return (
    <Form
      title="Иностранная практика"
      headerItems={<>
        <OccupyFreeSpace />
        {data.hasChanges && <SaveButton action={() => data.save()} />}
        <DeleteButton
          remove={() => data.remove()}
          title="Удалить запись?"
          button={<IconButton size="small"><DeleteOutlined /></IconButton>}
          />
        <LanguageSwitch locale={data.locale} setLocale={data.setLocale} />
      </>}
      >
      <FormGrid columns="repeat(3, 1fr)" style={{ alignItems: "flex-end" }}>
        <FormControlsForFields
          data={data.data}
          onChange={(o,c) => data.update(c)}
          schema={schema}
          fields={[
            ["title", { wrapperStyle: { gridColumn: "span 3" }}],
            ["slug"],
            ["created_datetime", { disabled: true, utcToLocal: true }],
            ["is_published"],
          ]}
          />
      </FormGrid>

      <TabsHeader state={tabs} />

      <TabPanel state={tabs.forTab("attributes")}>
        <FormGrid columns="1fr" noMargin>
          <MediaLibPicker
            selectFile={mf => data.update({ image_url: mf ? medialib.getFilepath(mf) : null })}
            selectedUrl={data.data.image_url}
            size="small"
            closeOnSelect
            label="Изображение"
            />

          <Typography variant="caption" component="p" color="textSecondary" style={{ marginTop: "1rem" }}>Справка</Typography>

          <LocusEditor
            key={`${data.data._id}_${data.locale}`}
            content={data.data.description}
            update={v => data.update({ description: v })}
            />

        <Divider />


        </FormGrid>
      </TabPanel>

      <TabPanel state={tabs.forTab("authors")}>
        <ListEditor
          idField="_id"
          itemEditor={AuthorEditor}
          items={data.data.authors}
          update={authors => data.update({ authors })}
          createDflt={() => ({ _id: generateCode(), title: "" })}
          />
      </TabPanel>
      
      <TabPanel state={tabs.forTab("partners")}>
        <ListEditor
          idField="_id"
          itemEditor={PartnerEditor}
          items={data.data.partners}
          update={partners => data.update({ partners })}
          createDflt={() => ({ _id: generateCode(), title: "", link: "" })}
          />
      </TabPanel>


      <TabPanel state={tabs.forTab("content")}>
        <LocusEditor
          key={`${data.data._id}_${data.locale}`}
          content={data.data.content}
          update={v => data.update({ content: v })}
          />
      </TabPanel>

    </Form>
  );
}


const PartnerEditor = ({ item, update }: ItemEditorProps<Partner>) => {
  const medialib = useMediaLibContext();
  return (
    <FormGrid columns="100px 1fr" noMargin forceEvenColumns>
      <MediaLibPicker
        selectFile={mf => update({ image_url: mf ? medialib.getFilepath(mf) : null })}
        selectedUrl={item.image_url}
        size="small"
        closeOnSelect
        />
      
      <FormGrid columns="1fr" noMargin gap="dense">
        <TextField
          value={item.title || ""}
          onChange={e => update({ title: e.target.value })}
          placeholder="Название"
          />
        <TextField
          value={item.link || ""}
          onChange={e => update({ link: e.target.value })}
          placeholder="Ссылка"
          />
      </FormGrid>
    </FormGrid>
  )
}


const AuthorEditor = ({ item, update }: ItemEditorProps<Author>) => {
  const medialib = useMediaLibContext();
  return (
    <FormGrid columns="100px 1fr" noMargin forceEvenColumns>
      <MediaLibPicker
        selectFile={mf => update({ image_url: mf ? medialib.getFilepath(mf) : null })}
        selectedUrl={item.image_url}
        size="small"
        closeOnSelect
        />
      
      <FormGrid columns="1fr" noMargin gap="dense">
        <TextField
          value={item.title || ""}
          onChange={e => update({ title: e.target.value })}
          placeholder="Имя"
          />
        <TextField
          value={item.job || ""}
          onChange={e => update({ job: e.target.value })}
          placeholder="Компания, должность"
          />
        <TextField
          value={item.link || ""}
          onChange={e => update({ link: e.target.value })}
          placeholder="Ссылка"
          />

        <LocusEditor
            key={item._id}
            content={item.description}
            update={v => update({ description: v })}
            />
      </FormGrid>
    </FormGrid>
  )
}
