import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Logo } from '.';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const Wrapper = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    & img {
        width: 300px;
    }
`;

const PublicPageTheme = createTheme({
  palette: {
    background: { default: "#0A2745", paper: "#0A2745" },
    primary: {
      main: '#1F9DD9',
    },
    secondary: {
      main: '#E21E26',
    },
    text: {
      primary: "#ffffff",
      secondary: "#ffffff",
    }
  }
});

export const PublicPageWrapper = (props: { children?: ReactNode }) => {
    return <ThemeProvider theme={PublicPageTheme}>
      <style dangerouslySetInnerHTML={{ __html: `html { background: #0A2745 }`}} />
      <Wrapper>
          <Logo version={2} />
          {props.children}
      </Wrapper>
    </ThemeProvider>
}
