import React from 'react';
import styled from '@emotion/styled';
import { CaseRecord, useCaseRecordsList } from './useCaseRecordsList';
import { ButtonOptionPicker, FlyingSplash, LoadingIndicator, SimpleList } from '../../toolympus/components/primitives';
import { CreateCaseRecordDialog } from './CreateCaseRecordDialog';
import { useHistory } from 'react-router-dom';
import { useFields } from '../../toolympus/components/schemed';
import { getPowerDocText } from '../../toolympus/components/PowerDoc';
import { DisplayProps } from '../../toolympus/components/schemed/FormControlProps';
import { Typography } from '@mui/material';

export const DescriptionWrapper = styled.div`
  white-space: pre-line;
`;

export const DescriptionViewer = (props: DisplayProps) => {
  return (
    <DescriptionWrapper>{getPowerDocText(props.row[props.field])}</DescriptionWrapper>
  )
}


export const CaseRecordsList = () => {
  const data = useCaseRecordsList();
  const history = useHistory();  

  const fields = useFields({
    defaultFields: [
      "_id",
      "number",
      "ruling_date",
      "stage",
      "court_id",
      "created_datetime",
      "is_ping_owner_enabled",
    ],
    blacklist: ["content", "content_en"],
    schema: data.schema,
    storageKey: "_locus_case_records_fields",
    extraSettings: {
      description: { tableControl: DescriptionViewer },
    }
  });

  return (<>
    <SimpleList
      data={{
        ...data,
        filter: data.filter.filter,
        setFilter: data.filter.setFilter,
        create: () => { data.newRecord.startEditing(); return Promise.resolve({ _id: -1 } as CaseRecord); }
      }}
      fields={[
        ["number"],
        ["ruling_date"],
        ["court_id"],
        ["created_datetime"],
      ]}
      tabs={[
        ["Черновики", "unpublished"],
        ["Опубликованные", "public"],
        ["Все", "all"],
      ]}
      schema={data.schema}
      getLinkValue={cr => `/case-record/${cr._id}`}
      linkFields={[ "_id", "number" ]}
      title="Решения"
      searchAuto
      searchAutoFocus
      fieldSetings={fields}
      fieldFilters={data.filtering}
      sorting={data.sorting}
      tableProps={{
        tailAnchor: data.limitTrigger.pagingAnchor,
      }}
      headerActions={<>
        <Typography variant="caption" color="textSecondary" style={{ alignSelf: "flex-end" }}>
          Записей: {data.count.data.total_records}
        </Typography>
      </>}
      headerViewActions={
        <ButtonOptionPicker
          options={[
            ["мои","t"],
            ["все","f"],
          ]}
          selected={data.myOnly}
          setSelected={data.setMyOnly}
          />
      }
      />

    {data.isLoading && <FlyingSplash>
      <LoadingIndicator />
    </FlyingSplash>}
    <CreateCaseRecordDialog
      {...data.newRecord}
      duplicatesCheck={data.duplicatesCheck}
      save={() => data.newRecord.save().then(cr => { history.push(`/case-record/${cr._id}`); return cr; })}
      schema={data.schema}
      />
  </>);
}
