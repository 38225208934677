import React from "react";
import { AssignmentOutlined, CommentOutlined, ContactMailOutlined, DescriptionOutlined, GavelOutlined, HomeOutlined, InfoOutlined, PersonOutlined, PrintOutlined, PublicOutlined, Settings, SettingsOutlined, SpeedOutlined } from "@mui/icons-material";
import { Roles } from "./components/Auth";

import { MenuRoute } from "./toolympus/components/frame";
import { SystemSubmenu } from "./toolympus/components/Contests/SystemSubmenu";
import { CMS } from "./components/CMS/CMS";
import { SiteSettingsPage } from "./components/Administration/SiteSettings";
import { CaseRecordsList, CaseRecordForm } from "./components/CaseRecords";
import { ExpertCommentForm, ExpertCommentsList } from "./components/ExpertComments";
import { UserProfilesList } from "./components/Administration/Users";
import { ExpertsApplicationsList } from "./components/ExpertsApplications";
import { AllDictionariesPage } from "./components/Administration/AllDictionariesPage";
import { CaseSuggestionsList } from "./components/CaseRecords/CaseSuggestions";
import { CountryPracticeForm, CountryPracticeList } from "./components/CountryPractice";
import { Documentation } from "./components/Administration/Documentation";
import { DashboardsPage, EditDashboardsPage } from "./components/Administration/DashboardsPage";
import { HomePage } from "./components/Administration/HomeAndDocs/HomePage";
import { ResetLocalStorage } from "./toolympus/components/primitives/ResetLocalStorage";
import { ConfigurableFormsRoutes } from "./toolympus/components/ConfigurableForms";
import { LanguagesWithLabels } from "./components/Common/Localization";
import { QueryChunksConfigurationPage } from "./toolympus/components/Dataschema";
import { TaskListPage } from "./components/Administration/Tasks/TaskListPage";

const InnerRoutes: MenuRoute[] = [
    { 
      path: '/home',
      title: 'Главная',
      component: HomePage,
      icon: <HomeOutlined />,
    },

    { 
      path: '/case-record/:id',
      title: 'Решение',
      component: CaseRecordForm,
      hidden: true,
    },
    { 
      path: '/case-record',
      title: 'Решения',
      icon: <DescriptionOutlined />,
      component: CaseRecordsList,
      alsoActivateForPath: p => p.startsWith("/case-record/"),
    },
    
    { 
      path: '/case-suggestion',
      title: 'Предложения решений',
      icon: <ContactMailOutlined />,
      component: CaseSuggestionsList,
    },

    
    { 
      path: '/expert-comment/:id',
      title: 'Экспертное мнение',
      component: ExpertCommentForm,
      hidden: true,
    },
    { 
      path: '/expert-comment',
      title: 'Экспертные мнения',
      icon: <CommentOutlined />,
      component: ExpertCommentsList,
      alsoActivateForPath: p => p.startsWith("/expert-comment/"),
    },

    { 
      path: '/expert-application',
      title: 'Заявки экспертов',
      icon: <ContactMailOutlined />,
      component: ExpertsApplicationsList,
    },


    { 
      path: '/country-practice/:id',
      title: 'Иностранная практика',
      component: CountryPracticeForm,
      hidden: true,
    },
    { 
      path: '/country-practice',
      title: 'Иностранная практика',
      icon: <PublicOutlined />,
      component: CountryPracticeList,
      alsoActivateForPath: p => p.startsWith("/country-practice/"),
    },

    
    { 
      path: '/user-profile',
      title: 'Пользователи',
      icon: <PersonOutlined />,
      component: UserProfilesList,
    },


    { path: '/dsqc', title: 'Схема данных', component: QueryChunksConfigurationPage, componentProps: { apiPath: "/api/dsqc" }, hidden: true },

    { path: '/dashboards', title: 'Дэшборды', component: EditDashboardsPage, hidden: true },

    { path: '/appreset', title: "", component: ResetLocalStorage, hidden: true },
    { path: '/tasks', title: "", component: TaskListPage, hidden: true },


    { 
      path: '/dictionaries',
      title: 'Справочники',
      icon: <GavelOutlined />,
      component: AllDictionariesPage,
    },
    { 
        path: '/system',
        title: 'Конфигурация',
        icon: <Settings />,
        component: () => (
            <SystemSubmenu
                emails={{ apiPath: "/emails" }}
                users={{ userRoles: Roles, allowInvites: true, allowPaswordReset: true, allowPaswordChange: true, twoFactorAuthEnabled: true }}
                messages={{}}
                queryConsole={{}}
                robud={{}}
                notificationTasks={{ apiPath: "/api/notifications/task", emailsApiPath: "/api/emails", emailsPagePath: "/system/emails" }}
                extraItems={[
                  {
                    key: "cms",
                    label: "CMS",
                    icon: <PrintOutlined />,
                    items: [{
                      key: "cms",
                      label: "CMS",
                      component: CMS,
                    }],
                  },
                  {
                    key: "forms",
                    label: "Формы",
                    icon: <AssignmentOutlined />,
                    items: [{
                      key: "forms",
                      label: "Формы",
                      component: ConfigurableFormsRoutes,
                      componentProps: {
                        apiPath: "/api/form/config",
                        localization: {
                          defaultLocale: "ru",
                          locales: LanguagesWithLabels,
                        }
                      }
                    }],
                  },
                  {
                    key: "settings",
                    label: "Настройки",
                    icon: <SettingsOutlined />,
                    items: [{
                      key: "settings",
                      label: "Настройки",
                      component: SiteSettingsPage,
                    }],
                  },
                  {
                    key: "documentation",
                    label: "Документация",
                    icon: <InfoOutlined />,
                    items: [{ key: "documentation", label: "", component: Documentation }]
                  },
                  {
                    key: "dashboard",
                    label: "Дэшборды",
                    icon: <SpeedOutlined />,
                    items: [{ key: "dashboard", label: "", component: DashboardsPage }]
                  },
                ]}
            />),
    },
];

export default InnerRoutes;