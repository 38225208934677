import React from 'react';
import { Court, CourtSchema, generateTitleMiddle } from './useManageCourts';
import { FormGrid, SimpleDialog } from '../../../toolympus/components/primitives';
import { FormControlsForFields } from '../../../toolympus/components/schemed';
import { IconButton } from '@mui/material';
import { Refresh } from '@mui/icons-material';

interface Props {
  isOpen: boolean;
  close: () => void;
  item: Omit<Court, "_id"> | null;
  update: (changes: Partial<Court>) => void;
  save: () => Promise<Court>;
}

export const CourtEditDialog = (props: Props) => {
  const { isOpen, close, item, update, save } = props;
  return (
    <SimpleDialog
      dialogTitle="Суд"
      close={close}
      isOpen={isOpen}
      save={save}
      maxWidth="md">

        <FormGrid columns="2fr 2fr 1fr">
          {item && <FormControlsForFields
            data={item}
            onChange={(o,c) => update(c)}
            schema={CourtSchema}
            fields={[
              ["title", {
                controlProps: {
                  onBlur: () => {
                    if(!item.title_middle) {
                      update({ title_middle: generateTitleMiddle(item.title) });
                    }
                  }
                }
              }],
              ["title_middle", {
                controlProps: {
                  endAdornment: (
                    <IconButton
                        size="small"
                        color="primary"
                        onClick={() => update({ title_middle: generateTitleMiddle(item.title) })}>
                        <Refresh />
                    </IconButton>),
                }
              }],
              ["title_short"],
            ]}
            />}

          {item && <FormControlsForFields
            data={item.translations?.en || {}}
            onChange={(o,c) => update({ translations: { en: { ...o, ...c } }})}
            schema={CourtSchema}
            fields={[
              ["title", {
                label: "Название (en)",
                controlProps: {
                  onBlur: () => {
                    if(!item.translations?.en?.title_middle) {
                      update({ translations: { en: { ...(item.translations?.en || {}), title_middle: generateTitleMiddle(item.translations?.en?.title) } }});
                    }
                  }
                }
              }],
              ["title_middle", {
                label: "Сокращенное название (en)",
                controlProps: {
                  endAdornment: (
                    <IconButton
                        size="small"
                        color="primary"
                        onClick={() => update({ translations: { en: { ...(item.translations?.en || {}), title_middle: generateTitleMiddle(item.translations?.en?.title) } }})}>
                        <Refresh />
                    </IconButton>),
                }
              }],
              ["title_short", { label: "Краткое название (en)"}],
            ]}
            />}

          {item && <FormControlsForFields
            data={item}
            onChange={(o,c) => update(c)}
            schema={CourtSchema}
            fields={[
              ["stage"],
            ]}
            />}

          {item && <FormControlsForFields
            data={item}
            onChange={(o,c) => update(c)}
            schema={CourtSchema}
            fields={[
              ["sort_order"],
            ]}
            />}
        </FormGrid>
      
    </SimpleDialog >
  );
}
