import React from 'react';
import { VideogameAssetOutlined } from "@mui/icons-material";
import { CustomElement } from "../../../slate";
import { EditorPlugin } from "../../../toolympus/components/PowerDoc";
import { createWithVoidInjector } from "../../../toolympus/components/PowerDoc/plugins/common";
import { UpdatePanelBlockElement, UpdatePanelBlockElementType } from "./UpdatePanelBlockElement";

export const UpdatePanelBlockPlugin: EditorPlugin = {
  key: "update-panel-block",
  inject: createWithVoidInjector(UpdatePanelBlockElementType),
  customBlocks: {
    [UpdatePanelBlockElementType]: UpdatePanelBlockElement,
  },
  commands: [
    {
      name: "insert-update-panel-block",
      invoke: editor => {
        editor.insertNode({
          type: UpdatePanelBlockElementType,
          children: [{ text: "", }],
        } as CustomElement);
      },
      menu: { section: "insert-item", icon: <VideogameAssetOutlined />, label: "Панель с обновлением" },
    }
  ]
}
