import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { FieldSchema } from '../../../toolympus/hooks/useSchema';
import { IconButton, InputAdornment, TextField, Paper, MenuList, ListItem, ListItemText } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import { CasePartySuggestion, CasePartySuggestions } from './useCaseRecordParties';
import { LoadingIndicator } from '../../../toolympus/components/primitives';
import isHotkey from 'is-hotkey';
import { useDictionaryMap } from '../../../toolympus/hooks/useDictionaries';

const SuggestionsWrapper = styled(Paper)`
  background: #ffffff;

  position: absolute;
  left: 0;
  top: calc(100% + 3px);
  width: 100%;
  z-index: 2000;
`;

interface Props {
  value: string | undefined;
  update: (v: string) => void;
  schema: FieldSchema;
  suggestions: CasePartySuggestions;
  selectSuggestion: (s: CasePartySuggestion) => void;
}

export const TinEditor = (props: Props) => {
  const suggested = props.suggestions.suggestions;

  const [changeTracker, setChangeTracker] = useState<number>(0);

  const getCountry = useDictionaryMap("Countries");

  
  useEffect(() => {
    if(props.value && props.value.length > 2 && changeTracker) {
      const timeout = setTimeout(() => props.suggestions.requestSuggestions(props.value || ""), 800);
      return () => clearTimeout(timeout);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value, changeTracker]);

  const selectSuggestion = (s: CasePartySuggestion) => {
    props.selectSuggestion(s);
    props.suggestions.clearSuggestions();
    setChangeTracker(0);
  }

  return (
    <>
      <TextField
        label={props.schema?.label}
        value={props.value || ""}
        onBlur={() => setTimeout(() => props.suggestions.clearSuggestions(), 500)}
        onChange={e => {
          setChangeTracker(x => x+1);
          props.update(e.target.value);
        }}
        onKeyUp={e => {
          if(isHotkey("enter")(e) && suggested.length) {
            e.preventDefault();
            e.stopPropagation();
            selectSuggestion(suggested[0]);
          }
        }}
        InputProps={{
          endAdornment: <>
            <InputAdornment position="end">
              <IconButton size="small" onClick={() => props.value && props.suggestions.requestSuggestions(props.value)}>
                {props.suggestions.isLoading ? <LoadingIndicator sizeVariant="s" /> : <MoreHoriz />}
              </IconButton>
            </InputAdornment>
            {!!suggested.length &&
              <SuggestionsWrapper>
                <MenuList dense>
                  {suggested.map((s,i) => (
                    <ListItem
                      key={`${s.title}_${s.tin}_${i}`}
                      button
                      dense
                      onClick={() => selectSuggestion(s)}>
                      <ListItemText primary={s.title} secondary={`${s.tin}${s.country ? ", " : ""}${s.country ? (getCountry(s.country)?.label || s.country) : ""}`} />
                    </ListItem>
                  ))}
                </MenuList>
              </SuggestionsWrapper>}
          </>
        }}
        />
    </>
  );
}
