import React from 'react';
import { useParams } from 'react-router-dom';
import { DeleteButton, Form, FormGrid, OccupyFreeSpace, SaveButton, TabPanel, TabsHeader, useSaveable, useTabsState } from '../../toolympus/components/primitives';
import { FormControlsForFields } from '../../toolympus/components/schemed';
import { Divider, IconButton, Typography } from '@mui/material';
import { LocusEditor } from '../CMS/LocusEditor';
import { useExpertCommentEdit } from './useExpertCommentEdit';
import { useExpertCommentSchema } from './useExpertCommentsList';
import { DeleteOutlined } from '@mui/icons-material';
import { LanguageSwitch } from '../Common/Localization';
import { TopicsSelectorControl } from '../Administration/Topics';

export const ExpertCommentForm = () => {
  const { id } = useParams<{ id: string }>();
  const data = useExpertCommentEdit(+id);
  const schema = useExpertCommentSchema();
  useSaveable(data);

  const tabs = useTabsState([
    ["attributes","Атрибуты"],
    ["content","Текст"],
  ], "attributes", "tab");

  return (
    <Form
      title="Экспертное мнение"
      headerItems={<>
        <OccupyFreeSpace />
        {data.hasChanges && <SaveButton action={() => data.save()} />}
        <DeleteButton
          remove={() => data.remove()}
          title="Удалить мнение?"
          button={<IconButton size="small"><DeleteOutlined /></IconButton>}
          />
        <LanguageSwitch locale={data.locale} setLocale={data.setLocale} />
      </>}
      >
      <FormGrid columns="repeat(3, 1fr)" style={{ alignItems: "flex-end" }}>
        <FormControlsForFields
          data={data.data}
          onChange={(o,c) => data.update(c)}
          schema={schema}
          fields={[
            ["title", { wrapperStyle: { gridColumn: "span 3" }}],
            ["comment_date"],
            ["created_datetime", { disabled: true, utcToLocal: true }],
            ["is_published"],
          ]}
          />
      </FormGrid>

      <TabsHeader state={tabs} />

      <TabPanel state={tabs.forTab("attributes")}>
        <FormGrid columns="1fr" noMargin>
          <FormControlsForFields
            data={data.data}
            onChange={(o,c) => data.update(c)}
            schema={schema}
            fields={[
              ["author_id", { autoComplete: true }],
              ["topics", { control: TopicsSelectorControl }]
            ]}
            />
          
          <Typography variant="caption" component="p" color="textSecondary" style={{ marginTop: "1rem" }}>Справка</Typography>

          <LocusEditor
            key={`${data.data._id}_${data.locale}`}
            content={data.data.description}
            update={v => data.update({ description: v })}
            />

        <Divider />


        </FormGrid>
      </TabPanel>

      <TabPanel state={tabs.forTab("content")}>
        <LocusEditor
          key={`${data.data._id}_${data.locale}`}
          content={data.data.content}
          update={v => data.update({ content: v })}
          />
      </TabPanel>

    </Form>
  );
}
